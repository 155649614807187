import {search, searchFieldAlignment, pageSize, pageSizeOptions, headerStyle, searchFieldStyle, emptyRowsWhenPaging, selection } from "../../common/table/shared/Options";
import {csvLabel, exportCSV, exportPdf, pdfLabel} from "../../common/table/shared/utils/exportData";
import {Options} from "@material-table/core";

const exportMenu: any = [{
    label: pdfLabel,
    exportFunc: (cols: any[], datas: any[]): void => {
        exportPdf(cols, datas, "Brugerkoder");
    }
},
    {
        label: csvLabel,
        exportFunc: (cols: any[], datas: any[]): void => {
            exportCSV(cols, datas, "Brugerkoder");
        }
    }];

export const options: Options<any> = {
    selection,
    search,
    searchFieldAlignment,
    pageSize,
    pageSizeOptions,
    headerStyle,
    searchFieldStyle,
    exportMenu,
    emptyRowsWhenPaging,
};

