import React, {useEffect, useState} from "react";
import styles from "./Toast.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes, IconDefinition, faExclamationTriangle, faInfoCircle, faExclamation} from '@fortawesome/free-solid-svg-icons';
import {MessageTypes} from "../shared/enums/MessageTypes";


export interface ToastProps {
  id: string;
  destroy: () => void;
  content: string;
  messageType: MessageTypes;
  duration?: number;
}

const Toast: React.FC<ToastProps> = (props) => {
  const { destroy, content, messageType, duration = 5000 } = props;
  const [messageIcon, setMessageIcon] = useState<IconDefinition>(faCheck);
  const [color, setColor] = useState<string>();

  useEffect(() => {
    if (!duration) {
      return;
    }

    setIcon(messageType);

    setBackgroundColor(messageType);

    const timer = setTimeout(() => {
      destroy();
    }, duration);

    return () => clearTimeout(timer);
  }, [destroy, duration, messageType]);

  const setIcon = (messageType: MessageTypes): void => {
    switch(messageType) {
      case MessageTypes.Success: {
        setMessageIcon(faCheck);
        break;
      }
      case MessageTypes.Error: {
        setMessageIcon(faExclamation);
        break;
      }
      case MessageTypes.Info: {
        setMessageIcon(faInfoCircle);
        break;
      }
      case MessageTypes.Warning: {
        setMessageIcon(faExclamationTriangle);
        break;
      }
      default: {
        break;
      }
    }
  };

  const setBackgroundColor = (messageType: MessageTypes): void => {
    switch(messageType) {
      case MessageTypes.Success: {
        setColor(`${styles.toastMain} ${styles.toastSuccess}`);
        break;
      }
      case MessageTypes.Error: {
        setColor(`${styles.toastMain} ${styles.toastError}`);
        break;
      }
      case MessageTypes.Info: {
        setColor(`${styles.toastMain} ${styles.toastInfo}`);
        break;
      }
      case MessageTypes.Warning: {
        setColor(`${styles.toastMain} ${styles.toastWarning}`);
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <div>
      <div className={`${styles.container} ${color}`}>
        <FontAwesomeIcon className={`${styles.messageIcon}`} size="sm" icon={messageIcon}/>
        <span className={`${styles.content}`}>{content}</span>
        <button className={`${styles.closeIcon} ${styles.toastButton}`} onClick={destroy}>
          <FontAwesomeIcon className={`${styles.svg}`} icon={faTimes}/>
        </button>
      </div>
    </div>
  );
};

const shouldRerender = (prevProps: ToastProps, nextProps: ToastProps) => {
  return prevProps.id === nextProps.id;
};

export default React.memo(Toast, shouldRerender);

