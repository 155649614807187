import { RuleTypes } from "../../../shared/enums/RuleTypes";

const RuleString = (ruleType: RuleTypes) : string | undefined =>  {
    switch (ruleType) {
        case RuleTypes.CVR: {
            return "CVR-nummer";
        }
        case RuleTypes.P_NUMMER: {
            return "P-nummer";
        }
        case RuleTypes.RID: {
            return "RID";
        }
    }
};

export default RuleString;