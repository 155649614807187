import $ from "jquery";

export class Helpers {
    public static setExampleHeadline(): void {
        $(".megatron-pagecontent.megatron-ot-sammensat span strong").html("Eksempel");
    }

    public static hideOriginalBtn = (): void => {
        const buttonContainers = document.getElementsByClassName("action-buttons mt-4");
        buttonContainers[0].className = "action-buttons mt-4 hide-button";
    };

    public static displayOriginalBtn = (): void => {
        const buttonContainers = document.getElementsByClassName("action-buttons mt-4 hide-button");
        buttonContainers[0].className = "action-buttons mt-4";
    };

    public static removeElementById = (id: string): void => {
        document.getElementById(id)?.remove();
    };
}
