import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas, faCheck, } from '@fortawesome/free-solid-svg-icons';
import { far, faSquare, faSquareCheck } from '@fortawesome/free-regular-svg-icons';

library.add(far, faSquare, faSquareCheck, fas, faCheck);

const DetailsSvarmulighed = (props: {opgaver: any, opgavebesvarelser: any}): JSX.Element => {
    const svarMuligheder = [] as JSX.Element[];

    props.opgaver.map((opgave: any) => {
        switch (opgave.type) {
            //This case corresponds to assignments with radiobutton answers
            case 6: {
                const besvarelse = props.opgavebesvarelser.find((e: any) => e.opgaveId === opgave.id)?.svarmulighed?.svar;
                const korrektBesvarelse = opgave.svarmuligheder[opgave.korrektSvar];
                opgave.svarmuligheder.map((svar: string) => {
                    const indexOfImageApi = svar.indexOf("/api/");
                    if(indexOfImageApi !== -1){
                       const imagePath = svar.substring(indexOfImageApi, svar.lastIndexOf(")"));
                       const imageDescription = svar.substring(svar.indexOf("[") + 1,svar.lastIndexOf("]"));
                       svarMuligheder.push(<div className="row ml-3" key={Math.random()}>
                        <p className="mt-1 mb-1">{svar == besvarelse ?
                            (
                                <FontAwesomeIcon className="mr-3" icon={["far", "square-check"]} />
                            ) : (
                                <FontAwesomeIcon className="mr-3" icon={["far", "square"]} />
                            )}
                        </p>

                        <img src={imagePath} aria-label={imageDescription}></img>

                        <p className="mt-1 mb-1">
                            {svar == korrektBesvarelse &&
                                <FontAwesomeIcon className="ml-3" icon={["fas", "check"]} color="green" />
                            }
                        </p>
                    </div>);
                    }
                    else{
                    svarMuligheder.push(<div className="row ml-3" key={Math.random()}>
                        <p className="mt-1 mb-1">{svar == besvarelse ?
                            (
                                <FontAwesomeIcon className="mr-3" icon={["far", "square-check"]} />
                            ) : (
                                <FontAwesomeIcon className="mr-3" icon={["far", "square"]} />
                            )}
                        </p>

                        <p className="mt-1 mb-1" dangerouslySetInnerHTML={{ __html: svar }} />

                        <p className="mt-1 mb-1">
                            {svar == korrektBesvarelse &&
                                <FontAwesomeIcon className="ml-3" icon={["fas", "check"]} color="green" />
                            }
                        </p>
                    </div>);}
                    }
                );
                break;
            }
            //This case corresponds to assignments with DropDown answers
            case 7:
                break;
        }
    });
    return (<>{svarMuligheder}</>);
};

export default DetailsSvarmulighed;