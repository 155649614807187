import {Dispatch, FunctionComponent, SetStateAction} from "react";
import $ from 'jquery';
import styles from "./SupporterRemoveRuleModal.module.scss";
import {RuleDetails} from "../../../shared/types/RuleDetails";
import {DeleteRule} from "../../../apiService/RuleApi";
import {toastManager} from "../../common/Toast/ToastManager/ToastManager";
import updateTable from "../../shared/utils/updateTable";
import { RuleTypes } from "../../../shared/enums/RuleTypes";
import RuleString from "../../shared/utils/RuleString";

const SupporterRemoveRuleModal: FunctionComponent<{
    rule: RuleDetails;
    setForceUpdate: Dispatch<SetStateAction<number>>;
    ruleType: RuleTypes
}> = ({rule, setForceUpdate, ruleType }) => {

    const toggleRemoveRuleModal = () => {
        $(`#modal-remove-${ruleType}-${rule.id}`).toggle();
        $("#modal-backdrop").toggle();
    };

    const handleSubmit = async (event: { preventDefault: () => void }) => {
        event.preventDefault();
        DeleteRule(rule.id).then((response) => {
            if (response.succeeded) {
                toastManager.success("'" + rule.value + "'" + " er blevet fjernet");
                setForceUpdate(updateTable());
            } else {
                toastManager.error(response.errors.toString());
            }
            toggleRemoveRuleModal();
        });
    };

    return (
        <>
            <div className="fds-modal" id={`modal-remove-${ruleType}-${rule.id}`} aria-hidden="true" role="dialog">
                <div className={`modal-content`}>
                    <div className="modal-header">
                        <h2 className="modal-title">Fjern {RuleString(ruleType)}</h2>
                    </div>
                    <div className="modal-body">
                        <span>Er du sikker på at du gerne vil fjerne &apos;{rule.value}&apos;? <br/></span>
                        <div className={styles.submitCancel}>
                            <div className={`action-buttons mt-4 ${styles.submitButton}`}>
                                <input
                                    type="submit"
                                    className="button button-primary button-long"
                                    value={"Fjern"}
                                    onClick={handleSubmit}>
                                </input>
                            </div>
                            <div className="action-buttons mt-4">
                                <button
                                    className="button button-secondary button-long"
                                    value="Luk"
                                    onClick={toggleRemoveRuleModal}>
                                    Annullér
                                </button>
                            </div>
                        </div>
                        <div className="modal-footer" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default SupporterRemoveRuleModal;
