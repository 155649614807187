import { FunctionComponent } from "react";
import Page from "../../components/common/Page/Page";

const NoAccess: FunctionComponent = () => {
    return (
        <Page>
            <div className="container page-container">
                <div className="alert alert-info">
                    <div className="alert-body">
                        <p className="alert-heading">Ingen adgang
                        </p>
                        <p className="alert-text">Du har ikke adgang til denne side. <br/> Kontakt supporten nederst på siden, hvis du mener, at dette er en fejl.
                        </p>
                    </div>
                </div>
            </div>
        </Page>
    );
};

export default NoAccess;
