import { FunctionComponent, useEffect, useState, useContext } from "react";
import Summary from "../../components/results/Summary/Summary";
import Details from "../../components/results/Details/Details";
import {
    GetOpgavebesvarelserByBrugerkode,
    GetResultAsPdfByBrugerkodeApi,
    GetResultByBrugerkodeApi
} from "../../apiService/ResultApi";
import VejlederPage from "./VejlederPage";
import { Result } from "../../shared/types/Result";
import { toastManager } from "../../components/common/Toast/ToastManager/ToastManager";
import createDownloadLink from "../../shared/utils/createDownloadLink";
import { GetElevByBrugerkodeAsAuthenticatedUserApi } from "../../apiService/ElevApi";
import { Elev } from "../../shared/types/Elev";
import { GetTesttagningDetailsByBrugerkode, UpdateTesttagningByBrugerkodeApi } from "../../apiService/TesttagningApi";
import { TesttagningDetails } from "../../shared/types/TesttagningDetails";
import DetailsLaesetest from "../../components/results/DetailsLaesetest/DetailsLaesetest";
import { IsTest } from "../../shared/utils/IsTest";
import OpgavesaetContext from "../../components/OpgavesaetContext";
import { GetOpgavesaetByBrugerkodeApi } from "../../apiService/OpgavesaetApi";
import DetailsOpgaveBesvarelseMatematik from "../../components/results/DetailsOpgaveBesvarelseMatematik/DetailsOpgaveBesvarelseMatematik";
import { genericWarning, noWarning } from "../../components/common/Warnings";

const VejlederElevResultat: FunctionComponent = () => {
    const [display, setDisplay] = useState(true);
    const [warning, setWarning] = useState<JSX.Element>();
    const [brugerkode, setBrugerkode] = useState<string | null | undefined>();
    const [result, setResult] = useState<Result>();
    const [opgavebesvarelser, setOpgavebesvarelser] = useState<any>();
    const [elev, setElev] = useState<Elev>();
    const [tekstlaesning, setTekstlaesning] = useState<boolean>();
    const [ordforraad, setOrdforraad] = useState<boolean>();
    const [ordlaesning, setOrdlaesning] = useState<boolean>();
    const [testtagning, setTesttagning] = useState<TesttagningDetails>();
    const { opgavesaet, setOpgavesaet, setTestnavn } = useContext(OpgavesaetContext);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        setBrugerkode(queryParams.get("brugerkode"));
        if (brugerkode != undefined) {
            GetOpgavebesvarelserByBrugerkode(brugerkode).then(response => {
                setOpgavebesvarelser(response.data.data);
            });
            GetResultByBrugerkodeApi(brugerkode).then(response => {
                setResult(response.data.data);
            });

            GetElevByBrugerkodeAsAuthenticatedUserApi(brugerkode).then((response) => {
                setElev(response.data.data);
            });

            GetTesttagningDetailsByBrugerkode(brugerkode).then(response => {
                setTesttagning(response.data);
                setTekstlaesning(response?.data?.tekstlaesning);
                setOrdforraad(response?.data?.ordforraad);
                setOrdlaesning(response?.data?.ordlaesning);
            });

            GetOpgavesaetByBrugerkodeApi(brugerkode).then((opgavesaetResponse) => {
                if (opgavesaetResponse.isAxiosError && opgavesaetResponse.response?.data?.Message !== undefined) {
                    setWarning(genericWarning(opgavesaetResponse.response.data.Message));
                    setOpgavesaet!("");
                } else {
                    setOpgavesaet!(opgavesaetResponse.data);
                    setTestnavn!(opgavesaetResponse.data?.navn);
                    setWarning(noWarning);
                }
            });
        }
    }, [brugerkode, setOpgavesaet, setTestnavn]);

    useEffect(() => {
        if (brugerkode != undefined)
        {
            UpdateTesttagningByBrugerkodeApi(brugerkode, tekstlaesning, ordforraad, ordlaesning);
        }
    },[tekstlaesning, ordforraad, ordlaesning, brugerkode]);

    const downloadPdf = (brugerkode: string | null | undefined): void => {
        UpdateTesttagningByBrugerkodeApi(brugerkode, tekstlaesning, ordforraad, ordlaesning).then(() => {
            GetResultAsPdfByBrugerkodeApi(brugerkode).then(response => {
                const byteArray = response.data.data;
                if (byteArray != undefined) {
                    createDownloadLink(byteArray, "pdf", `${elev?.fornavn}_${elev?.efternavn}_${elev?.brugerkode}_${IsTest.getTestName(testtagning?.opgavesaet.navn)}`);
                } else {
                    toastManager.error("Der skete en fejl. Prøv igen.");
                }
            });
        });
    };

    return (
        <VejlederPage>
            <div className="container page-container App">
                {warning}
                {elev != undefined && <>
                    <h1>Afsluttet test for {result?.testtagerNavn}</h1>
                    <Summary
                        brugerkode={brugerkode}
                        display={display}
                        setDisplay={setDisplay}
                        result={result}
                        elev={elev}
                        setTekstlaesning={setTekstlaesning}
                        tekstlaesning={tekstlaesning}
                        setOrdforraad={setOrdforraad}
                        ordforraad={ordforraad}
                        setOrdlaesning={setOrdlaesning}
                        ordlaesning={ordlaesning}
                        testtagning={testtagning} />
                    {IsTest.isLaesetest(result?.testNavn) &&
                        <DetailsLaesetest brugerkode={brugerkode} display={display} setDisplay={setDisplay} result={result} testtagning={testtagning} />
                    }
                    {IsTest.isMatematiktest(result?.testNavn) &&
                        <Details brugerkode={brugerkode} display={display} setDisplay={setDisplay} result={result} />
                    }
                    <button className="button button-primary" onClick={() => downloadPdf(brugerkode)}>Download PDF</button>
                    {IsTest.isMatematiktest(result?.testNavn) &&
                        <DetailsOpgaveBesvarelseMatematik brugerkode={brugerkode} display={display} setDisplay={setDisplay} result={result} opgavebesvarelser={opgavebesvarelser} opgavesaet={opgavesaet} />
                    }
                </>}
            </div>
        </VejlederPage>
    );
};

export default VejlederElevResultat;
