import { FunctionComponent, useEffect, useState } from "react";
import Page from "../../components/common/Page/Page";
import Details from "../../components/results/Details/Details";
import Summary from "../../components/results/Summary/Summary";
import ToggleResultDisplay from "../../components/results/ToggleResultDisplay";
import {GetResultAsPdfByBrugerkodeAsElevApi, GetResultByBrugerkodeAsElevApi} from "../../apiService/ResultApi";
import { Result } from "../../shared/types/Result";
import { SessionStorage } from "../../shared/enums/Storage";
import {GetElevByBrugerkodeAsElevApi} from "../../apiService/ElevApi";
import {Elev} from "../../shared/types/Elev";
import {GetTesttagningDetailsByBrugerkode, UpdateTesttagningByBrugerkodeApi} from "../../apiService/TesttagningApi";
import {TesttagningDetails} from "../../shared/types/TesttagningDetails";
import DetailsLaesetest from "../../components/results/DetailsLaesetest/DetailsLaesetest";
import {IsTest} from "../../shared/utils/IsTest";

const Resultater: FunctionComponent = () => {
    const [display, setDisplay] = useState(false);
    const [brugerkode, setBrugerkode] = useState<string | null | undefined>();
    const [result, setResult] = useState<Result>();
    const [elev, setElev] = useState<Elev>();
    const [tekstlaesning, setTekstlaesning] = useState(false);
    const [ordforraad, setOrdforraad] = useState(false);
    const [ordlaesning, setOrdlaesning] = useState(false);
    const [testtagning, setTesttagning] = useState<TesttagningDetails>();

    useEffect(() => {
        const brugerkodeInSession = sessionStorage.getItem(SessionStorage.BRUGERKODE);
        sessionStorage.clear();
        if (brugerkodeInSession) {
            setBrugerkode(brugerkodeInSession);
        }
        if (brugerkode != undefined) {
            GetResultByBrugerkodeAsElevApi(brugerkode).then(response => {
                if (response == 1) {
                    return;
                }
                setResult(response.data.data);
            });

            GetElevByBrugerkodeAsElevApi(brugerkode).then((response) => {
                setElev(response.data.data);
            });

            GetTesttagningDetailsByBrugerkode(brugerkode).then(response => {
                setTesttagning(response.data);
            });

        }
    }, [brugerkode]);

    useEffect(() => {
        if (brugerkode != undefined)
        {
            UpdateTesttagningByBrugerkodeApi(brugerkode, tekstlaesning, ordforraad, ordlaesning);
        }
    },[tekstlaesning, ordforraad, ordlaesning, brugerkode]);

    const downloadPdf = (brugerkode: string | null | undefined): void => {
        UpdateTesttagningByBrugerkodeApi(brugerkode, tekstlaesning, ordforraad, ordlaesning).then(() => {
            GetResultAsPdfByBrugerkodeAsElevApi(brugerkode).then(response => {
                window.open(response.request.responseURL, '');
            });
        });
    };

    return (
        <Page>
            <div className="container page-container">
                <h1>Din test er nu afsluttet, og dine svar er gemt</h1>
                <p>Luk ikke dette vindue, før du har talt med din testvejleder.</p>
                <br />
                <ToggleResultDisplay display={display} setDisplay={setDisplay} />
                <Summary
                    brugerkode={brugerkode}
                    display={display}
                    setDisplay={setDisplay}
                    result={result}
                    elev={elev}
                    setTekstlaesning={setTekstlaesning}
                    tekstlaesning={tekstlaesning}
                    setOrdforraad={setOrdforraad}
                    ordforraad={ordforraad}
                    setOrdlaesning={setOrdlaesning}
                    ordlaesning={ordlaesning}
                    testtagning={testtagning}/>
                { IsTest.isLaesetest(result?.testNavn) &&
                    <DetailsLaesetest brugerkode={brugerkode} display={display} setDisplay={setDisplay} result={result} testtagning={testtagning}/>
                }
                { IsTest.isMatematiktest(result?.testNavn) &&
                    <Details brugerkode={brugerkode} display={display} setDisplay={setDisplay} result={result} />
                }
                {display && <button className="button button-primary" onClick={() => downloadPdf(brugerkode)}>Download PDF</button>}
            </div>
        </Page>
    );
};

export default Resultater;
