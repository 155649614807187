import { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from "react";
import SupporterRequestUserDataModal from "../../../components/supporter/SupporterRequestUserDataModal/SupporterRequestUserDataModal";
import { TesttagningDetails } from "../../../shared/types/TesttagningDetails";
import TestResultTable from "../../../components/common/table/Table/TestResultTable";
import VejlederPage from "../../vejleder/VejlederPage";
import VejlederBrugerkoderTable from "../../../components/vejleder/VejlederBrugerkoderTable/VejlederBrugerkoderTable";
import { HoldDetails } from "../../../shared/types/HoldDetails";
import VejlederHoldTable from "../../../components/vejleder/VejlederHoldTable/VejlederHoldTable";
import { AuthenticatedUserDetails } from "../../../shared/types/AuthenticatedUserDetails";
import { SessionStorage } from "../../../shared/enums/Storage";
import SupporterRequestUserData from "../../../components/supporter/SupporterRequestUserDataModal/SupporterRequestUserData";

const SupporterSearchVejleder: FunctionComponent = () => {
    const [vejlederBrugernavn, setVejlederBrugernavn] = useState<string>("");
    const [testtagningerResultat, setTesttagningerResultat] = useState<TesttagningDetails[]>([]);
    const [resultaterIsShown, setResultaterIsShown] = useState<boolean>(true);
    const [testtagningerBrugerkoder, setTesttagningerBrugerkoder] = useState<TesttagningDetails[]>([]);
    const [brugerkoderIsShown, setBrugerkoderIsShown] = useState<boolean>(false);
    const [hold, setHold] = useState<HoldDetails[]>([]);
    const [holdIsShown, setHoldIsShown] = useState<boolean>(false);
    const [forceUpdate, setForceUpdate] = useState<number>(0);
    const [vejleder, setVejleder] = useState<AuthenticatedUserDetails>();
    const [supportsag, setSupportsag] = useState<string>();

    useEffect(() => {
        const supportsagInSession = window.sessionStorage.getItem(SessionStorage.SUPPORTSAG);
        if (supportsagInSession)
        {
            setSupportsag(supportsagInSession);
        }
    }, []);

    useEffect(() => {
        if (supportsag) {
            SupporterRequestUserData(supportsag, setTesttagningerResultat, setTesttagningerBrugerkoder, setHold, setVejleder);
        }
    }, [supportsag]);

    return (
        <VejlederPage>
            <h1 className="h1">Fremsøg testresultater</h1>
            <label className="form-label">Angiv brugernavn for vejleder: </label>
            <input
                type="string"
                value={vejlederBrugernavn}
                className="form-input"
                name="vejlederUsername"
                onChange={(e) => {
                    setVejlederBrugernavn(e.target.value);
                }}>
            </input>
            <br />
            <SupporterRequestUserDataModal
                vejlederBrugernavn={vejlederBrugernavn}
                setTesttagningerResultat={setTesttagningerResultat}
                setTesttagningerBrugerkoder={setTesttagningerBrugerkoder}
                setHold={setHold}
                setVejleder={setVejleder}
            />
            <hr />
            {vejleder &&
                <VejlederInfo vejleder={vejleder} />
            }
            <SupportsagButtons
                resultaterIsShown={resultaterIsShown}
                setResultaterIsShown={setResultaterIsShown}
                brugerkoderIsShown={brugerkoderIsShown}
                setBrugerkoderIsShown={setBrugerkoderIsShown}
                holdIsShown={holdIsShown}
                setHoldIsShown={setHoldIsShown}
            />
            {testtagningerResultat.length > 0 && resultaterIsShown &&
                <>
                    <TestResultTable
                        testtagninger={testtagningerResultat}
                        tableInfo={
                            <>Herunder finder du oplysninger på alle de brugerkoder, som dine testtagere har benyttet.{<br />} Hvis testen er afsluttet, kan resultatoversigten ses ved at klikke på navnet.</>
                        }
                        setForceUpdate={setForceUpdate}
                        mutable={false}
                    />
                </>
            }
            {testtagningerBrugerkoder.length > 0 && brugerkoderIsShown &&
                <>
                    <VejlederBrugerkoderTable testtagninger={testtagningerBrugerkoder} />
                </>
            }
            {hold.length > 0 && holdIsShown &&
                <>
                    <VejlederHoldTable hold={hold} forceUpdate={forceUpdate} setForceUpdate={setForceUpdate} />
                </>
            }
        </VejlederPage>
    );
};

export default SupporterSearchVejleder;

const SupportsagButtons: FunctionComponent<{
    resultaterIsShown: boolean;
    setResultaterIsShown: Dispatch<SetStateAction<boolean>>;
    brugerkoderIsShown: boolean;
    setBrugerkoderIsShown: Dispatch<SetStateAction<boolean>>;
    holdIsShown: boolean;
    setHoldIsShown: Dispatch<SetStateAction<boolean>>;
}> = ({ resultaterIsShown, setResultaterIsShown, brugerkoderIsShown, setBrugerkoderIsShown, holdIsShown, setHoldIsShown }) => {
    return (
        <>
            <div className="form-group">
                <button
                    className="button button-secondary"
                    onClick={() => {
                        setResultaterIsShown(true);
                        setBrugerkoderIsShown(false);
                        setHoldIsShown(false);
                    }}
                    disabled={resultaterIsShown}>
                    Resultater
                </button>
                <button
                    className="button button-secondary"
                    onClick={() => {
                        setResultaterIsShown(false);
                        setBrugerkoderIsShown(true);
                        setHoldIsShown(false);
                    }}
                    disabled={brugerkoderIsShown}>
                    Brugerkoder
                </button>
                <button
                    className="button button-secondary"
                    onClick={() => {
                        setResultaterIsShown(false);
                        setBrugerkoderIsShown(false);
                        setHoldIsShown(true);
                    }}
                    disabled={holdIsShown}>
                    Hold
                </button>
            </div>
        </>
    );
};

const VejlederInfo: FunctionComponent<{ vejleder: AuthenticatedUserDetails }> = ({ vejleder }) => {
    return (
        <>
            <div className="form-group">
                <h3>Vejleder</h3>
                <span>{`${vejleder.fornavn} ${vejleder.efternavn}`}</span>
            </div>
        </>
    );
};