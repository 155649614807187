import { FC } from 'react';
import { Redirect } from 'react-router-dom';
import { authorizationService } from "../../services/auth/authorization.service";
import { useKeycloak } from '@react-keycloak/web';

const Login: FC = () => {
  const { keycloak } = useKeycloak();

  const login = () => {
    const options = {
      redirectUri: window.location.origin + "/auth"
    };
    keycloak?.login(options)
        .catch((error) => {
          console.log("Error in keycloak login: ", error);
        });
  };


  if (authorizationService.isUserAuthorized() && keycloak.authenticated) {
    return <Redirect to={{
      pathname: '/auth',
    }} />;
  } else {
    login();
  }

  return <></>;
};

export default Login;
