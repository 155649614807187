import { FunctionComponent } from "react";
import styles from "./HjaelpendeLinks.module.scss";

const HjaelpendeLinks: FunctionComponent = () => {
    return (
        <div className="col-12 col-sm-12 col-md-3 footer-col">
            <h1 aria-label="Kontakt" className="h5 mb-4" title="Kontakt">
                Hjælpende links
            </h1>
            <ul className={`${styles.list} mt-0`}>
                <li>
                    <a
                        className="function-link"
                        rel="noopener noreferrer"
                        href="https://viden.stil.dk/x/Lo3WC"
                        target="_blank">
                        Find hjælp her
                    </a>
                </li>
                <li>
                    <a
                        className="function-link"
                        rel="noopener noreferrer"
                        href="https://www.uvm.dk/forberedende-grunduddannelse/undervisning-og-laeringsmiljoe/vejledende-laesetest-og-vejledende-matematiktest"
                        target="_blank">
                        Testvejledninger (FGU)
                    </a>
                </li>
                <li>
                    <a
                        className="function-link"
                        rel="noopener noreferrer"
                        href="https://www.uvm.dk/forberedende-voksenundervisning-fvu/maalgruppevurdering--screening-og-trinplacering"
                        target="_blank">
                        Testvejledninger (FVU)
                    </a>
                </li>
                <li>
                    <a
                        className="function-link"
                        rel="noopener noreferrer"
                        href="https://viden.stil.dk/display/STILVIDENOFFENTLIG/Tekniske+krav+for+brug+af+Testafvikleren.dk"
                        target="_blank">
                        Tekniske krav
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default HjaelpendeLinks;
