import { FunctionComponent } from "react";
import {useHistory, useLocation} from "react-router-dom";
import styles from "./NavigatePage.module.scss";

const NavigatePage: FunctionComponent<{
    label: string;
    path: string }> =
    ({label, path,}) => {
    const location = useLocation();
    const history = useHistory();

    return (
        <>
            <li className={location.pathname == path ? `current ${styles.link}` : `${styles.link}`}>
                <button onClick={() => history.push(path)}
                   className={`function-link ${styles.navLink} ${styles.noneDecoration} nav-link button-overflow-menu`} tabIndex={0} >
                    <span>{label}</span>
                </button>
            </li>
        </>
    );
};

export default NavigatePage;
