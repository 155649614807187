import { FunctionComponent } from "react";
import styles from "./Kontakt.module.scss";

const Kontakt: FunctionComponent = () => {
    return (
        <div className="col-12 col-sm-12 col-md-3 footer-col">
            <h1 aria-label="Kontakt" className="h5 mb-4" title="Kontakt">
                Kontakt
            </h1>
            <ul className={`${styles.list} mt-0`}>
                <li>
                    <a className="function-link" rel="noopener noreferrer" href={"https://jira.stil.dk/servicedesk/customer/portal/5/create/285"} target="_blank">
                        Opret supportsag
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default Kontakt;
