import { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";
import { authorizationService } from "../../services/auth/authorization.service";
import { Roles } from "../../shared/enums/Roles";

const AuthRedirect: FunctionComponent = () => {
    const history = useHistory();
  
    switch(authorizationService.getCurrentRole()) {
      case Roles.VEJLEDER:
        history.push("/vejleder/brugerkoder");
        break;
      case Roles.SUPPORTER:
        history.push("/supporter/searchVejleder");
        break;
      default:
        history.push("/auth/noaccess");
        break;
    }
    return <></>;
};

export default AuthRedirect;
