import { FC } from "react";
import styles from "./Navigation.module.scss";
import {authorizationService} from "../../../../services/auth/authorization.service";
import NavigateModule from "../NavigateModule/NavigateModule";
import NavigatePage from "../NavigatePage/NavigatePage";

export const Navigation: FC = () => {
    return (
        <>
            <header className="header">
                <div className="solution-header">
                    <div className="container">
                        <nav className="nav">
                            <div className="navbar">
                                <div className={`navbar-inner container ${styles.container}`}>
                                    <ul className={`nav-primary ${styles.containerNavPrimary}`}>
                                        <NavigateModule label={"Vejleder"} path={"/vejleder"} />
                                        { authorizationService.isSupporter() &&
                                            <NavigateModule label={"Support"} path={"/supporter"} />
                                        }
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
                <nav className="nav">
                    <div className="navbar navbar-primary">
                        <div className="navbar-inner container">
                            <ul className="nav-primary">
                                { window.location.href.indexOf("vejleder") > -1 &&
                                <>
                                    <NavigatePage label={"Brugerkoder"} path={"/vejleder/brugerkoder"} />
                                    <NavigatePage label={"Resultater"} path={"/vejleder/resultat"} />
                                    <NavigatePage label={"Hold"} path={"/vejleder/hold"} />
                                </>
                                }
                                { window.location.href.indexOf("supporter") > -1 &&
                                <>
                                    <NavigatePage label={"Fremsøg vejleder"} path={"/supporter/searchVejleder"} />
                                    <NavigatePage label={"P-nummer"} path={"/supporter/pnummer"} />
                                    <NavigatePage label={"CVR"} path={"/supporter/cvr"} />
                                    <NavigatePage label={"RID"} path={"/supporter/rid"} />
                                    <NavigatePage label={"Tilføj supporter"} path={"/supporter/manageSupporters"} />
                                </>
                                }
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    );
};
