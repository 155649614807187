import { FunctionComponent } from "react";
import Page from "../../components/common/Page/Page";

const Personoplysninger: FunctionComponent = () => {
    return (
        <Page>
            <div className="container page-container">
                <div>
                    <h2>Oplysning om brug af dine personoplysninger når du logger ind på testafvikleren</h2>
                    <h3>1 Formål med behandling af dine personoplysninger</h3>
                    <p>Formålet med Testafvikleren er at understøtte vejledende læsetest og  matematiktest til hhv. FGU og FVU.</p>
                    <p>Formålet med at behandle personoplysninger i forbindelse med login er til brug for identifikation:</p>
                    <ul>
                        <li>For testtagere: Dit navn og din fødselsdag stilles til rådighed for vejlederen til den test du skal tage, for at vejlederen kan identificere, at du er dig. 
                            Resultat benyttes til indplacering af testtager, og skal derfor kunne knyttes til den enkelte testtager.</li>
                        <li>For vejledere: Formålet med at trække dine personoplysninger, når du logger ind, er at kunne verificere, at du er dig via din medarbejdersignatur. 
                            Det er også muligt at bekræfte at du er tilknyttet en institution, som har adgang til at udstede tests gennem Testafvikleren.</li>
                    </ul>
                    <h3>2 Hvem er den dataansvarlige og hvordan kontakter du den dataansvarlige?</h3>
                    <p>STIL er databehandler på vegne af den institution, hvor testen tages.
                        Retsgrundlaget for behandlingen findes ude hos den enkelte institution, hvor testen tages.
                        Retsgrundlaget udspringer af:</p>
                    <p><strong>Vejledende test til FGU</strong> <br/>
                        Kravet om deltagelse i en obligatorisk test følger ”Bekendtgørelse af lov om forberedende grunduddannelse” (LBK nr. 606 af 24/05/2019) § 35 stk. 1, 
                        hvoraf det fremgår, at der foretages en vurdering af kursistens dansk- og/eller matematikfaglige niveau før uddannelsesstart. 
                        På baggrund af testresultatet træffes afgørelse om, om der skal henvises til FGU{"'"}s introducerende niveau i dansk og/eller matematik.</p>
                    <p><strong>Vejledende test til FVU</strong> <br/>
                        Kravet om deltagelse i den vejledende test til FVU følger ”Bekendtgørelse om undervisning m.v. inden for forberedende voksenundervisning” (BEK nr. 439 af 15/04/2020) § 10 og 11, hvoraf det fremgår, 
                        at der foretages en vurdering af kursistens matematikfaglige niveau før uddannelsesstart. På baggrund af testresultatet træffes afgørelse om, hvilket fagligt niveau kursisten skal placeres på i dansk og/eller matematik.
                        Kontaktoplysningerne på den dataansvarlige, finder du på hjemmesiden for den institution, som du er tilknyttet.</p>
                    <h3>3 Modtagere af dine personoplysninger</h3>
                    <p>Vi videregiver ikke dine personoplysninger til andre formål.</p>
                    <h3>4 Opbevaring af dine personoplysninger</h3>
                    <p>En vejleder har mulighed for at logge ind via Nemlogin og oprette brugerkoder, som udleveres til testtagere. 
                        Brugerkoden giver testtageren adgang til at foretage en test. Når en test er afsluttet, kan resultatet tilgås af vejleder i to år jf. BEK nr. 439 af 15/04/2020 § 11.</p>
                    <h3>5 Kontaktoplysninger på databeskyttelsesrådgiveren</h3>
                    <p>Hvis du har spørgsmål til vores behandling af dine oplysninger, er du altid velkommen til at kontakte vores databeskyttelsesrådgiver.
                        Du kan kontakte Børne- og Undervisningsministeriets databeskyttelsesrådgiver her: 
                        <a className="external-link" href="https://viden.stil.dk/pages/viewpage.action?pageId=77791403" rel="noopener noreferrer" target="_blank">https://viden.stil.dk/pages/viewpage.action?pageId=77791403</a>.
                        Databeskyttelsesrådgiveren kan oplyse dig nærmere om reglerne for databeskyttelse. Databeskyttelsesrådgiveren kan også vejlede dig om dine rettigheder i forhold til ministeriets behandling af personoplysninger.</p>
                    <h3>6 Dine rettigheder</h3>
                    <p>Du har efter databeskyttelsesforordningen en række rettigheder i forhold til vores behandling af oplysninger om dig.
                        Hvis du vil gøre brug af dine rettigheder, skal du kontakte os.</p>
                    <p><strong>Ret til at se oplysninger (indsigtsret)</strong> <br/>
                        Du har ret til at få indsigt i de oplysninger, som vi behandler om dig, samt en række yderligere oplysninger.</p>
                    <p><strong>Ret til berigtigelse (rettelse)</strong> <br/>
                        Du har ret til at få urigtige oplysninger om dig selv rettet.</p>
                    <p><strong>Ret til indsigelse og sletning</strong> <br/>
                        Du har i visse tilfælde ret til at gøre indsigelse mod vores ellers lovlige behandling af dine personoplysninger. Det kan ske, før billedet er blevet offentliggjort eller på et senere tidspunkt.</p>
                    <p><strong>Ret til at transmittere oplysninger (dataportabilitet)</strong> <br/>
                        Du har i visse tilfælde ret til at modtage dine personoplysninger i et struktureret, almindeligt anvendt og maskinlæsbart format samt at få overført disse personoplysninger fra én dataansvarlig til en anden uden hindring</p>
                    <p><strong>Dine rettigheder når dine personoplysninger behandles til statistiske formål</strong> <br/>
                        Dine rettigheder er begrænset i forhold til den statistiske behandling af dine personoplysninger. Dette betyder at du kan anmode STIL om at få udøvet en af ovenstående rettigheder i forhold til den statistiske behandling, men oftest vil denne anmodning ikke blive imødekommet.
                        Du kan læse mere om dine rettigheder i Datatilsynets vejledning om de registreredes rettigheder, som du finder på hjemmesiden www.datatilsynet.dk.</p>
                    <h3>7 Klage til Datatilsynet</h3>
                    <p>Du har ret til at indgive en klage til Datatilsynet, hvis du er utilfreds med den måde, vi behandler dine personoplysninger på. Du finder Datatilsynets kontaktoplysninger på hjemmesiden www.datatilsynet.dk.</p>
                </div>
            </div>
        </Page >
    );
};

export default Personoplysninger;