import { Dispatch, SetStateAction } from "react";
import { GetAuthenticatedUserBySupportsagIdApi } from "../../../apiService/AuthenticatedUserApi";
import { GetHoldBySupportsagIdApi } from "../../../apiService/HoldApi";
import { GetTesttagningerBySupportsagIdApi } from "../../../apiService/TesttagningApi";
import { AuthenticatedUserDetails } from "../../../shared/types/AuthenticatedUserDetails";
import { HoldDetails } from "../../../shared/types/HoldDetails";
import { formattedResult, TesttagningDetails } from "../../../shared/types/TesttagningDetails";
import { toastManager } from "../../common/Toast/ToastManager/ToastManager";

const SupporterRequestUserData:any = (
    supportsagId: string,
    setTesttagningerResult: Dispatch<SetStateAction<TesttagningDetails[]>>,
    setTesttagningerBrugerkoder: Dispatch<SetStateAction<TesttagningDetails[]>>,
    setHold: Dispatch<SetStateAction<HoldDetails[]>>,
    setVejleder: Dispatch<SetStateAction<AuthenticatedUserDetails | undefined>>
) => {
    GetTesttagningerBySupportsagIdApi(supportsagId).then((testtagningResponse) => {
        const testtagninger = testtagningResponse.data.data as TesttagningDetails[];

        for (const item of testtagninger) {
            if (item.elev.fornavn != null) {
                item.elev.fullName = item.elev.fornavn + " " + item.elev.efternavn;
            } else {
                item.elev.fullName = "-";
            }
        }

        for (const item of testtagninger) {
            item.formattedResult = formattedResult(item);
        }

        const sortedTesttagninger = testtagninger.sort(
            (a, b) => new Date(b.sluttidspunkt).getTime() - new Date(a.sluttidspunkt).getTime()
        );

        const testtagningerResult = sortedTesttagninger.filter((testtagning) => testtagning.starttidspunkt !== null);
        const testtagningerBrugerkoder = sortedTesttagninger.filter((testtagning) => testtagning.starttidspunkt === null);

        setTesttagningerResult(testtagningerResult);
        setTesttagningerBrugerkoder(testtagningerBrugerkoder);
    });
    GetHoldBySupportsagIdApi(supportsagId).then((holdResponse) => {
        const hold = holdResponse.data.data as HoldDetails[];
        const sortedHold = hold.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
        setHold(sortedHold);
    });
    GetAuthenticatedUserBySupportsagIdApi(supportsagId).then((authenticatedUser) => {
        const vejleder = authenticatedUser.data as AuthenticatedUserDetails;
        setVejleder(vejleder);
        if (vejleder) {
            toastManager.success("Modtaget data for '" + vejleder.brugernavn + "'.");
        } else {
            toastManager.success("Kan ikke finde vejlederen på nuværende tidspunkt.");
        }
    });
};

export default SupporterRequestUserData;