import { Elev } from "../shared/types/Elev";
import BaseApi from "./BaseApi";

export const UpdateElevAsElevApi = async (elev: Elev): Promise<any> => {
    return await BaseApi.put<Request>("Elev/UpdateAsElev", elev);
};

export const UpdateElevApi = async (elev: Elev): Promise<any> => {
    return await BaseApi.put<Request>("Elev", elev);
};

export const GetElevByBrugerkodeAsElevApi = async (brugerkode: string): Promise<any> => {
    return await BaseApi.get<Request>("Elev/GetByBrugerkodeAsElev?brugerkode=" + brugerkode);
};

export const GetElevByBrugerkodeAsAuthenticatedUserApi = async (brugerkode: string): Promise<any> => {
    return await BaseApi.get<Request>("Elev/GetByBrugerkodeAsAuthenticatedUser?brugerkode=" + brugerkode);
};
