import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { Icons } from "@material-table/core";

export const tableIcons: Icons = {
    // eslint-disable-next-line react/display-name
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    // eslint-disable-next-line react/display-name
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    // eslint-disable-next-line react/display-name
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    // eslint-disable-next-line react/display-name
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    // eslint-disable-next-line react/display-name
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    // eslint-disable-next-line react/display-name
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    // eslint-disable-next-line react/display-name
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    // eslint-disable-next-line react/display-name
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    // eslint-disable-next-line react/display-name
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    // eslint-disable-next-line react/display-name
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    // eslint-disable-next-line react/display-name
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    // eslint-disable-next-line react/display-name
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    // eslint-disable-next-line react/display-name
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    // eslint-disable-next-line react/display-name
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    // eslint-disable-next-line react/display-name
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
