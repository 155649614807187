import React from "react";

export const search = true;
export const searchFieldAlignment = "left";
export const pageSize = 20;
export const pageSizeOptions = [10, 20, 50];
export const headerStyle: React.CSSProperties = {
    fontSize: 14,
    fontWeight: "bold",
    backgroundColor: "#f5f5f5",
};

export const searchFieldStyle: React.CSSProperties = {
    fontSize: 14,
};
export const selection = true;
export const emptyRowsWhenPaging = false;
