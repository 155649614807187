import React, {FC} from "react";
import styles from "./TestResultInfo.module.scss";
import {ITestApprovalProps} from "../../../shared/interfaces/ITestApprovalProps";
import {ITesttagningProps} from "../../../shared/interfaces/ITesttagningProps";
import {IElevProps} from "../../../shared/interfaces/IElevProps";

type Props = ITestApprovalProps | ITesttagningProps | IElevProps;

enum ResultApprovalOptions {
    TEKSTLAESNING = "Tekstlæsning",
    ORDFORRAAD = "Ordforråd",
    ORDLAESNING = "Ordlæsning"
}

const TestResultInfo: FC<Props> = (props) => {
    const { setTekstlaesning, tekstlaesning, setOrdforraad, ordforraad, setOrdlaesning, ordlaesning } = props as ITestApprovalProps;
    const { testtagning } = props as ITesttagningProps;
    const { elev } = props as IElevProps;

    const CalculateAfsnit_1 = (): string | null => {
        if (!testtagning) {
            return null;
        }

        const sluttidspunkt = new Date(testtagning.del1Sluttidspunkt);
        const starttidspunkt = new Date(testtagning.del1Starttidspunkt);
        const totalTime = sluttidspunkt.getTime() - starttidspunkt.getTime();
        return totalTime < 240000 ? "Testen af læseforståelse gik nok for hurtigt" : null;
    };

    const CalculateAfsnit_2 = (): string | null => {
        if (!testtagning) {
            return null;
        }

        const sluttidspunkt = new Date(testtagning.del2Sluttidspunkt);
        const starttidspunkt = new Date(testtagning.del2Starttidspunkt);
        const totalTime = sluttidspunkt.getTime() - starttidspunkt.getTime();
        return totalTime < 60000 ? "Testen af ordforråd gik nok for hurtigt" : null;
    };

    const CalculateAfsnit_3 = (): string | null => {
        if (!testtagning || !elev.takeFullTest) {
            return null;
        }

        const sluttidspunkt = new Date(testtagning.del3Sluttidspunkt);
        const starttidspunkt = new Date(testtagning.del3Starttidspunkt);
        const totalTime = sluttidspunkt.getTime() - starttidspunkt.getTime();
        return totalTime < 140000 ? "Testen af afkodning gik nok for hurtigt" : null;
    };

    return (
        <div>
            <div className={`${styles.infoDiv}`}>
                { CalculateAfsnit_1() &&
                    <span className={`${styles.labelText}`}>{CalculateAfsnit_1()}</span>
                }
                { CalculateAfsnit_2() &&
                <span className={`${styles.labelText}`}>{CalculateAfsnit_2()}</span>
                }
                { CalculateAfsnit_3() &&
                <span className={`${styles.labelText}`}>{CalculateAfsnit_3()}</span>
                }
            </div>

            <div>
                <span className={`${styles.labelText}`}>Testlederens godkendelse. Testen er gennemført pålideligt:</span>
                <input
                    id={ResultApprovalOptions.TEKSTLAESNING}
                    className={`${styles.checkbox}`}
                    type="checkbox"
                    checked={tekstlaesning}
                    onChange={() => setTekstlaesning(!tekstlaesning)}/>
                <label className={`${styles.checkboxLabel}`} htmlFor={ResultApprovalOptions.TEKSTLAESNING}>{ResultApprovalOptions.TEKSTLAESNING}</label>
                <input
                    id={ResultApprovalOptions.ORDFORRAAD}
                    className={`${styles.checkbox}`}
                    type="checkbox"
                    checked={ordforraad}
                    onChange={() => setOrdforraad(!ordforraad)}/>
                <label className={`${styles.checkboxLabel}`} htmlFor={ResultApprovalOptions.ORDFORRAAD}>{ResultApprovalOptions.ORDFORRAAD}</label>
                { elev.takeFullTest &&
                    <div>
                        <input
                            id={ResultApprovalOptions.ORDLAESNING}
                            type="checkbox"
                            checked={ordlaesning}
                            onChange={() => setOrdlaesning(!ordlaesning)}/>
                        <label className={`${styles.checkboxLabel}`} htmlFor={ResultApprovalOptions.ORDLAESNING}>{ResultApprovalOptions.ORDLAESNING}</label>
                    </div>
                }
            </div>
        </div>
    );
};

export default TestResultInfo;
