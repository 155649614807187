import * as React from 'react';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import type { RouteProps } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import {authorizationService} from "../../services/auth/authorization.service";

interface PrivateRouteParams extends RouteProps {
  component:
  | React.ComponentType<RouteComponentProps<any>>
  | React.ComponentType<any>
}

export const PrivateRoute = ({
  component: Component,
  ...rest
}: PrivateRouteParams): JSX.Element => {
  const { initialized } = useKeycloak();

  if (!initialized) {
    return <div>Loading...</div>;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return authorizationService.isUserAuthorized() ?
          <Component {...props} /> :
          <Redirect to={{ pathname: '/auth/login', state: { from: props.location } }} />;
      }}
    />
  );
};
