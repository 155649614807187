import React, { FunctionComponent, useEffect, useState } from "react";
import VejlederPage from "../VejlederPage";
import { GetTesttagningerAsAuthenticatedUserApi } from "../../../apiService/TesttagningApi";
import { formattedResult, TesttagningDetails } from "../../../shared/types/TesttagningDetails";
import TestResultTable from "../../../components/common/table/Table/TestResultTable";
import { useSessionStorage } from "../../../components/common/table/shared/utils/useSessionStorage";
import { SessionStorage } from "../../../shared/enums/Storage";

const VejlederResultat: FunctionComponent = () => {
    const [testtagninger, setTesttagninger] = useState<TesttagningDetails[]>([]);
    const [forceUpdate, setForceUpdate] = useState(0);
    const [showArchived] = useSessionStorage(SessionStorage.SHOW_ARCHIVED, false);

    useEffect(() => {
        GetTesttagningerAsAuthenticatedUserApi(true).then((response) => {
                const testtagninger = response.data.data as TesttagningDetails[];

                for (const item of testtagninger) {
                    if (item.elev.fornavn != null) {
                        item.elev.fullName = item.elev.fornavn + " " + item.elev.efternavn;
                    } else {
                        item.elev.fullName = "-";
                    }
                }

                for (const item of testtagninger) {
                    item.formattedResult = formattedResult(item);
                }

                const sortedTesttagninger = testtagninger.sort(
                    (a, b) => new Date(b.sluttidspunkt).getTime() - new Date(a.sluttidspunkt).getTime()
                );
                setTesttagninger(sortedTesttagninger);
            });
    }, [forceUpdate, showArchived]);

    return (
        <VejlederPage>
            <h1 className="h1">Resultater</h1>
                <TestResultTable
                    testtagninger={testtagninger}
                    tableInfo={
                        <>
                            Se oplysninger om igangværende og afsluttede test. <br/>
                            Hvis testen er afsluttet, kan resultatoversigten tilgås ved at klikke på navnet.
                            <p>
                                <b>Bemærk: </b>
                                Testresultater opbevares i to år, hvorefter de kasseres. Du bør undersøge, om din
                                institution gemmer resultater centralt, og i så fald overføre dem fra Testafvikleren.
                            </p>
                        </>
                    }
                    setForceUpdate={setForceUpdate}
                    mutable={true}
                />
        </VejlederPage>
    );
};

export default VejlederResultat;
