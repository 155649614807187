import {AxiosResponse} from "axios";
import BaseApi from "./BaseApi";
import {ResponseType} from "../shared/types/ResponseType";
import {RuleDetails} from "../shared/types/RuleDetails";

export const GetRulesByType = async (valueType: string): Promise<RuleDetails[]> => {
    return BaseApi.get<Request, AxiosResponse<any, any>>("Rule/GetByType?valueType=" + valueType).then((response) => {
        const rules = response.data.data as RuleDetails[];
        return rules.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    });
};

export const DeleteRule = async (ruleId: string): Promise<ResponseType<string>> => {
    try {
        const response = await BaseApi.delete<Request, AxiosResponse<any, any>>("Rule?ruleId=" + ruleId);
        return {
            data: response.data.data,
            succeeded: true,
            Message: response.data.message,
            errors: [],
        };
    } catch (error: any) {
        return {
            data: undefined,
            succeeded: false,
            Message: "",
            errors: [error.response.data.Message],
        };
    }
};

export const CreateRule = async (value: string, valueType: string, role: string, opgavesaetId?: string): Promise<ResponseType<string>> => {
    try {
        const response = await BaseApi.post<Request, AxiosResponse<any, any>>("Rule", { value, valueType, role, opgavesaetId });
        return {
            data: response.data.data,
            succeeded: true,
            Message: response.data.message,
            errors: [],
        };
    } catch (error: any) {
        return {
            data: undefined,
            succeeded: false,
            Message: "",
            errors: [error.response.data.Message],
        };
    }
};

export const CreateRules = async (values: string[], valueType: string, role: string, opgavesaetId?: string): Promise<ResponseType<string>> => {
    try {
        const response = await BaseApi.post<Request, AxiosResponse<any, any>>("Rule/createRules", { values, valueType, role, opgavesaetId });
        return {
            data: response.data.data,
            succeeded: true,
            Message: response.data.message,
            errors: [],
        };
    } catch (error: any) {
        return {
            data: undefined,
            succeeded: false,
            Message: "",
            errors: [error.response.data.Message],
        };
    }
};
