import { useKeycloak } from "@react-keycloak/web";
import { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "./LoginInfo.module.scss";
import { authorizationService } from "../../../services/auth/authorization.service";
import { GetAuthenticatedUserApi } from "../../../apiService/AuthenticatedUserApi";

export const LoginInfo: FC = () => {
    const { keycloak } = useKeycloak();
    const history = useHistory();
    const [name, setName] = useState<string>();
    const [brugernavn, setBrugernavn] = useState<string>();

    const handleLogout = () => {
        const logoutOptions = { redirectUri : window.location.origin };
        keycloak.logout(logoutOptions);
        authorizationService.clearSessionStorage();
    };

    const handleLogin = () => {
        history.push("/auth/login");
    };

    const toDashboard = () => {
        if (authorizationService.isSupporter()) {
            history.push("/vejleder");
        } else if (authorizationService.isVejleder()) {
            history.push("/vejleder");
        } else if (authorizationService.inNone()){
            history.push("/auth/noaccess");
        }
    };

    const signedIn = (
        <div className="portal-info">
            <button className={`function-link user ${styles.userLink} ${styles.noneDecoration}`} onClick={toDashboard} tabIndex={0}>
                {name} (ID: {brugernavn})             
            </button>
            <button className="button button-secondary d-print-none" onClick={handleLogout}>
                Log af
            </button>
        </div>
    );

    const signedOut = (
        <div className="portal-info">
            <button className="button button-secondary d-print-none" onClick={handleLogin}>
                Vejlederlogin
            </button>
        </div>
    );

    useEffect(() => {
        if (keycloak.authenticated) {
            GetAuthenticatedUserApi().then((response) => {
                if (response.succeeded && response.data) {
                    setName(`${response.data.fornavn} ${response.data.efternavn}`);
                    setBrugernavn(response.data.brugernavn);
                }
            }).catch((error) => {
                console.log("GetAuthenticatedUserApi failed: ", error);
            });

        } else {
            authorizationService.clearSessionStorage();
            setName(undefined);
            setBrugernavn(undefined);
        }
    }, [keycloak.authenticated]);

    return keycloak.authenticated ? signedIn : signedOut;
};
