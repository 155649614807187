import styles from "./OpgaveText.module.scss";

const OpgaveText = (props: { opgaver: any }): JSX.Element => {
    let opgaveText = "";
    
    props.opgaver.forEach((opgave: any) => {
        let svarOptions = "";
        switch (opgave.type) {
            //This case corresponds to assignments with radiobutton answers
            case 6:
                opgaveText += opgave.tekst.replaceAll("#", "") + " ";
                break;
            //This case corresponds to assignments with DropDown answers
            case 7:
                svarOptions += " (";
                opgave.svarmuligheder.forEach((svar: string) => {
                    svarOptions += svar + ", ";
                });
                svarOptions = svarOptions.substring(0, svarOptions.lastIndexOf(","));
                svarOptions += ")";
                opgaveText += opgave.tekst.replaceAll(" <Dropdown>", `<b> ${svarOptions} </b>`).replaceAll(" ,", ",") + " ";
                break;
        }
    });
    return (<div className={styles.alignOpgaveText}><span key={Math.random()} dangerouslySetInnerHTML={{ __html: opgaveText }}/></div>);
};

export default OpgaveText;