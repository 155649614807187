const createDownloadLink = (byteArray: string, format: string, fileName: string): void => {
    const binaryString = window.atob(byteArray);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    const blob = new Blob([bytes], {type: `application/${format}`});
    const link = window.URL.createObjectURL(blob);
    const downloadLink = document.createElement("a");
    downloadLink.href = link;
    downloadLink.download = `${fileName}.${format}`;
    downloadLink.click();
};

export default createDownloadLink;
