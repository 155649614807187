import axios, { AxiosError } from "axios";
import React, {FC, useContext, useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import {GetElevByBrugerkodeAsElevApi, UpdateElevAsElevApi} from "../../../apiService/ElevApi";
import { ResponseType } from "../../../shared/types/ResponseType";
import { genericWarning, noWarning } from "../../common/Warnings";
import moment from "moment";
import { Elev } from "../../../shared/types/Elev";
import { SessionStorage } from "../../../shared/enums/Storage";
import {GetOpgavesaetByBrugerkodeApi} from "../../../apiService/OpgavesaetApi";
import styles from "./RegisterElev.module.scss";
import {LanguageOptions} from "../../../shared/enums/LanguageOptions";
import { UpdateTesttagningOpgavesaetAsElevApi} from "../../../apiService/TesttagningApi";
import { faVolumeUp } from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {TakeFullTestOptions} from "../../../shared/enums/TakeFullTestOptions";
import OpgavesaetContext from "../../OpgavesaetContext";
import {IsTest} from "../../../shared/utils/IsTest";

const RegisterElev: FC = () => {
    const [fornavn, setFornavn] = useState("");
    const [efternavn, setEfternavn] = useState("");
    const [holdId, setHoldId] = useState("");
    const [day, setDay] = useState("");
    const [month, setMonth] = useState("");
    const [year, setYear] = useState("");
    const [opgavesaetName, setOpgavesaetName] = useState("");
    const [bedsteSprog, setBedsteSprog] = useState<string>();
    const [sprog, setSprog] = useState<string>();
    const [takeFullTest, setTakeFullTest] = useState<string>(TakeFullTestOptions.TAKE_FULL_TEST);
    const [warning, setWarning] = useState<JSX.Element>(noWarning);
    const history = useHistory();
    const [birthdayValidateError, setBirthdayValidateError] = useState(false);
    const [birthdayValidateMessage, setBirthdayValidateMessage] = useState("");
    const audio = new Audio("/api/v1/Sound?name=Register_checkSound");
    const { setOpgavesaet, setTestnavn } = useContext(OpgavesaetContext);

    useEffect(() => {
        const brugerkode = window.sessionStorage.getItem(SessionStorage.BRUGERKODE);
        if (brugerkode != undefined) {
            GetElevByBrugerkodeAsElevApi(brugerkode).then((response) => {
                const elev: any = response.data.data;
                if (elev.foedselsdag != null) {
                    const foedselsdag: Date = new Date(elev.foedselsdag);
                    const offset = foedselsdag.getTimezoneOffset();
                    const foedselsdagKorrigeret = new Date(foedselsdag.getTime() - offset * 60 * 1000);
                    setDay(foedselsdagKorrigeret.getDate().toString());
                    setMonth((foedselsdagKorrigeret.getMonth() + 1).toString());
                    setYear(foedselsdagKorrigeret.getFullYear().toString());
                }
                if (elev.fornavn) {
                    setFornavn(elev.fornavn);
                }
                if (elev.efternavn) {
                    setEfternavn(elev.efternavn);
                }

                if (elev.sprog) {
                    setSprog(elev.sprog);
                }

                if (elev.bedsteSprog) {
                    setBedsteSprog(elev.bedsteSprog.toString());
                }
                
                setHoldId(elev.holdId);
                GetOpgavesaetByBrugerkodeApi(brugerkode).then((opgavesaetResponse) => {
                    setOpgavesaetName(opgavesaetResponse.data.navn);
                    if (IsTest.isFGUL(opgavesaetName) && elev.takeFullTest !== null) {
                        elev.takeFullTest ? setTakeFullTest(TakeFullTestOptions.TAKE_FULL_TEST) : setTakeFullTest(TakeFullTestOptions.NOT_TAKE_FULL_TEST);
                    }
                });
            });
        }
    }, [opgavesaetName]);

    const handleSubmit = async (event: { preventDefault: () => void }) => {
        setBirthdayValidateError(false);
        setBirthdayValidateMessage("");
        event.preventDefault();
        const regexpDay = new RegExp("\\b(0?[1-9]|[12][0-9]|3[01])\\b");
        const regexpMonth = new RegExp("^(0?[1-9]|1[012])$");
        const regexpYear = new RegExp("^(19|20)\\d{2}$");

        if (!regexpDay.test(day)) {
            setBirthdayValidateError(true);
            setBirthdayValidateMessage("Indtast en dag i tal mellem 1 og 31");
            return;
        }

        if (!regexpMonth.test(month)) {
            setBirthdayValidateError(true);
            setBirthdayValidateMessage("Indtast en måned i tal mellem 1 og 12");
            return;
        }

        if (!regexpYear.test(year)) {
            setBirthdayValidateError(true);
            setBirthdayValidateMessage("Indtast et årstal mellem 1900 og 2099");
            return;
        }

        const date = `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;

        if (!moment(date).isValid()) {
            setBirthdayValidateError(true);
            setBirthdayValidateMessage("Indtast en gyldig dato");
            return;
        }

        const elev: Elev = {
            brugerkode: window.sessionStorage.getItem(SessionStorage.BRUGERKODE)!,
            fornavn: fornavn,
            efternavn: efternavn,
            foedselsdato: date,
            holdId: holdId,
            takeFullTest: shouldTakeFullTest(),
            sprog: sprog,
            bedsteSprog: Number(bedsteSprog)
        };

        try {
            await UpdateElevAsElevApi(elev);
            if (IsTest.isLaesetest(opgavesaetName)) {
                if (!shouldTakeFullTest()) {
                    UpdateTesttagningOpgavesaetAsElevApi(elev.brugerkode).then(() => {
                        GetOpgavesaetByBrugerkodeApi(elev.brugerkode).then((opgavesaetResponse) => {
                            if (opgavesaetResponse.succeeded) {
                                setOpgavesaet!(opgavesaetResponse.data);
                                setTestnavn!(opgavesaetResponse.data.navn);
                                history.push(`/test/${elev.brugerkode}`);
                            }
                        });
                    });
                } else {
                    history.push(`/test/${elev.brugerkode}`);
                }
            } else {
                history.push(`/test/${elev.brugerkode}`);
            }
        } catch (error: unknown | AxiosError<ResponseType<any>>) {
            if (axios.isAxiosError(error)) {
                const e = error as AxiosError<ResponseType<any>>;
                if (e.response != undefined) {
                    setWarning(genericWarning(e.response.data.Message));
                }
            } else {
                setWarning(genericWarning("Unknown error"));
            }
        }
    };

    const shouldTakeFullTest = (): boolean | null => {
        if (takeFullTest === TakeFullTestOptions.TAKE_FULL_TEST) {
            return true;
        } else if (takeFullTest === TakeFullTestOptions.NOT_TAKE_FULL_TEST) {
            return false;
        }

        return null;
    };


    const birthdayError = () => {
        if (birthdayValidateError) {
            return (
                <span className="form-error-message">
                    <span className="sr-only">Fejl:</span>
                    {birthdayValidateMessage}
                </span>
            );
        } else {
            return <></>;
        }
    };

    const disabled = (): boolean => {
        if (IsTest.isVLV(opgavesaetName)) {
            return !fornavn || !efternavn || !day || !month || !year || !bedsteSprog;
        } else if (IsTest.isFGUL(opgavesaetName)) {
            if (bedsteSprog === LanguageOptions.IKKE_DANSK.toString()) {
                return !fornavn || !efternavn || !day || !month || !year || !bedsteSprog || !sprog || !takeFullTest;
            }

            return !fornavn || !efternavn || !day || !month || !year || !bedsteSprog || !takeFullTest;
        }

        return !fornavn || !efternavn || !day || !month || !year;
    };

    const playSound = () => {
        audio.pause();
        audio.currentTime = 0;
        audio.play();
    };

    return (
        <div>
            <h1 className="h2">Dine oplysninger</h1>
            {warning}
            { IsTest.isLaesetest(opgavesaetName) &&
                <button className={`button button-secondary d-print-none ${styles.normalWeight} ${styles.soundButton}`} onClick={playSound} >
                    <FontAwesomeIcon className={`${styles.soundIcon}`} size="sm" icon={faVolumeUp}/>
                    Tjek lyden
                </button>
            }
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="fornavn" className="form-label">
                        Fornavn
                    </label>
                    <input
                        type="text"
                        className="form-input"
                        name="fornavn"
                        id="fornavn"
                        value={fornavn}
                        onChange={(e) => setFornavn(e.target.value)}>
                    </input>
                </div>
                <div className="form-group">
                    <label htmlFor="efternavn" className="form-label">
                        Efternavn
                    </label>
                    <input
                        type="text"
                        className="form-input"
                        name="efternavn"
                        id="efternavn"
                        value={efternavn}
                        onChange={(e) => setEfternavn(e.target.value)}>
                    </input>
                </div>
                <div className={`form-label form-group ${birthdayValidateError ? "form-error" : ""}`}>
                    <legend className={`form-label form-group`}>Fødselsdato</legend>
                    <span className="form-hint">For eksempel: 27 9 2001</span>
                    <div>{birthdayError()}</div>
                    <div className={`date-group mt-3 form-label form-group}`}>
                        <div className="form-group form-group-day">
                            <label className="form-label" htmlFor="day">
                                Dag
                            </label>
                            <input className="form-input" value={day} id="day" onChange={(e) => setDay(e.target.value)} />
                        </div>
                        <div className="form-group form-group-month">
                            <label className="form-label" htmlFor="month">
                                Måned
                            </label>
                            <input className="form-input" value={month} id="month" onChange={(e) => setMonth(e.target.value)} />
                        </div>
                        <div className="form-group form-group-year ">
                            <label className="form-label" htmlFor="year">
                                År
                            </label>
                            <input className="form-input" value={year} id="year" onChange={(e) => setYear(e.target.value)} />
                        </div>
                    </div>
                    { IsTest.isLaesetest(opgavesaetName) &&
                        <div className="form-group">
                            <label className="form-label">Hvilket sprog er du bedst til?</label>
                            <div className={`${styles.normalWeight}`}>
                                <div className={`${styles.optionContainer}`}>
                                    <input
                                           id={LanguageOptions.DANSK.toString()}
                                           type="radio"
                                           checked={bedsteSprog === LanguageOptions.DANSK.toString()}
                                           value={LanguageOptions.DANSK}
                                           onChange={(e) => {
                                               setBedsteSprog(e.target.value);
                                               setSprog(undefined);
                                           }}/>
                                    <label htmlFor={LanguageOptions.DANSK.toString()}> Dansk</label>
                                </div>
                                <div className={`${styles.optionContainer}`}>
                                    <input
                                           id={LanguageOptions.IKKE_DANSK.toString()}
                                           type="radio"
                                           checked={bedsteSprog === LanguageOptions.IKKE_DANSK.toString()}
                                           value={LanguageOptions.IKKE_DANSK}
                                           onChange={(e) => setBedsteSprog(e.target.value)}/>
                                    <label htmlFor={LanguageOptions.IKKE_DANSK.toString()}> Et andet sprog end dansk</label>
                                </div>
                                { IsTest.isVLV(opgavesaetName) &&
                                    <div className={`${styles.optionContainer}`}>
                                        <input
                                               id={LanguageOptions.DANSK_OG_ET_ANDET_SPROG.toString()}
                                               type="radio"
                                               checked={bedsteSprog === LanguageOptions.DANSK_OG_ET_ANDET_SPROG.toString()}
                                               value={LanguageOptions.DANSK_OG_ET_ANDET_SPROG}
                                               onChange={(e) => setBedsteSprog(e.target.value)}/>
                                        <label htmlFor={LanguageOptions.DANSK_OG_ET_ANDET_SPROG.toString()}> Både dansk og et andet sprog</label>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    { IsTest.isFGUL(opgavesaetName) && bedsteSprog === LanguageOptions.IKKE_DANSK.toString() &&
                        <div className="form-group">
                            <label htmlFor="inputSprog" className="form-label">
                                Skriv det sprog, eleven taler bedst
                            </label>
                            <input
                                type="text"
                                className={`form-input ${styles.normalWeight}`}
                                name="inputSprog"
                                id="inputSprog"
                                value={sprog}
                                onChange={(e) => setSprog(e.target.value)}>
                            </input>
                        </div>
                    }
                    { IsTest.isFGUL(opgavesaetName) &&
                        <div className="form-group">
                            <label className="form-label">
                                Skal du testes i Deltest 3 - Ordlæsning? (Spørg din vejleder)
                            </label>
                            <div className={`${styles.normalWeight}`}>
                                <div className={`${styles.optionContainer}`}>
                                    <input id={TakeFullTestOptions.TAKE_FULL_TEST}
                                           type="radio"
                                           checked={takeFullTest === TakeFullTestOptions.TAKE_FULL_TEST}
                                           value={TakeFullTestOptions.TAKE_FULL_TEST}
                                           onChange={(e) => setTakeFullTest(e.target.value)}/>
                                    <label htmlFor={TakeFullTestOptions.TAKE_FULL_TEST}> Ja</label>
                                </div>
                                <div className={`${styles.optionContainer}`}>
                                    <input id={TakeFullTestOptions.NOT_TAKE_FULL_TEST}
                                           type="radio"
                                           checked={takeFullTest === TakeFullTestOptions.NOT_TAKE_FULL_TEST}
                                           value={TakeFullTestOptions.NOT_TAKE_FULL_TEST}
                                           onChange={(e) => setTakeFullTest(e.target.value)}/>
                                    <label htmlFor={TakeFullTestOptions.NOT_TAKE_FULL_TEST}> Nej</label>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="action-buttons mt-4">
                        <input
                            type="submit"
                            className="button button-primary button-long"
                            name="aaben"
                            value="Gem"
                            disabled={disabled()}>
                        </input>
                    </div>
                    <div className="form-group">
                        <button className={`function-link`} tabIndex={0} onClick={() => history.push("/personoplysninger")} >
                        Sådan behandler vi dine personoplysninger
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default RegisterElev;
