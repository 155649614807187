import { FunctionComponent, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Page from "../../components/common/Page/Page";
import RegisterElev from "../../components/vejleder/RegisterElev/RegisterElev";
import { SessionStorage } from "../../shared/enums/Storage";

const Elev: FunctionComponent = () => {
    const history = useHistory();

    useEffect(() => {
        if (window.sessionStorage.getItem(SessionStorage.BRUGERKODE) === null) {
            history.push("/");
        }
    }, [history]);

    return (
        <Page>
            <div className="container page-container">
                <RegisterElev />
            </div>
        </Page>
    );
};

export default Elev;
