import { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";
import styles from "./NavigateModule.module.scss";

const NavigateModule: FunctionComponent<{
    label: string;
    path: string }> =
    ({label, path,}) => {
    const history = useHistory();

    return (
        <>
            <li className={window.location.href.indexOf(path) > -1 ? `current ${styles.link}` : `${styles.link}`}>
                <button onClick={() => history.push(path)} className={`alert-leave2 function-link button-overflow-menu ${styles.link} ${styles.noneDecoration}`} tabIndex={0} >
                    <span className={`h3 ${styles.header}`}>{label}</span>
                </button>
            </li>
        </>
    );
};

export default NavigateModule;
