import { FunctionComponent } from "react";
import Logo from "../Logo";
import AccessibilityCookies from "../AccessibilityCookies/AccessibilityCookies";
import Kontakt from "../Kontakt/Kontakt";
import HjaelpendeLinks from "../HjaelpendeLinks/HjaelpendeLinks";
import styles from "./Footer.module.scss";

const Footer: FunctionComponent = () => {
    return (
        <div>
            <footer className={styles.footer}>
                <div className="footer">
                    <div className="container">
                        <div className="row">
                            <Logo />
                            <Kontakt />
                            <HjaelpendeLinks />
                            <AccessibilityCookies />
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Footer;
