import { FC, useEffect, useState } from "react";
import { IBrugerkodeProps } from "../../../shared/interfaces/IBrugerkodeProps";
import { HovedopgaveResult } from "../../../shared/types/Result";
import { IDisplayProps } from "../ToggleResultDisplay";
import {IResultProps} from "../../../shared/interfaces/IResultProps";
import styles from "./Details.module.scss";
import {IOpgavebesvarelserProps} from "../../../shared/interfaces/IOpgavebesvarelserProps";

type ResultRow = {
    name: string;
    correct: number;
    total: number;
    class: string;
    group: number;
};

type Props = IDisplayProps | IBrugerkodeProps | IResultProps | IOpgavebesvarelserProps | any;

const Details: FC<Props> = (props) => {
    const { display } = props as IDisplayProps;
    const { brugerkode } = props as IBrugerkodeProps;
    const { result } = props as IResultProps;
    const [resultHeader, setResultHeader] = useState<ResultRow[]>(new Array<ResultRow>());

    useEffect(() => {
        let results: ResultRow[] = [];
        let group = 0;
        if (result?.afsnit?.length > 0) {
            console.log("result", result);
            result.afsnit.map(
                (a: { navn: string; correct: number; total: number; hovedopgaver: Array<HovedopgaveResult> }) => {
                    results = [
                        ...results,
                        {
                            name: a.navn,
                            correct: a.correct,
                            total: a.total,
                            class: "hovedopgave",
                            group: group,
                        },
                    ];
                    a.hovedopgaver.forEach((h) => {
                        results = [
                            ...results,
                            {
                                name: "Opgave " + h.overskrift,
                                correct: h.correct,
                                total: h.total,
                                class: "opgave displayNone",
                                group: group,
                            },
                        ];
                    });
                    group += 1;
                }
            );
            setResultHeader(results);
        }
    }, [brugerkode, result]);

    const ToggleDisplay = (className: string, group: string) => {
        if (className === "hovedopgave") {
            const opgaver = document.getElementsByClassName("opgave");
            Array.from(opgaver).forEach((element) => {
                if (element.classList.contains(group)) {
                    element.classList.toggle("displayNone");
                }
            });

            const hovedopgaver = document.getElementsByClassName("detailResultat");
            Array.from(hovedopgaver).forEach((element) => {
                if (element.classList.contains(group)) {
                    element.classList.toggle("detail_open");
                    element.classList.toggle("detail_closed");
                }
            });
        }
    };

    const resultInPercentange: string =
        result != undefined && result?.resultInPercentange != undefined
            ? parseFloat(result?.resultInPercentange).toFixed(1).replaceAll(".", ",")
            : "";
    const resultAsFraction: string =
        result != undefined && result.correct != undefined && result.total != undefined
            ? `${result.correct} af ${result.total}`
            : "";

    const displayResultatoversigt = () => {
        return (
            <tbody>
                <tr className={styles.samletResultat}>
                    <th>{result?.testNavn}, samlet resultat</th>
                    <td>{resultAsFraction}</td>
                    <td>{`(${resultInPercentange} %)`} </td>
                </tr>
                {resultHeader.map((row) => (
                    <tr
                        className={row.class + " " + row.group.toString()}
                        key={Math.random()}
                        onClick={() => ToggleDisplay(row.class, row.group.toString())}>
                        <td tabIndex={0} onKeyDown={(e) => (e.key === 'Enter' || e.key === 'Space' || e.key === ' ') && ToggleDisplay(row.class, row.group.toString())}
                            className={
                                (row.class === "hovedopgave" ? "detail detail_closed detailResultat " : " ") + row.group.toString()
                            }>
                            {row.name}
                        </td>
                        <td className="w-percent-md-15">
                            {row.correct} af {row.total}{" "}
                        </td>
                        <td className="w-percent-md-15">
                            {row.class === "hovedopgave" &&
                                "(" +
                                    ((row.correct / row.total) * 100).toFixed(1).toString().replaceAll(".", ",") +
                                    " %)"}
                        </td>
                    </tr>
                ))}
            </tbody>
        );
    };

    if (!display) {
        return <></>;
    } else {
        return (
            <>
                <h2>Resultatoversigt</h2>
                <table className={`${styles.table} table table--borderless table--compact table--responsive-headers d-print-table mb-7 w-percent-lg-70`}>
                    <thead>
                        <tr>
                            <th className="w-percent-70">Navn</th>
                            <th className={styles.result}>Point</th>
                            <th className={styles.result}/>
                        </tr>
                    </thead>
                    { displayResultatoversigt() }
                </table>
            </>
        );
    }
};

export default Details;
