import { ChangeEvent, Dispatch, FunctionComponent, SetStateAction, useState } from "react";
import { HoldDetails } from "../../../shared/types/HoldDetails";
import $ from "jquery";
import { ElevDetails } from "../../../shared/types/ElevDetails";
import { UpdateElevApi } from "../../../apiService/ElevApi";
import { Elev } from "../../../shared/types/Elev";
import styles from "./VejlederChangeHoldForTesttagningModal.module.scss";
import updateTable from "../../shared/utils/updateTable";

const VejlederChangeHoldForTesttagningModal: FunctionComponent<{ elev: ElevDetails; setForceUpdate: Dispatch<SetStateAction<number>>; hold: HoldDetails[] }> = ({
    elev,
    setForceUpdate,
    hold
}) => {
    const [selectedHold, setSelectedHold] = useState<HoldDetails | undefined>(elev.hold);

    const holdOptions =
        hold.length > 0 &&
        hold.map((item) => {
            return (
                <option key={item.id} id={item.id} value={item.navn}>
                    {item.navn}
                </option>
            );
        });

    const toggleHoldModal = () => {
        $(`#modal-hold-${elev.id}`).toggle();
        $("#modal-backdrop").toggle();
    };

    const handleSelectedChangeHold = (event: ChangeEvent<HTMLSelectElement>) => {
        event.preventDefault();
        const selectedIndex = event.target.options.selectedIndex;
        const id = event.target.options[selectedIndex].getAttribute("id");
        setSelectedHold(hold.find((h) => h.id == id));
    };

    const handleSubmit = async (event: { preventDefault: () => void }) => {
        event.preventDefault();
        const updatedElev: Elev = { ...elev, holdId: selectedHold?.id, foedselsdato: elev.foedselsdag?.toString() };
        await UpdateElevApi(updatedElev);
        setForceUpdate(updateTable());
        toggleHoldModal();
    };

    return (
        <>
            <div className="fds-modal" id={`modal-hold-${elev.id}`} aria-hidden="true" role="dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="modal-title">Skift hold</h2>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label className="form-label">Til hvilket hold skal testtager tilknyttes?</label>
                            <select
                                onChange={(e) => handleSelectedChangeHold(e)}
                                className="item form-select"
                                style={{ width: "auto" }}
                                value={selectedHold?.navn ?? ""}>
                                <option value={""} key={""} id={""} />
                                {holdOptions}
                            </select>
                        </div>
                        <div className={styles.submitCancel}>
                            <div className={`action-buttons mt-4 ${styles.submitButton}`}>
                                <input
                                    type="submit"
                                    className="button button-primary button-long"
                                    value="Skift hold"
                                    onClick={handleSubmit}>
                                </input>
                            </div>
                            <div className="action-buttons mt-4">
                                <button
                                    className="button button-secondary button-long"
                                    onClick={toggleHoldModal}>
                                    Annullér
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer"/>
                </div>
            </div>
        </>
    );
};

export default VejlederChangeHoldForTesttagningModal;
