import { ReactComponent as Image } from "../../assets/img/logo.svg";
import { FunctionComponent } from "react";

const Logo: FunctionComponent = () => {
    return (
        <div className="col-12 col-sm-12 col-md-3 footer-col ">
            <div className="h5 mb-4">
                <Image />
            </div>
        </div>
    );
};

export default Logo;
