import { PropsWithChildren } from "react";
import Page from "../../components/common/Page/Page";
import { Navigation } from "../../components/common/navigation/Navigation/Navigation";

const VejlederPage = (props: PropsWithChildren<any>): JSX.Element => {
    return (
        <Page>
            <Navigation />
            <div className="container page-container">{props.children}</div>
        </Page>
    );
};

export default VejlederPage;
