import {Dispatch, FunctionComponent, SetStateAction, useCallback, useEffect, useState} from "react";
import moment from "moment";
import { Paper } from "@material-ui/core";
import { localization } from "../../common/table/shared/Localization";
import { tableIcons } from "../../common/table/shared/Icons";
import MaterialTable from "@material-table/core";
import $ from "jquery";
import { ModalBackdrop } from "../../common/ModalBackdrop";
import styles from "./SupporterRuleTable.module.scss";
import {options} from "./Options";
import {RuleDetails} from "../../../shared/types/RuleDetails";
import SupporterRemoveValueModal from "../SupporterRemoveRuleModal/SupporterRemoveRuleModal";
import { RuleTypes } from "../../../shared/enums/RuleTypes";
import RuleString from "../../shared/utils/RuleString";

const SupportRuleTable: FunctionComponent<{
    rules: RuleDetails[];
    ruleType: RuleTypes;
    setForceUpdate: Dispatch<SetStateAction<number>>;
}> = ({ rules, setForceUpdate, ruleType }) => {


    const columnsDef = useCallback(() => {
        const removeRID = (rowData: RuleDetails) => {
            return (
                <>
                    <a
                        className={styles.brugerkodeLink}
                        onClick={() => {
                            $(`#modal-remove-${ruleType}-${rowData.id}`).toggle();
                            $("#modal-backdrop").toggle();
                        }}>
                        Fjern
                    </a>
                    <SupporterRemoveValueModal rule={rowData} setForceUpdate={setForceUpdate} ruleType={ruleType}/>
                </>
            );
        };

        return [
            {
                title: RuleString(ruleType),
                field: "value",
                type: "string" as const,
                cellStyle: {
                    width: '10%',
                    maxWidth: '10%'
                  },
                  headerStyle: {
                    width:'10%',
                    maxWidth: '10%'
                  },
            },
            {
                title: "Tilføjet af",
                field: "createdBy",
                type: "string" as const,
                render: (rowData: RuleDetails) => rowData?.createdBy != undefined ? rowData.createdBy : "-",
            },
            {
                title: "Oprettet d.",
                field: "createdAt",
                type: "date" as const,
                render: (rowData: any) =>
                    rowData?.createdAt != undefined
                        ? moment(new Date(rowData.createdAt)).format("DD-MM-YYYY")
                        : "-",
                customFilterAndSearch: (term: string, rowData: { createdAt: Date }) => {
                    const formated = moment(new Date(rowData.createdAt)).format("DD-MM-YYYY");
                    const formatedArr = formated.split("-");
                    return (formatedArr[0] + "-" + formatedArr[1] + "-" + formatedArr[2]).indexOf(term) != -1;
                },
                cellStyle: {
                    width: '10%',
                    maxWidth: '10%'
                  },
                  headerStyle: {
                    width:'10%',
                    maxWidth: '10%'
                  },
            },
            {
                title: "Test",
                field: "opgavesaetName",
                type: "string" as const,
            },
            {
                title: "Fjern",
                field: "internal_action",
                type: "string" as const,
                sorting: false,
                render: (rowData: any) => removeRID(rowData),
                customFilterAndSearch: (term: string ) => {
                    return "Fjern".indexOf(term) != -1;
                },
                cellStyle: {
                    width: '10%',
                    maxWidth: '10%'
                  },
                  headerStyle: {
                    width:'10%',
                    maxWidth: '10%'
                  },
            },
        ];
    }, [setForceUpdate, ruleType]);
    const [columns, setColumns] = useState(columnsDef());

    useEffect(() => {
        setColumns(columnsDef());
    }, [rules, columnsDef]);

    return (
        <div>
            <MaterialTable
                icons={tableIcons}
                title=""
                columns={columns}
                data={rules}
                options={options}
                components={{
                    Container: (props) => <Paper {...props} elevation={0} variant="outlined" />,
                }}
                localization={localization}>
            </MaterialTable>
            <ModalBackdrop />
        </div>
    );
};

export default SupportRuleTable;
