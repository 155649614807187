import { FunctionComponent, useEffect, useState } from "react";
import VejlederPage from "../../vejleder/VejlederPage";
import styles from "./SupporterManageSupporters.module.scss";
import { RuleTypes } from "../../../shared/enums/RuleTypes";
import { Roles } from "../../../shared/enums/Roles";
import {CreateRule, GetRulesByType} from "../../../apiService/RuleApi";
import { RuleDetails } from "../../../shared/types/RuleDetails";
import {toastManager} from "../../../components/common/Toast/ToastManager/ToastManager";
import updateTable from "../../../components/shared/utils/updateTable";
import SupportSupportersTable from "../../../components/supporter/SupporterSupportersTable/SupporterSupportersTable";
import { GetAuthenticatedUserByBrugernavnApi } from "../../../apiService/AuthenticatedUserApi";

const SupporterManageSupporters: FunctionComponent = () => {
    const [vejlederBrugernavn, setVejlederBrugernavn] = useState<string>("");
    const [rules, setRules] = useState<RuleDetails[]>([]);
    const [forceUpdate, setForceUpdate] = useState(0);

    const addSupporter = () => {
        GetAuthenticatedUserByBrugernavnApi(vejlederBrugernavn).then((response) => {
            if (response.data != undefined && response.succeeded) {
                CreateRule(response.data.preferredUsername, RuleTypes.PREFERRED_USERNAME, Roles.SUPPORTER).then((response) => {
                    if (response.succeeded) {
                        toastManager.success("'" + vejlederBrugernavn + "'" + " er blevet tilføjet");
                        setForceUpdate(updateTable());
                    } else {
                        toastManager.error(response.errors.toString());
                    }
                });
            } else {
                toastManager.error(`Der eksisterer ikke en vejleder med dette brugernavn.`);
            }
        });
    };

    useEffect(() => {
        GetRulesByType(RuleTypes.PREFERRED_USERNAME).then((rules) => {
            setRules(rules);
        });
    }, [forceUpdate]);

    return (
        <VejlederPage>
            <h1 className="h1">Tilføj/fjern supporter</h1>
            <label className={`form-label ${styles.label}`}>Angiv supporters ID: </label>
            <input
                type="text"
                value={vejlederBrugernavn}
                className={`form-input ${styles.input}`}
                name="supporterId"
                onChange={(e) => {
                    setVejlederBrugernavn(e.target.value);
                }}>
            </input>
            <button
                className="button button-secondary"
                onClick={addSupporter}
                disabled={vejlederBrugernavn?.toString().length < 1}>
                Tilføj
            </button>
            <h2 className="h2">Adgange til testafvikleren</h2>
            <SupportSupportersTable
                setForceUpdate={setForceUpdate}
                rules={rules}
            />
        </VejlederPage>
    );
};

export default SupporterManageSupporters;
