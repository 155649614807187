import { useKeycloak } from "@react-keycloak/web";
import { FunctionComponent, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {GetOpgavesaetByBrugerkodeApi} from "../apiService/OpgavesaetApi";
import OpgavesaetContext from "./OpgavesaetContext";
import {GetTesttagningByBrugerkode} from "../apiService/TesttagningApi";
import {genericWarning, noWarning} from "./common/Warnings";
import { SessionStorage } from "../shared/enums/Storage";

const TestSelector: FunctionComponent = () => {
    const [brugerkode, setBrugerkode] = useState("");
    const [warning, setWarning] = useState<JSX.Element>();
    const [response, setResponse] = useState<any>();
    const { opgavesaet, setOpgavesaet, setTestnavn } = useContext(OpgavesaetContext);
    const history = useHistory();
    const { keycloak } = useKeycloak();

    useEffect(() => {
        if (opgavesaet !== "" && response !== undefined && response.isAxiosError === undefined) {
            sessionStorage.clear();
            sessionStorage.setItem(SessionStorage.BRUGERKODE, brugerkode);
            if (keycloak.authenticated) {
                const logoutOptions = { redirectUri : window.location.origin + "/register" };
                keycloak.logout(logoutOptions);
            } else {
                history.push(`/register`);
            }
        }
    }, [opgavesaet, brugerkode, history, response, keycloak]);

    const handleSubmit = async (event: { preventDefault: () => void }) => {
        event.preventDefault();

        GetOpgavesaetByBrugerkodeApi(brugerkode).then((opgavesaetResponse) => {
            if (opgavesaetResponse.isAxiosError && opgavesaetResponse.response.data.Message !== undefined) {
                GetTesttagningByBrugerkode(brugerkode, true).then((testtagningResponse) => {
                    if(testtagningResponse.succeeded && testtagningResponse.data != undefined) {
                        sessionStorage.setItem(SessionStorage.BRUGERKODE, brugerkode);
                        history.push(`/resultat`);
                    } else {
                        setWarning(genericWarning(opgavesaetResponse.response.data.Message));
                        setOpgavesaet!("");
                    }
                });
            } else {
                setOpgavesaet!(opgavesaetResponse.data);
                setTestnavn!(opgavesaetResponse.data.navn);
                setWarning(noWarning);
            }
            setResponse(opgavesaetResponse);
        });
    };

    return (
        <div>
            {warning}
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label className="h2" htmlFor="kode">Indtast din kode</label>
                    <input
                        type="text"
                        className="form-input"
                        name="kode"
                        id="kode"
                        onChange={(e) => setBrugerkode(e.target.value.toUpperCase())}>
                    </input>
                </div>
                <div className="action-buttons mt-4">
                    <input
                        type="submit"
                        className="button button-primary button-long"
                        value="Åbn"
                        disabled={!brugerkode}>
                    </input>
                </div>
            </form>
        </div>
    );
};

export default TestSelector;
