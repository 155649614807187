import {Dispatch, FunctionComponent, SetStateAction, useEffect, useState} from "react";
import { UpdateArchiveHold } from "../../../apiService/HoldApi";
import $ from 'jquery';
import {HoldDetails} from "../../../shared/types/HoldDetails";
import styles from "./VejlederArchiveHoldModal.module.scss";
import {toastManager} from "../../common/Toast/ToastManager/ToastManager";
import updateTable from "../../shared/utils/updateTable";

const VejlederArchiveHoldModal: FunctionComponent<{
    hold: HoldDetails;
    archive: boolean;
    setForceUpdate: Dispatch<SetStateAction<number>>;
}> = ({hold, setForceUpdate, archive }) => {
    const [archivedText, setArchivedText] = useState<string>("");
    const [archived, setArchived] = useState<string>("");

    const toggleArchiveHoldModal = () => {
        $(`#modal-archive-hold-${hold.id}`).toggle();
        $("#modal-backdrop").toggle();
    };

    const handleSubmit = async (event: { preventDefault: () => void }) => {
        event.preventDefault();
        await UpdateArchiveHold(hold.navn, archive).then(response => {
            const isArchived = archive ? "arkiveret" : "genåbnet";
            if (response.succeeded) {
                toastManager.success("'" + hold.navn + "'" + " er blevet " + isArchived + ".");
            } else {
                toastManager.error(response.errors.toString());
            }
        });

        toggleArchiveHoldModal();
        setForceUpdate(updateTable());
    };

    useEffect(() => {
        archive ? setArchivedText("Arkivér hold") : setArchivedText("Genåbn hold");
        archive ? setArchived("arkivere") : setArchived("genåbne");
    }, [archive]);

    return (
        <>
            <div className="fds-modal" id={`modal-archive-hold-${hold.id}`} aria-hidden="true" role="dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="modal-title">{archivedText}</h2>
                    </div>
                    <div className="modal-body">
                        <span>Er du sikker på at du gerne vil {archived} &apos;{hold.navn}&apos;? <br/></span>
                        {archive && <span>Du kan godt aktivere et hold igen.</span>}
                        <div className={styles.submitCancel}>
                            <div className={`action-buttons mt-4 ${styles.submitButton}`}>
                                <input
                                    type="submit"
                                    className="button button-primary button-long"
                                    value={archive ? "Arkivér" : "Genåbn"}
                                    onClick={handleSubmit}>
                                </input>
                            </div>
                            <div className="action-buttons mt-4">
                                <button
                                    className="button button-secondary button-long"
                                    onClick={toggleArchiveHoldModal}>
                                    Annullér
                                </button>
                            </div>
                        </div>
                        <div className="modal-footer" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default VejlederArchiveHoldModal;
