import {AxiosResponse} from "axios";
import {HoldDetails} from "../shared/types/HoldDetails";
import BaseApi from "./BaseApi";
import {ResponseType} from "../shared/types/ResponseType";


export const GetHoldAsVejlederApi = async (): Promise<HoldDetails[]> => {
    return BaseApi.get<Request, AxiosResponse<any, any>>("Hold/GetByAuthenticatedUser").then((response) => {
        const hold = response.data.data as HoldDetails[];
        return hold.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    });
};

export const GetHoldBySupportsagIdApi = async (supportsagId: string): Promise<any> => {
    return await BaseApi.get<Request>("Hold/GetBySupportsagId?supportsagId=" + supportsagId);
};

export const UpdateArchiveHold = async (holdNavn: string, archived: boolean): Promise<ResponseType<null>> => {
    try {
        const response = await BaseApi.put<Request, AxiosResponse<any, any>>("Hold/UpdateArchive", { holdNavn, archived });
        return {
            data: undefined,
            succeeded: true,
            Message: response.data.message,
            errors: [],
        };
    } catch (error: any) {
        return {
            data: undefined,
            succeeded: false,
            Message: "",
            errors: [error.response.data.Message],
        };
    }
};

export const CreateHoldApi = async (navn: string): Promise<ResponseType<string>> => {
    try {
        const response = await BaseApi.post<Request, AxiosResponse<any, any>>("Hold", { navn });
        return {
            data: response.data.data,
            succeeded: true,
            Message: response.data.message,
            errors: [],
        };
    } catch (error: any) {
        return {
            data: undefined,
            succeeded: false,
            Message: "",
            errors: error.response.data.Message,
        };
    }
};
