import { Transformer } from "@ftop/megatron";
import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import Page from "../../../components/common/Page/Page";
import { useLocation, useRouteMatch } from "react-router-dom";
import $ from "jquery";
import Opgavenummer from "../../../components/Opgavenummer";
import OpgavesaetContext from "../../../components/OpgavesaetContext";
import { GetOpgavesaetByBrugerkodeApi} from "../../../apiService/OpgavesaetApi";
import { genericWarning, noWarning } from "../../../components/common/Warnings";
import {Opgavevisning, Submittype, TransformSettings} from "@ftop/megatron/dist/typeindex";
import {afsnit1Examples} from "../shared/examples/afsnit1Examples";
import {afsnit2Examples} from "../shared/examples/afsnit2Examples";
import {afsnit3Examples} from "../shared/examples/afsnit3Examples";
import {IsTest} from "../../../shared/utils/IsTest";

const apiVersion = process.env.REACT_APP_API_VERSION;

const settings: TransformSettings = {
    opgavevisning: Opgavevisning.PerOpgave,
    readonly: false,
    submittype: Submittype.Async,
    storage: "session",
    endpoints: {
        apiPrefix: "api/" + apiVersion + "/megatron",
    },
    onSubmitRedirectLink: "/resultat"
};

const settingsLaesetest: TransformSettings = {
    opgavevisning: Opgavevisning.NoWizardWithNextButton,
    readonly: false,
    submittype: Submittype.Async,
    storage: "session",
    endpoints: {
        apiPrefix: "api/" + apiVersion + "/megatron"
    },
    onSubmitRedirectLink: "/resultat",
    nextButtonText: "\u25B6",
    sendResponsesWithoutChange: true

};

const Opgavesaet: FunctionComponent = () => {
    const [brugerkode, setBrugerkode] = useState<string>("");
    const [contentComponent, setContentComponent] = useState<any>();
    const [warning, setWarning] = useState<JSX.Element>();
    const location = useLocation();
    const isComplete = document.readyState === "complete";
    const { opgavesaet, setOpgavesaet, setTestnavn } = useContext(OpgavesaetContext);
    const afsnit_01 = 1;
    const afsnit_02 = 3;
    const afsnit_03 = 5;

    const GetBrugerkode = () => {
        const match: any = useRouteMatch("/test/:brugerkode")?.params;
        if (!brugerkode && match && match.brugerkode) {
            setBrugerkode(match.brugerkode);
        }
    };

    GetBrugerkode();

    useEffect(() => {
        const AddEmptyOpgave = () => {
            for (const opgave of opgavesaet.afsnit[afsnit_01].opgaver) {
                if (opgave.opgaver.length == 1) {
                    opgave.opgaver.push({
                        svarmuligheder: ["tomOpgave", "tomOpgave", "tomOpgave", "tomOpgave", "tomOpgave"],
                        type: 7
                    });
                }
            }
        };

        const SetDisableButton = () => {
            if (!IsTest.isLaesetest(opgavesaet.navn)) {
                return;
            }

            for (const opgave of opgavesaet.afsnit[afsnit_02].opgaver) {
                for (const delOpgave of opgave.opgaver) {
                    delOpgave.initiallyDisabledMs = 1000;
                }
            }

            if (opgavesaet.afsnit[afsnit_03] != null) {
                for (const opgave of opgavesaet.afsnit[afsnit_03].opgaver) {
                    for (const delOpgave of opgave.opgaver) {
                        delOpgave.initiallyDisabledMs = 1000;
                    }
                }
            }
        };

        const SetGridAndAudioTag = () => {
            for (const opgave of opgavesaet.afsnit[afsnit_02].opgaver) {
                for (const delopgave of opgave.opgaver) {
                    delopgave.grid = [2, 2];
                    const audio = delopgave.audio;
                    delopgave.audio = {source: [{src: "/api/v1/Sound?name=" + audio, type: "audio/mpeg"}], autoplay: true};
                }
            }


            if (opgavesaet.afsnit[afsnit_03] != null) {
                for (const opgave of opgavesaet.afsnit[afsnit_03].opgaver) {
                    for (const delopgave of opgave.opgaver) {
                        delopgave.grid = [4, 1];
                    }
                }
            }
        };

        const RemoveNullTimeInSec = () => {
            for (const afsnit of opgavesaet.afsnit) {
                if (afsnit.timeInSec === null) {
                    delete afsnit.timeInSec;
                }

                for (const opgave of afsnit.opgaver) {
                    if (opgave.timeInSec === null) {
                        delete opgave.timeInSec;
                    }
                }
            }
        };

        if (!contentComponent) {
            GetOpgavesaetByBrugerkodeApi(brugerkode).then((opgavesaetResponse) => {
                if (opgavesaetResponse.isAxiosError && opgavesaetResponse.response.data.Message !== undefined) {
                    setWarning(genericWarning(opgavesaetResponse.response.data.Message));
                    setOpgavesaet!("");
                } else {
                    setOpgavesaet!(opgavesaetResponse.data);
                    setTestnavn!(opgavesaetResponse.data.navn);
                    setWarning(noWarning);
                }
            });

            if (opgavesaet !== "" && IsTest.isLaesetest(opgavesaet.navn)) {
                SetGridAndAudioTag();
                AddEmptyOpgave();
                RemoveNullTimeInSec();
                SetDisableButton();
                setContentComponent(new Transformer().transformString(JSON.stringify(opgavesaet), settingsLaesetest));
            } else if (opgavesaet !== "") {
                RemoveNullTimeInSec();
                setContentComponent(new Transformer().transformString(JSON.stringify(opgavesaet), settings));
            }
        }

        $(".megatron-opgave-tekst").appendTo(".megatron-ot-optilenudafxtekst");
        $(".megatron-ot-optilenudafxtekst").not(".megatron-ot-optilenudafxtekst:last").remove();
        $(".mb-3 label img").parent().parent().addClass("svarmulighed-image");
        $('h1:contains("Afslut")').parent().parent().parent().siblings(".megatron-sidemenu").remove();
        const lastIndex = ($('[role="tablist"]').children().length - 2).toString();
        const currentIndex = $('[role="tablist"]').find('[aria-selected="true"]').attr("id")?.substring(3);

        if ($( ".sidenav-list li" ).last().hasClass("active")) {
            $('button:contains("Næste")').html("Gå til Del " + (parseInt(currentIndex!) + 2) .toString());
        }

        if (lastIndex === currentIndex) {
            $('button:contains("Gå til Del")').html("Afslut");
        }

        if (IsTest.isLaesetest(opgavesaet.navn)) {
            if (contentComponent != null) {
                if ($(".megatron-pagecontent span strong").text() === "Eksempel_01") {
                    afsnit1Examples.start();
                } else if ($(".megatron-pagecontent span strong").text() === "Eksempel_02") {
                    afsnit2Examples.start();
                } else if ($(".megatron-pagecontent span strong").text() === "Eksempel_03") {
                    afsnit3Examples.start();
                }
            }
        }
    }, [brugerkode, location, isComplete, contentComponent, opgavesaet, setOpgavesaet, setTestnavn]);

    return (
        <Page>
            <div className="container page-container">
                {warning}
                {contentComponent}
                <Opgavenummer opgavesaetNavn={opgavesaet.navn} />
            </div>
        </Page>
    );
};

export default Opgavesaet;
