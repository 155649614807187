import { FC } from "react";
import {IDisplayProps} from "../../shared/interfaces/IDisplayProps";

type Props = IDisplayProps;

const ToggleArchiveHoldButton: FC<Props> = (props) => {
    const { display, setDisplay } = props as IDisplayProps;
    return (
        <div className="form-group">
            <input id="toggle-example"
                   type="checkbox"
                   name="toggle-example"
                   defaultChecked={display}
                   className="form-toggle"
                   onClick={() => {
                       setDisplay(!display);
                   }}/>
            <label htmlFor="toggle-example" className="form-toggle-label">
                Vis arkiverede hold
            </label>
        </div>
    );
};

export default ToggleArchiveHoldButton;
