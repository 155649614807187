import moment from "moment";
import {FC} from "react";
import {IBrugerkodeProps} from "../../../shared/interfaces/IBrugerkodeProps";
import {IDisplayProps} from "../ToggleResultDisplay";
import {IResultProps} from "../../../shared/interfaces/IResultProps";
import styles from "./Summary.module.scss";
import {IElevProps} from "../../../shared/interfaces/IElevProps";
import {LanguageOptions} from "../../../shared/enums/LanguageOptions";
import TestResultInfo from "../TestResultInfo/TestResultInfo";
import {ITestApprovalProps} from "../../../shared/interfaces/ITestApprovalProps";
import {ITesttagningProps} from "../../../shared/interfaces/ITesttagningProps";
import {IsTest} from "../../../shared/utils/IsTest";

type Props = IDisplayProps | IBrugerkodeProps | IResultProps | IElevProps | ITestApprovalProps | ITesttagningProps;

const Summary: FC<Props> = (props) => {
    const { display } = props as IDisplayProps;
    const { brugerkode } = props as IBrugerkodeProps;
    const { result } = props as IResultProps;
    const { elev } = props as IElevProps;
    const { setTekstlaesning, tekstlaesning, setOrdforraad, ordforraad, setOrdlaesning, ordlaesning } = props as ITestApprovalProps;
    const { testtagning } = props as ITesttagningProps;

    const foedselsdag: string =
        result != undefined && result?.foedselsdag != undefined
            ? moment(new Date(result.foedselsdag)).format("DD-MM-YYYY")
            : "";
    const testDate: string =
        result != undefined && result?.sluttidspunkt != undefined
            ? moment(new Date(result.sluttidspunkt)).format("DD-MM-YYYY")
            : "";
    const resultDate: string =
        result != undefined && result?.resultDate != undefined
            ? moment(new Date(result.resultDate)).format("DD-MM-YYYY")
            : "";

    const setVLVBedsteSprog = (): string => {
        if (elev.bedsteSprog === LanguageOptions.DANSK) {
            return "Dansk";
        } else if (elev.bedsteSprog === LanguageOptions.IKKE_DANSK) {
            return "Et andet sprog end dansk";
        } else if (elev.bedsteSprog === LanguageOptions.DANSK_OG_ET_ANDET_SPROG) {
            return "Både dansk og et andet sprog";
        }

        return "";
    };

    const setFGULBedsteSprog = (): string | undefined => {
        if (elev.bedsteSprog === LanguageOptions.DANSK) {
            return "Dansk";
        } else if (elev.bedsteSprog === LanguageOptions.IKKE_DANSK) {
            return elev.sprog;
        }

        return "";
    };

    if (!display) {
        return <></>;
    } else {
        return (
            <div>
                <h2>{result?.testNavn}</h2>
                <table className={`${styles.table} table table--borderless table--compact table--responsive-headers d-print-table mb-7 w-percent-lg-70`}>
                    <tbody>
                    <tr>
                        <th className="w-percent-md-70">Navn:</th>
                        <td>{result?.testtagerNavn}</td>
                    </tr>
                    <tr>
                        <th>Fødselsdato:</th>
                        <td>{foedselsdag}</td>
                    </tr>
                    <tr>
                        <th>Brugerkode:</th>
                        <td>{brugerkode}</td>
                    </tr>
                    <tr>
                        <th>Dato for test:</th>
                        <td>{testDate}</td>
                    </tr>
                    <tr>
                        <th>Dato for udskrift:</th>
                        <td>{resultDate}</td>
                    </tr>
                    { IsTest.isVLV(result?.testNavn) &&
                        <tr>
                            <th>Testtagers bedste sprog:</th>
                            <td>{setVLVBedsteSprog()}</td>
                        </tr>
                    }
                    { IsTest.isFGUL(result?.testNavn) &&
                        <tr>
                            <th>Testtagers bedste sprog:</th>
                            <td>{setFGULBedsteSprog()}</td>
                        </tr>
                    }
                    </tbody>
                </table>
                { IsTest.isFGUL(result?.testNavn) &&
                    <TestResultInfo
                    setTekstlaesning={setTekstlaesning}
                    tekstlaesning={tekstlaesning}
                    setOrdforraad={setOrdforraad}
                    ordforraad={ordforraad}
                    setOrdlaesning={setOrdlaesning}
                    ordlaesning={ordlaesning}
                    testtagning={testtagning}
                    elev={elev}/>
                }
            </div>
        );
    }
};

export default Summary;
