import { FunctionComponent, useState } from "react";
import {GetHovedopgaveByIdApi} from "../apiService/HovedopgaveApi";
import { useLocation } from "react-router-dom";
import React from "react";
import {IsTest} from "../shared/utils/IsTest";

const Opgavenummer: FunctionComponent<{
    opgavesaetNavn: string;
}> = ({opgavesaetNavn}) => {
    const location = useLocation();
    const isComplete = document.readyState === "complete";

    const [opgavenummer, setOpgavenummer] = useState<any>("");

    React.useEffect(() => {
        const multId = document.querySelector(".megatron-opgave-svarmuligheder")
            ? document.querySelector(".megatron-opgave-svarmuligheder")?.id
            : "";
        const dropdownId = document.querySelector(".form-select") ? document.querySelector(".form-select")?.id : "";

        const getOpgavenummer = async (hovedopgaveId: any) => {
            const response: any = await GetHovedopgaveByIdApi(hovedopgaveId);
            setOpgavenummer(response);
        };

        if (IsTest.isLaesetest(opgavesaetNavn)) {
            setOpgavenummer("");
        } else if (multId) {
            getOpgavenummer(multId);
        } else if (dropdownId) {
            getOpgavenummer(dropdownId);
        } else {
            setOpgavenummer("");
        }
    }, [location, isComplete, opgavesaetNavn]);

    const content: JSX.Element = <div className="opgavenummer">Opgavenummer: {opgavenummer} </div>;

    return opgavenummer ? content : <div/>;
};

export default Opgavenummer;
