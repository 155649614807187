import { FunctionComponent, useContext, useEffect, useState } from "react";
import OpgavesaetContext from "../OpgavesaetContext";

const Logo: FunctionComponent = () => {
    const { testnavn } = useContext(OpgavesaetContext);
    const [tekst, setTekst] = useState("Testafvikleren");

    useEffect(() => {
        if (testnavn != undefined) {
            setTekst(testnavn);
        } else {
            setTekst("Testafvikleren");
        }
    }, [testnavn]);

    return <>{tekst}</>;
};

export default Logo;
