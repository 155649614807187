import { FunctionComponent } from "react";
import Page from "../../components/common/Page/Page";

const Cookiepolitik: FunctionComponent = () => {
    return (
        <Page>
            <div className="container page-container">
                <div>
                    <h2>Cookies og indsamling af oplysninger på Testafvikleren.dk</h2>
                    <h3>Generelt om cookies</h3>
                    <p>En cookie er en fil, som lægges på din enhed (computer, smartphone, tablet eller lignende). 
                        Den gør det muligt at genkende din enhed og samle information om, hvilke sider og funktioner, du besøger via din browser. 
                        En cookie kan ikke se, hvem du er, hvad du hedder, hvor du bor, eller om enheden bruges af en eller flere personer. 
                        Den kan heller ikke sprede virus eller skadelige programmer.</p>
                    <h3>Cookies på Testafvikleren.dk</h3>
                    <p>På Testafvikleren.dk&nbsp;benytter vi udelukkende tekniske cookies til at holde styr på, hvem du som bruger er, og hvilke roller og rettigheder, du som bruger har med dit login. 
                        Da disse cookies er forbeholdt den enkelte session, er ingen af disse oplysninger personhenførbare, og de gemmes heller ikke.</p>
                </div>
            </div>
        </Page >
    );
};

export default Cookiepolitik;