import React, { Dispatch, FunctionComponent, SetStateAction, useCallback, useEffect, useState } from "react";
import { TesttagningDetails } from "../../../../shared/types/TesttagningDetails";
import moment from "moment";
import { Paper } from "@material-ui/core";
import { localization } from "../shared/Localization";
import { tableIcons } from "../shared/Icons";
import MaterialTable from "@material-table/core";
import { useHistory } from "react-router-dom";
import VejlederChangeHoldForTesttagningModal from "../../../vejleder/VejlederChangeHoldForTesttagningModal/VejlederChangeHoldForTesttagningModal";
import $ from "jquery";
import { ModalBackdrop } from "../../ModalBackdrop";
import styles from "./TestResultTable.module.scss";
import ToggleArchiveHoldButton from "../../ToggleArchiveHoldButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { toastManager } from "../../Toast/ToastManager/ToastManager";
import { CreateResultsAsPdfZippedApi } from "../../../../apiService/ResultApi";
import { options } from "./Options";
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import createDownloadLink from "../../../../shared/utils/createDownloadLink";
import { HoldDetails } from "../../../../shared/types/HoldDetails";
import { GetHoldAsVejlederApi } from "../../../../apiService/HoldApi";
import { useSessionStorage } from "../shared/utils/useSessionStorage";
import { SessionStorage } from "../../../../shared/enums/Storage";

const TestResultTable: FunctionComponent<{
    testtagninger: TesttagningDetails[];
    tableInfo: JSX.Element;
    setForceUpdate: Dispatch<SetStateAction<number>>;
    mutable: boolean;
}> = ({ testtagninger, tableInfo, setForceUpdate, mutable }) => {
    const [filteredTesttagninger, setFilteredTesttagninger] = useState<TesttagningDetails[]>([]);
    const [selectedRows, setSelectedRows] = useState<TesttagningDetails[]>([]);
    const [hold, setHold] = useState<HoldDetails[]>([]);
    const [showArchived, setShowArchived] = useSessionStorage(SessionStorage.SHOW_ARCHIVED, false);

    const history = useHistory();
    const columnsDef = useCallback(() => {
        const renderNavn = (rowData: TesttagningDetails) => {
            if (rowData.sluttidspunkt == undefined) {
                return <span>{rowData.elev.fullName}</span>;
            }
            const link = `/vejleder/elev/resultat?brugerkode=${rowData.elev.brugerkode}`;
            return (
                <a className={styles.brugerkodeLink} onClick={() => history.push(link)}>
                    {rowData.elev.fullName}
                </a>
            );
        };

        const renderHold = (rowData: TesttagningDetails) => {
            if (mutable) {
                return (
                    <>
                        <span>{rowData?.elev?.hold?.navn != undefined ? rowData.elev.hold.navn : "-"}</span>
                        <a
                            className={styles.brugerkodeLink}
                            onClick={() => {
                                $(`#modal-hold-${rowData.elev.id}`).toggle();
                                $("#modal-backdrop").toggle();
                            }}>
                            <FontAwesomeIcon className={`${styles.holdIcon}`} icon={faEdit} />
                        </a>
                        <VejlederChangeHoldForTesttagningModal elev={rowData.elev} setForceUpdate={setForceUpdate} hold={hold} />
                    </>
                );
            } else {
                return <span>{rowData?.elev?.hold?.navn != undefined ? rowData.elev.hold.navn : "-"}</span>;
            }
        };

        return [
            {
                title: "Navn",
                field: "elev.fullName",
                width: "25%",
                type: "string" as const,
                render: (rowData: TesttagningDetails) => renderNavn(rowData),
            },
            {
                title: "Gennemført d.",
                field: "sluttidspunkt",
                type: "date" as const,
                render: (rowData: any) =>
                    rowData?.sluttidspunkt != undefined
                        ? moment(new Date(rowData.sluttidspunkt)).format("DD-MM-YYYY")
                        : "Ikke afsluttet",
                customFilterAndSearch: (term: string, rowData: { sluttidspunkt: Date }) => {
                    const formated = moment(new Date(rowData.sluttidspunkt)).format("DD-MM-YYYY");
                    const formatedArr = formated.split("-");
                    return (formatedArr[0] + "-" + formatedArr[1] + "-" + formatedArr[2]).indexOf(term) != -1;
                },
            },
            {
                title: "Test",
                field: "opgavesaet.navn",
                type: "string" as const,
            },
            {
                title: "Brugerkode",
                field: "elev.brugerkode",
                type: "string" as const,
            },
            {
                title: "Hold",
                field: "elev.hold.navn",
                type: "string" as const,
                render: (rowData: any) => renderHold(rowData),
            }
        ];
    }, [history, mutable, setForceUpdate, hold]);
    const [columns, setColumns] = useState(columnsDef());

    const downloadTestResultsAsZip = (): void => {
        toastManager.info("Vent et øjeblik imens testresultaterne bliver genereret.");
        const unfinishedRows = selectedRows.filter(x => x.sluttidspunkt == undefined);
        if (unfinishedRows.length >= 1) {
            toastManager.error("Du kan ikke downloade resultater for prøver der ikke er afsluttet.");
        }
        const finishedRows = selectedRows.filter(x => x.sluttidspunkt != undefined);
        if (finishedRows.length >= 1) {
            CreateResultsAsPdfZippedApi(finishedRows.map(x => x.elev.brugerkode)).then((response) => {
                const byteArray = response.data;
                if (byteArray != undefined) {
                    createDownloadLink(byteArray, "zip", "testresultater");
                    toastManager.success("Testresultater er downloadet.");
                } else {
                    toastManager.error("Der skete en fejl. Prøv igen.");
                }
            });
        }
    };

    useEffect(() => {
        if (!showArchived) {
            setFilteredTesttagninger(testtagninger.filter((testtagning) => {
                return !testtagning?.elev?.hold?.archived;
            }));
        } else {
            setFilteredTesttagninger(testtagninger);
        }
        setColumns(columnsDef());
    }, [testtagninger, columnsDef, showArchived, hold]);

    useEffect(() => {
        let isMounted = true;
        GetHoldAsVejlederApi().then((hold) => {
            if (isMounted) {
                setHold(hold.filter(function (hold) {
                    return !hold?.archived;
                }));
            }
        });
        return () => {
            isMounted = false;
        };
    }, []);

    return (
        <div>
            <div className={styles.helperTextButton}>
                <span className={styles.helperText}>{tableInfo}</span>
                <div className={styles.toggleArchiveButton}>
                    <div>
                        <ToggleArchiveHoldButton display={showArchived} setDisplay={setShowArchived} />
                    </div>
                </div>
            </div>
            <MaterialTable
                icons={tableIcons}
                title=""
                columns={columns}
                data={filteredTesttagninger}
                options={options}
                onSelectionChange={(rows: TesttagningDetails[]) => {
                    setSelectedRows(rows);
                }}
                components={{
                    Container: (props) => <Paper {...props} elevation={0} variant="outlined" />,
                }}
                localization={localization}>
            </MaterialTable>
            {mutable ? <ModalBackdrop /> : <></>}

            <button
                className={`button button-secondary ${styles.button}`}
                onClick={() => downloadTestResultsAsZip()}
                disabled={selectedRows.length < 1}>
                Download testresultater
            </button>
            <FontAwesomeIcon className={`${styles.iconQuestionMark}`} size="sm" icon={faQuestionCircle} />
            <span className={`${styles.buttonHelperText}`}>Hent resultaterne for de valgte testdeltagere. Vær opmærksom på, at du gemmer personfølsomt data på din computer.</span>
        </div>
    );
};

export default TestResultTable;
