import { FunctionComponent } from "react";
import Page from "../../components/common/Page/Page";
import TestSelector from "../../components/TestSelector";
import styles from "./Frontpage.module.scss";
import Image from "../../assets/img/frontpage_math.jpg";

const Frontpage: FunctionComponent = () => {
    return (
        <Page>
            <div className={`container page-container`}>
                <div className={`${styles.container}`}>
                    <div className={`${styles.inputContainer}`}>
                        <TestSelector />
                        <div className={`${styles.textContainer}`}>
                            <span className={`${styles.infoText}`}>Tal med din vejleder, hvis du ikke har fået</span>
                            <span className={`${styles.infoText}`}>udleveret en kode.</span>
                        </div>
                    </div>
                    <div className={`${styles.rightDiv}`}>
                        <img src={Image} alt="Forside billede." className={`${styles.image}`} />
                    </div>
                </div>
            </div>
        </Page>
    );
};

export default Frontpage;
