import { ElevDetails } from "./ElevDetails";
import { OpgavesaetDetails } from "./OpgavesaetDetails";

export type TesttagningDetails = {
    starttidspunkt: Date;
    sluttidspunkt: Date;
    procentKorrekteOpgaver: string;
    elev: ElevDetails;
    opgavesaet: OpgavesaetDetails;
    createdAt: Date;
    antalForkerteOpgaver: number;
    antalIkkeBesvaredeOpgaver: number;
    antalKorrekteOpgaver: number;
    antalOversprungneOpgaver: number;
    formattedResult: string;
    del1Starttidspunkt: Date;
    del1Sluttidspunkt: Date;
    del2Starttidspunkt: Date;
    del2Sluttidspunkt: Date;
    del3Starttidspunkt: Date;
    del3Sluttidspunkt: Date;
    ordforraad: boolean;
    ordlaesning: boolean;
    tekstlaesning: boolean;

};

export const formattedResult = (item: TesttagningDetails): string => {
    const total = item.antalForkerteOpgaver + item.antalIkkeBesvaredeOpgaver + item.antalKorrekteOpgaver + item.antalOversprungneOpgaver;
    if (total == 0) {
        return "-";
    } else {
        return `${item.antalKorrekteOpgaver} af ${total}`;
    }
};
