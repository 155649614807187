import { FunctionComponent, useEffect, useState } from "react";
import VejlederBrugerkoderTable from "../../components/vejleder/VejlederBrugerkoderTable/VejlederBrugerkoderTable";
import VejlederPage from "./VejlederPage";
import VejlederCreateBrugerkoderModal from "../../components/vejleder/VejlederCreateBrugerkoderModal/VejlederCreateBrugerkoderModal";
import { GetTesttagningerAsAuthenticatedUserApi } from "../../apiService/TesttagningApi";
import { TesttagningDetails } from "../../shared/types/TesttagningDetails";

const VejlederBrugerkoder: FunctionComponent = () => {
    const [forceUpdate, setForceUpdate] = useState<number>(0);
    const [testtagning, setTesttagning] = useState<TesttagningDetails[]>([]);

    useEffect(() => {
        GetTesttagningerAsAuthenticatedUserApi(false).then((response) => {
            const testtagninger = response.data.data as TesttagningDetails[];
            setTesttagning(testtagninger);
        });
    }, [forceUpdate]);

    return (
        <VejlederPage>
            <h1 className="h1">Brugerkoder</h1>
            <p>Opret brugerkoder til at give adgang til en ny test. En brugerkode er unik og giver adgang til én test pr. person.
                Når brugerkoderne er oprettet, fremgår de i listen nedenfor. Ubrugte brugerkoder slettes efter 30 dage.
            </p>
            <VejlederCreateBrugerkoderModal forceUpdate={forceUpdate} setForceUpdate={setForceUpdate} />
            <h2 className="h2">Eksisterende brugerkoder</h2>
            <p>Brugerkoderne i listen nedenfor er ubrugte og kan distribueres. Når en test påbegyndes, fjernes koden fra oversigten.
                Igangværende og afsluttede test kan ses under {"'Resultater'"}.
            </p>
            <VejlederBrugerkoderTable testtagninger={testtagning} />
        </VejlederPage>
    );
};

export default VejlederBrugerkoder;
