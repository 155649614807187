import { AxiosResponse } from "axios";
import { Opgavesaet } from "../shared/types/Opgavesaet";
import BaseApi from "./BaseApi";
import {ResponseType} from "../shared/types/ResponseType";

export const GetOpgavesaetByBrugerkodeApi = async (brugerkode: string): Promise<any> => {
    try {
        const response = await BaseApi.get<Request>("Opgavesaet/GetByBrugerkode?brugerkode=" + brugerkode);
        return response.data;
    } catch (err) {
        return err;
    }
};

export const getAllVisibleOpgavesaetApi = async (): Promise<Opgavesaet[] | any> => {
    try {
        const response = await BaseApi.get<any>("Opgavesaet/GetAllVisible");
        return response.data.data;
    } catch (err) {
        return err;
    }
};

export const GetOpgavesaetByRule = async ():  Promise<ResponseType<Opgavesaet[]>> => {
    try {
        const response = await BaseApi.get<Request, AxiosResponse<any, any>>("Opgavesaet/GetByRule");
        return {
            data: response.data.data as Opgavesaet[],
            succeeded: true,
            Message: response.data.message,
            errors: [],
        };
    } catch (error: any) {
        return {
            data: undefined,
            succeeded: false,
            Message: "",
            errors: [error.response.data.Message],
        };
    }
};


