import {Dispatch, FunctionComponent, SetStateAction, useCallback, useEffect, useState} from "react";
import { HoldDetails } from "../../../shared/types/HoldDetails";
import moment from "moment";
import MaterialTable from "@material-table/core";
import { tableIcons } from "../../common/table/shared/Icons";
import { Paper } from "@material-ui/core";
import { localization } from "../../common/table/shared/Localization";
import $ from "jquery";
import VejlederArchiveHoldModal from "../VejlederArchiveHoldModal/VejlederArchiveHoldModal";
import styles from "./VejlederHoldTable.module.scss";
import ToggleArchiveHoldButton from "../../common/ToggleArchiveHoldButton";
import { options } from "./Options";
import { useSessionStorage } from "../../common/table/shared/utils/useSessionStorage";
import { SessionStorage } from "../../../shared/enums/Storage";

const VejlederHoldTable: FunctionComponent<{
    forceUpdate: number,
    setForceUpdate: Dispatch<SetStateAction<number>>,
    hold: HoldDetails[]
}> = ({ forceUpdate, setForceUpdate, hold }) => {
    const [filteredHold, setFilteredHold] = useState<HoldDetails[]>([]);
    const [showArchived, setShowArchived ] = useSessionStorage(SessionStorage.SHOW_ARCHIVED, false);
    const columnsDef = useCallback(() => {
        const renderArchiveButton = (rowData: HoldDetails) => {
            const buttonValue = rowData.archived ? "Genåbn hold" : "Arkivér hold";
            return (
                <>
                    <a
                        className={styles.brugerkodeLink}
                        onClick={() => {
                            $(`#modal-archive-hold-${rowData.id}`).toggle();
                            $("#modal-backdrop").toggle();
                        }}>
                        {buttonValue}
                    </a>
                    <VejlederArchiveHoldModal archive={!rowData.archived} hold={rowData} setForceUpdate={setForceUpdate} />
                </>
            );
        };

        return [
            {
                title: "Navn",
                field: "navn",
                type: "string" as const,
            },
            {
                title: "Oprettet d.",
                field: "createdAt",
                type: "date" as const,
                render: (rowData: any) =>
                    rowData?.createdAt != undefined ? moment(new Date(rowData.createdAt)).format("DD-MM-YYYY") : "-",
                customFilterAndSearch: (term: string, rowData: { createdAt: Date }) => {
                    const formated = moment(new Date(rowData.createdAt)).format("DD-MM-YYYY");
                    const formatedArr = formated.split("-");
                    return (formatedArr[0] + "-" + formatedArr[1] + "-" + formatedArr[2]).indexOf(term) != -1;
                },
            },
            {
                title: "Arkivér/genåbn hold",
                field: "internal_action",
                type: "string" as const,
                render: (rowData: any) => renderArchiveButton(rowData),
                customFilterAndSearch: ( term: string, rowData: HoldDetails ) => {
                    const formated = rowData.archived ? "Genåbn hold" : "Arkivér hold";
                    return (formated).indexOf(term) != -1;
                },
                customSort: (a: HoldDetails, b: HoldDetails) => holdStatusSort(a, b)
            }
        ];
    }, [setForceUpdate]);
    const [columns, setColumns] = useState(columnsDef());

    useEffect(() => {
        if (!showArchived) {
            setFilteredHold(hold.filter((hold) => {
                return !hold?.archived;
            }));
        } else {
            setFilteredHold(hold);
        }
        setColumns(columnsDef());
    }, [forceUpdate, showArchived, columnsDef, setForceUpdate, hold]);

    const holdStatusSort = (a: HoldDetails, b: HoldDetails) => {
        if (a.archived == b.archived) {
            return 0;
        } else if (a.archived) {
            return 1;
        } else {
            return -1;
        }
    };

    return (
        <>
            <div className={styles.container}>
                <span className={`h2 ${styles.header}`}>Eksisterende hold</span>
                <div className={styles.toggleArchiveButton}>
                    <div>
                        <ToggleArchiveHoldButton display={showArchived} setDisplay={setShowArchived} />
                    </div>
                </div>
            </div>
            <div>
                <MaterialTable
                    icons={tableIcons}
                    title=""
                    columns={columns}
                    data={filteredHold}
                    options={options}
                    components={{
                        Container: (props) => <Paper {...props} elevation={0} variant="outlined" />,
                    }}
                    localization={localization}>
                </MaterialTable>
            </div>
        </>
    );
};

export default VejlederHoldTable;
