import { Roles } from "../../shared/enums/Roles";
import { SessionStorage } from "../../shared/enums/Storage";

export class AuthorizationService {
    public clearSessionStorage(): void {
        this.deleteTokenInSessionStorage();
        this.deleteRefreshTokenInSessionStorage();
        this.deleteRoleInSessionStorage();
        this.deleteSupportsagInSessionStorage();
        console.log("Session storage cleared.");
    }

    public setRoleInSessionStorage(role: string): void {
        sessionStorage.setItem(SessionStorage.ROLE, role);
    }

    public setKeycloakTokenInSessionStorage(token: string): void {
        sessionStorage.setItem(SessionStorage.KC_TOKEN, token);
    }

    public setKeycloakRefreshTokenInSessionStorage(token: string): void {
        sessionStorage.setItem(SessionStorage.KC_REFRESH_TOKEN, token);
    }

    public deleteRoleInSessionStorage(): void {
        sessionStorage.removeItem(SessionStorage.ROLE);
    }

    public deleteTokenInSessionStorage(): void {
        sessionStorage.removeItem(SessionStorage.KC_TOKEN);
    }

    public deleteRefreshTokenInSessionStorage(): void {
        sessionStorage.removeItem(SessionStorage.KC_REFRESH_TOKEN);
    }

    public deleteSupportsagInSessionStorage(): void {
        sessionStorage.removeItem(SessionStorage.SUPPORTSAG);
    }

    public getCurrentRole(): string | null {
        const userStr = sessionStorage.getItem(SessionStorage.ROLE);

        return (userStr ? userStr : null);
    }

    public isVejleder(): boolean {
        return Roles.VEJLEDER === this.getCurrentRole();
    }

    public isSupporter(): boolean {
        return Roles.SUPPORTER === this.getCurrentRole();
    }

    public inNone(): boolean {
        const userStr = sessionStorage.getItem(SessionStorage.ROLE);
        if (userStr === "none") {
            return true;
        }
        return false;
    }

    public isUserAuthorized(role = ""): boolean {
        switch (role) {
            case (""): {
                return this.getCurrentRole() != null;
            }
            default: {
                return role === this.getCurrentRole();
            }
        }
    }
}

export const authorizationService = new AuthorizationService();
