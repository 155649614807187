import { FunctionComponent, useEffect, useState } from "react";
import { TesttagningDetails } from "../../../shared/types/TesttagningDetails";
import { Paper } from "@material-ui/core";
import { localization } from "../../common/table/shared/Localization";
import { tableIcons } from "../../common/table/shared/Icons";
import moment from "moment";
import MaterialTable from "@material-table/core";
import { options } from "./Options";
import styles from "./VejlederBrugerkoderTable.module.scss";
import { toastManager } from "../../common/Toast/ToastManager/ToastManager";
import saveBrugerkoderToFile, { BrugerkodePdf } from "./SaveBrugerkoderToFile";

const VejlederBrugerkoderTable: FunctionComponent<{
    testtagninger: TesttagningDetails[]
}> = ({ testtagninger }) => {
    const [testtagning, setTesttagning] = useState<TesttagningDetails[]>([]);
    const [selectedRows, setSelectedRows] = useState<TesttagningDetails[]>([]);
    const [columns] = useState([
        {
            title: "Brugerkode",
            field: "elev.brugerkode",
            type: "string" as const,
            cellStyle: {
                width: '25%',
                maxWidth: '25%'
            },
            headerStyle: {
                width: '25%',
                maxWidth: '25%'
            }
        },
        {
            title: "Opgavesæt",
            field: "opgavesaet.navn",
            type: "string" as const,
        },
        {
            title: "Hold",
            field: "elev.hold.navn",
            type: "string" as const,
            render: (rowData: any) => (rowData?.elev?.hold?.navn != undefined ? rowData.elev.hold.navn : "-"),
        },
        {
            title: "Oprettet d.",
            field: "createdAt",
            type: "date" as const,
            render: (rowData: any) =>
                rowData?.createdAt != undefined ? moment(new Date(rowData.createdAt)).format("DD-MM-YYYY") : "-",
            customFilterAndSearch: (term: string, rowData: { createdAt: Date }) => {
                const formated = moment(new Date(rowData.createdAt)).format("DD-MM-YYYY");
                const formatedArr = formated.split("-");
                return (formatedArr[0] + "-" + formatedArr[1] + "-" + formatedArr[2]).indexOf(term) != -1;
            },
            cellStyle: {
                width: '25%',
                maxWidth: '25%'
            },
            headerStyle: {
                width: '25%',
                maxWidth: '25%'
            }
        },
    ]);

    useEffect(() => {
        const sorted = testtagninger.sort(
            (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
        setTesttagning(sorted);

    }, [testtagninger]);

    const downloadBrugerkoderAsPdf = () => {
        const brugerkoder: BrugerkodePdf[] = [];
        selectedRows.map((row) => {
            brugerkoder.push({brugerkode: row.elev.brugerkode, testName:row.opgavesaet.navn});
        });
        if (brugerkoder != undefined) {
            saveBrugerkoderToFile(brugerkoder);
        } else {
            toastManager.error('Der skete en fejl. Prøv igen.');
        }
    };

    return (
        <>
            <MaterialTable
                icons={tableIcons}
                title=""
                columns={columns}
                data={testtagning}
                options={options}
                onSelectionChange={(rows: TesttagningDetails[]) => {
                    setSelectedRows(rows);
                }}
                components={{
                    Container: (props) => <Paper {...props} elevation={0} variant="outlined" />,
                }}
                localization={localization}>
            </MaterialTable>

            <button
                className={`button button-secondary ${styles.button}`}
                onClick={() => downloadBrugerkoderAsPdf()}
                disabled={selectedRows.length < 1}>
                Udskriv valgte brugerkoder
            </button>
        </>
    );
};

export default VejlederBrugerkoderTable;
