import { createContext, FC, useState } from "react";

export enum Testnavn {
    FVU = "FVU",
    FGU = "FGU",
}

interface IOpgavesaetContext {
    opgavesaet: any;
    setOpgavesaet?: (value: string) => void;
    testnavn?: Testnavn;
    setTestnavn?: (value: Testnavn) => void;
}

const defaultOpgavesaet: IOpgavesaetContext = {
    opgavesaet: "",
};

const OpgavesaetContext = createContext<IOpgavesaetContext>(defaultOpgavesaet);

export default OpgavesaetContext;

export const OpgavesaetProvider: FC = ({ children }) => {
    const [opgavesaet, setOpgavesaet] = useState(defaultOpgavesaet.opgavesaet);
    const [testnavn, setTestnavn] = useState(defaultOpgavesaet.testnavn);

    return (
        <OpgavesaetContext.Provider
            value={{
                opgavesaet: opgavesaet,
                setOpgavesaet: setOpgavesaet,
                testnavn: testnavn,
                setTestnavn: setTestnavn,
            }}>
            {children}
        </OpgavesaetContext.Provider>
    );
};
