import { Helpers } from "./helpers";
import $ from "jquery";

export class Afsnit2Examples {
    private audio = new Audio("/api/v1/Sound?name=VLV_Del_02_Eksempel_01");
    private timeout = 500;

    public start(): void {
        Helpers.hideOriginalBtn();
        Helpers.setExampleHeadline();
        Afsnit2Examples.example("VLV_Del_02_Eksempel_01_01", "VLV_Del_02_Eksempel_01_02","VLV_Del_02_Eksempel_01_03","VLV_Del_02_Eksempel_01_04");
        this.displayNextBtn_1();
        this.playSound_1("VLV_Del_02_Eksempel_01");
    }

    private static example(image01: string, image02: string, image03: string, image04: string): void {
        const img_01 = document.createElement("img");
        img_01.id = "example-picture-01";
        img_01.src = "/api/v1/Image?name=" + image01;

        const img_02 = document.createElement("img");
        img_02.id = "example-picture-02";
        img_02.src = "/api/v1/Image?name=" + image02;

        const img_03 = document.createElement("img");
        img_03.id = "example-picture-03";
        img_03.src = "/api/v1/Image?name=" + image03;

        const img_04 = document.createElement("img");
        img_04.id = "example-picture-04";
        img_04.src = "/api/v1/Image?name=" + image04;

        const exampleContainer = document.createElement("div");
        exampleContainer.className = "example-afsnit-2-container";
        exampleContainer.id = "example_1";
        exampleContainer.innerHTML = `<div class="first-column">
                                            <div id="example-picture-01-container">
                                            </div> 
                                            <div class="second-row" id="example-picture-02-container">
                                            </div>  
                                    </div> 
                                    <div>
                                            <div id="example-picture-03-container">
                                            </div> 
                                            <div class="second-row" id="example-picture-04-container">
                                            </div>  
                                    </div>`;
        $(".megatron-pagecontent.megatron-ot-sammensat span.megatron-opgave-sammensat-tekst").append(exampleContainer);
        $("#example-picture-01-container").append(img_01);
        $("#example-picture-02-container").append(img_02);
        $("#example-picture-03-container").append(img_03);
        $("#example-picture-04-container").append(img_04);
    }

    private playSound_1(soundPath: string): void {
        this.audio.pause();
        this.audio.src = "/api/v1/Sound?name=" + soundPath;
        this.audio.currentTime = 0;
        this.audio.play();
        this.audio.onended = this.activateEventListeners;
    }

    private activateEventListeners = (): void => {
        const img_01 = document.getElementById("example-picture-01") as HTMLElement;
        img_01.onclick = this.img01Handler;

        const img_02 = document.getElementById("example-picture-02") as HTMLElement;
        img_02.onclick = this.img02Handler;

        const img_03 = document.getElementById("example-picture-03") as HTMLElement;
        img_03.onclick = this.img03Handler;

        const img_04 = document.getElementById("example-picture-04") as HTMLElement;
        img_04.onclick = this.img04Handler;
    };

    private img01Handler = (): void => {
        const img_01 = document.getElementById("example-picture-01") as HTMLElement;
        img_01.className = "picture-answer";

        this.disableImgEventListener();
        setTimeout(() => {
            Afsnit2Examples.setCorrectAnswer("example-picture-01", "example-picture-04");
            this.playSound_wrong();
        }, this.timeout);
    };

    private img02Handler = (): void => {
        const img_01 = document.getElementById("example-picture-02") as HTMLElement;
        img_01.className = "picture-answer";

        this.disableImgEventListener();
        setTimeout(() => {
            Afsnit2Examples.setCorrectAnswer("example-picture-02", "example-picture-04");
            this.playSound_wrong();
        }, this.timeout);
    };

    private img03Handler = (): void => {
        const img_01 = document.getElementById("example-picture-03") as HTMLElement;
        img_01.className = "picture-answer";

        this.disableImgEventListener();
        setTimeout(() => {
            Afsnit2Examples.setCorrectAnswer("example-picture-03", "example-picture-04");
            this.playSound_wrong();
        }, this.timeout);
    };

    private img04Handler = (): void => {
        const img_01 = document.getElementById("example-picture-04") as HTMLElement;
        img_01.className = "picture-answer";

        this.disableImgEventListener();
        this.playSound_correct();
    };

    private disableImgEventListener = (): void => {
        const img_01 = document.getElementById("example-picture-01") as HTMLElement;
        img_01.onclick = null;

        const img_02 = document.getElementById("example-picture-02") as HTMLElement;
        img_02.onclick = null;

        const img_03 = document.getElementById("example-picture-03") as HTMLElement;
        img_03.onclick = null;

        const img_04 = document.getElementById("example-picture-04") as HTMLElement;
        img_04.onclick = null;
    };

    private static setCorrectAnswer(idWrong: string, idCorrect: string): void {
        const wrongAnswer = document.getElementById(idWrong) as HTMLElement;
        wrongAnswer.className = "";

        const correctAnser = document.getElementById(idCorrect) as HTMLElement;
        correctAnser.className = "picture-answer";
    }

    private playSound_correct(): void {
        this.audio.pause();
        this.audio.src = "/api/v1/Sound?name=VLV_Del_02_Rigtigt";
        this.audio.currentTime = 0;
        this.audio.play();
        this.audio.onended = this.activateNextBtn;
    }

    private playSound_wrong(): void {
        this.audio.pause();
        this.audio.src = "/api/v1/Sound?name=VLV_Del_02_Forkert";
        this.audio.currentTime = 0;
        this.audio.play();
        this.audio.onended = this.activateNextBtn;
    }

    private activateNextBtn = (): void => {
        const btn = document.getElementById("nextButton") as HTMLButtonElement;
        btn.disabled = false;
    };

    private displayNextBtn_1 = (): void => {
        const btnContainer = document.createElement("div");
        btnContainer.className = "action-buttons mt-4 button-right";
        btnContainer.id = "nextButton_Container";
        const btn = document.createElement("button");
        btn.className = "button button-primary button-long";
        btn.innerHTML = "\u25B6";
        btn.onclick = this.nextBtn_1Handler;
        btn.disabled = true;
        btn.id = "nextButton";
        btnContainer.append(btn);
        $(".megatron-pagecontent").append(btnContainer);
    };

    private nextBtn_1Handler = (): void => {
        Helpers.removeElementById("nextButton_Container");
        Helpers.removeElementById("example_1");
        this.startExample_2();
    };

    // Example 2
    private startExample_2 = (): any => {
        Afsnit2Examples.example( "VLV_Del_02_Eksempel_02_02","VLV_Del_02_Eksempel_02_03","VLV_Del_02_Eksempel_02_04","VLV_Del_02_Eksempel_02_01");
        this.displayNextBtn_2();
        this.playSound_1("VLV_Del_02_Eksempel_02");
    };

    private displayNextBtn_2 = (): void => {
        const btnContainer = document.createElement("div");
        btnContainer.className = "action-buttons mt-4 button-right";
        btnContainer.id = "nextButton_Container";
        const btn = document.createElement("button");
        btn.className = "button button-primary button-long";
        btn.innerHTML = "\u25B6";
        btn.onclick = this.nextBtn_2Handler;
        btn.disabled = true;
        btn.id = "nextButton";
        btnContainer.append(btn);
        $(".megatron-pagecontent").append(btnContainer);
    };

    private nextBtn_2Handler = (): void => {
        Helpers.removeElementById("nextButton_Container");
        Helpers.removeElementById("example_1");
        this.endExample();
    };

    // End Example
    private endExample = (): any => {
        Afsnit2Examples.setEndExample();
        this.displayNextBtn_1();
        this.playSound_4();
    };

    private static setEndExample(): void {
        $(".megatron-pagecontent.megatron-ot-sammensat span strong").html("LYT!");
    }

    private playSound_4(): void {
        this.audio.pause();
        this.audio.src = "/api/v1/Sound?name=VLV_Del_02_Eksempel_03";
        this.audio.currentTime = 0;
        this.audio.play();
        this.audio.onended = Afsnit2Examples.onEndPlaySound4Handler;
    }

    private static onEndPlaySound4Handler(): void {
        Helpers.removeElementById("nextButton_Container");
        Helpers.displayOriginalBtn();
    }
}

export const afsnit2Examples = new Afsnit2Examples();
