import ReactDOM from "react-dom";
import Toast, { ToastProps } from "../Toast/Toast";
import styles from "./ToastManager.module.scss";
import { MessageTypes } from "../shared/enums/MessageTypes";

interface ToastOptions {
  content: string;
  messageType: MessageTypes;
}

export class ToastManager {
  private readonly containerRef: HTMLDivElement;
  private toasts: ToastProps[] = [];

  constructor() {
    const body = document.getElementsByTagName("body")[0] as HTMLBodyElement;
    const toastContainer = document.createElement("div") as HTMLDivElement;
    body.insertAdjacentElement("beforeend", toastContainer);
    this.containerRef = toastContainer;
  }

  public error(message: string): void {
    this.display({
      content: message,
      messageType: MessageTypes.Error
    });
  }

  public success(message: string): void {
    this.display({
      content: message,
      messageType: MessageTypes.Success
    });
  }

  public info(message: string): void {
    this.display({
      content: message,
      messageType: MessageTypes.Info
    });
  }

  public warning(message: string): void {
    this.display({
      content: message,
      messageType: MessageTypes.Warning
    });
  }

  private display(options: ToastOptions): void {
    const toastId = Math.random().toString(36).substr(2, 9);
    this.setStyle();
    const toast: ToastProps = {
      id: toastId,
      ...options,
      destroy: () => this.destroy( toastId),
    };

    this.toasts = [toast, ...this.toasts];
    this.render();
  }

  private setStyle(): void {
    this.containerRef.className = `${styles.toastContainerMain}`;
  }

  private destroy(id: string): void {
    this.toasts = this.toasts.filter((toast: ToastProps) => toast.id !== id);
    this.render();
  }

  private render(): void {
    const toastsList = this.toasts.map((toastProps: ToastProps) => (
      <Toast key={toastProps.id} {...toastProps} />
    ));
    ReactDOM.render(toastsList, this.containerRef);
  }
}

export const toastManager = new ToastManager();
