import { FunctionComponent } from "react";
import Page from "../../components/common/Page/Page";

const Persondatapolitik: FunctionComponent = () => {
    return (
        <Page>
            <div className="container page-container">
                <div>
                    <h2>Persondatapolitik</h2>
                    <p>Persondatapolitikken redegør for, hvordan Styrelsen for It og Læring (STIL) anvender de personoplysninger, du efterlader dig, når du besøger Testafvikleren.dk</p>
                    <p>På Testafvikleren.dk indsamler BUVM oplysninger om brugere i overensstemmelse med reglerne i databeskyttelseslovgivningen. 
                        Det betyder også, at dine data ikke anvendes til automatiske afgørelser, herunder profilering, og at dine data ikke overføres til tredjelande eller tredjeparter.</p>
                    <p>Vi indsamler følgende oplysninger, der kan være karakteriseret som almindelige personoplysninger <strong>for vejledere i testafvikleren</strong>:</p>
                    <ul>
                        <li>Oplysninger fra NemLogin, herunder navn og tilknytning til institution. Disse oplysninger anvendes med henblik på at kunne give dig de rigtige rettigheder</li>
                    </ul>
                    <p>Vi indsamler følgende oplysninger, der kan være karakteriseret som almindelige eller følsomme personoplysninger <strong>for testtagere i testafvikleren</strong>:</p>
                    <ul>
                        <li>Navn og fødselsdato. Disse oplysninger anvendes med henblik på senere identificering af testresultatet af testtagerens tilknyttede vejleder.</li>
                        <li>Testresultat. Alle testresultater opbevares i to år for senere fremsøgning. Efter to år slettes resultatet. 
                            Det er kun vejlederen, der har genereret brugerkoden, og STIL{"'"}s support, der kan se det tilknyttede testresultat. 
                            Testresultaterne kan desuden downloades af testvejlerens med henblik på administrativ dokumentation hos institutionen.</li>
                    </ul>
                    <p>Alle oplysninger opbevares kun så længe BUVM har brug for dem ift. det beskrevne formål. 
                        Selvom en vejleder ikke længere har adgang, vil vejlederens informationer bliver opbevaret, da testresultaterne, som skal gemmes i to år, er tilknyttet hertil.&nbsp;</p>
                    <p><br /></p>
                    <p>Derudover benytter systemet følgende data, som sikrer at Testafvikleren.dk yder den bedst mulige service og gøre siden til et nyttefuldt værktøj:</p>
                    <ul>
                        <li>En vejleder logger ind med virksomhedssignatur, hvorfor virksomhedens CVR-nummer og P-nummer i tilknytning til din profil. 
                            De data hjælper med at styre dine roller og rettigheder.</li>
                        <li>Dit tekniske setup af den enhed, du tilgår hjemmesiden fra, logges.</li>
                    </ul>
                    <h2>Dine rettigheder</h2>
                    <p>Du har i kraft af databeskyttelsesforordningen en række rettigheder i forhold til Børne- og Undervisningsministeriets behandling af de oplysninger, 
                        du opgiver på Testafvikleren.dk</p>
                    <p>Du har ret til indsigt, ret til at gøre indsigelse mod behandlingen, ret til at få berigtiget, slettet eller blokeret oplysninger,
                         der viser sig urigtige eller vildledende eller på lignende måde er behandlet i strid med lovgivningen.</p>
                    <p>Du kan læse mere om dine rettigheder i Datatilsynets vejledning om de registreredes rettigheder, 
                        som du finder på <a className="external-link" href="https://www.datatilsynet.dk/" rel="nofollow">www.datatilsynet.dk</a>.</p>
                    <h3>Børne- og Undervisningsministeriets databeskyttelsesrådgiver</h3>
                    <p>Børne- og Undervisningsministeriet har udpeget en koncernfælles databeskyttelsesrådgiver, 
                        der bl.a. har til opgave at rådgive om persondatareglerne og overvåge ministeriets efterlevelse af reglerne. 
                        Dette omfatter Børne- og Undervisningsministeriets departement og de to styrelser Styrelsen for Undervisning og Kvalitet og Styrelsen for It og Læring. 
                        Børne- og Undervisningsministeriets databeskyttelsesrådgiver er organisatorisk placeret i Styrelsen for It og Læring.</p>
                    <p>Børne- og Undervisningsministeriets databeskyttelsesrådgiver er chefkonsulent Karsten Vest Nielsen.</p>
                    <p>Databeskyttelsesrådgiveren kan oplyse dig nærmere om reglerne for databeskyttelse. 
                        Databeskyttelsesrådgiveren kan også vejlede dig om dine rettigheder i forhold til behandling 
                        af personoplysninger i Børne- og Undervisningsministeriets to styrelser og departement.</p>
                    <p>Databeskyttelsesrådgiveren kan kontaktes på e-mail: <a className="external-link" href="mailto:dpo@uvm.dk" rel="nofollow">dpo@uvm.dk</a> eller på tlf.: 2016 7513.</p>
                    <h2>Klager</h2>
                    <p>Du har ret til at klage over ministeriets behandling af dine oplysninger til Datatilsynet efter reglerne i databeskyttelsesforordningens artikel 77. 
                        Du kan kontakte Datatilsynet fra din digitale postkasse på <a className="external-link" href="http://borger.dk/" rel="nofollow">borger.dk</a>, 
                        ved almindelig e-mail til <a className="external-link" href="mailto:dt@datatilsynet.dk" rel="nofollow">dt@datatilsynet.dk</a> eller 
                        med almindelig post til Datatilsynet, Carl Jacobsens Vej 35, 2500 Valby.</p>
                </div>
            </div>
        </Page >
    );
};

export default Persondatapolitik;