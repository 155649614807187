import axios from "axios";
import { SessionStorage } from "../shared/enums/Storage";

const apiVersion = process.env.REACT_APP_API_VERSION;
const baseURL = process.env.REACT_APP_BASE_URL;

const BaseApi = () => {
    if (!baseURL || !apiVersion) {
        throw new Error("Undefined environment variables");
    }

    const instance = axios.create({
        baseURL: baseURL + apiVersion,
    });

    instance.interceptors.request.use(
        (config) => {
            const token = sessionStorage.getItem(SessionStorage.KC_TOKEN);
            if (token) {
                config.headers!['Authorization'] = 'Bearer ' + token;
            }
            return config;
        },
        (error) => {
            Promise.reject(error);
        });

    axios.interceptors.response.use((response) => {
        return response;
    },
        (error) => {
            const originalRequest = error.config;
            if (error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                const token = sessionStorage.getItem(SessionStorage.KC_TOKEN);
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
                return axios(originalRequest);
            }
        });

return instance;
};

export default BaseApi();
