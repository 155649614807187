import { PropsWithChildren } from "react";
import Footer from "../../footer/Footer/Footer";
import Header from "../../header/Header/Header";
import styles from "./Page.module.scss";

const Page = (props: PropsWithChildren<any>): JSX.Element => {
    return (
        <div>
            <Header />
            <div className={styles.contentWrap}>{props.children}</div>
            <Footer />
        </div>
    );
};

export default Page;
