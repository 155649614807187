import { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";
import styles from "./AccessibilityCookies.module.scss";

const AccessibilityCookies: FunctionComponent = () => {
    const history = useHistory();

    return (
        <div className="col-sm-12 col-md-3 footer-col">
            <h1 aria-label="Webtilgængelighed og cookies" className="h5 mb-4" title="Webtilgængelighed og cookies">
                Webtilgængelighed og cookies
            </h1>
            <ul className={`${styles.list} mt-0`}>
                <li>
                    <a
                        className="function-link" 
                        rel="noopener noreferrer"
                        href="https://www.was.digst.dk/testafvikleren-dk"
                        target="_blank">
                        Tilgængelighedserklæring
                    </a>
                </li>
                <li>
                    <button className={`function-link ${styles.noneDecoration}`} tabIndex={0} onClick={() => history.push("/persondatapolitik")} >
                    Privatlivspolitik
                    </button>
                </li>
                <li>
                    <button className={`function-link ${styles.noneDecoration}`} tabIndex={0} onClick={() => history.push("/personoplysninger")} >
                    Behandling af personoplysninger
                    </button>
                </li>
                <li>
                    <button className={`function-link ${styles.noneDecoration}`} tabIndex={0} onClick={() => history.push("/cookies")} >
                        Cookiespolitik
                    </button>
                </li>
            </ul>
        </div>
    );
};

export default AccessibilityCookies;
