import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import VejlederPage from "../../vejleder/VejlederPage";
import styles from "./SupporterCVR.module.scss";
import { RuleTypes } from "../../../shared/enums/RuleTypes";
import { Roles } from "../../../shared/enums/Roles";
import { CreateRules, GetRulesByType } from "../../../apiService/RuleApi";
import { RuleDetails } from "../../../shared/types/RuleDetails";
import { toastManager } from "../../../components/common/Toast/ToastManager/ToastManager";
import updateTable from "../../../components/shared/utils/updateTable";
import { Opgavesaet } from "../../../shared/types/Opgavesaet";
import { getAllVisibleOpgavesaetApi } from "../../../apiService/OpgavesaetApi";
import SupportRuleTable from "../../../components/supporter/SupporterRuleTable/SupporterRuleTable";

const SupporterCVR: FunctionComponent = () => {
    const [CVRs, setCVRs] = useState<string>("");
    const [rules, setRules] = useState<RuleDetails[]>([]);
    const [forceUpdate, setForceUpdate] = useState(0);
    const [opgavesaet, setOpgavesaet] = useState<Opgavesaet[]>([]);
    const [selectedOpgavesetId, setSelectedOpgavesaetId] = useState<string>("");

    const addCVR = () => {
        CreateRules(CVRs.replaceAll(" ", "").split(","), RuleTypes.CVR, Roles.VEJLEDER, selectedOpgavesetId).then((response) => {
            if (response.succeeded) {
                toastManager.info(response.Message);
                setForceUpdate(updateTable());
            } else {
                toastManager.error(response.errors.toString());
            }
        });

    };

    useEffect(() => {
        GetRulesByType(RuleTypes.CVR).then((rules) => {
            setRules(rules);
        });
        getAllVisibleOpgavesaetApi().then((response) => {
            setOpgavesaet(response);
        });
    }, [forceUpdate]);

    const opgavesaetOptions =
        opgavesaet.length > 0 &&
        opgavesaet.map((item) => {
            return (
                <option key={item.id} id={item.id} value={item.navn}>
                    {item.navn}
                </option>
            );
        });

    const handleSelectedChangeOpgavesaet = (event: ChangeEvent<HTMLSelectElement>) => {
        event.preventDefault();
        const selectedIndex = event.target.options.selectedIndex;
        const id = event.target.options[selectedIndex].getAttribute("id");
        if (id != undefined) {
            setSelectedOpgavesaetId(id!);
        }
    };

    const isValidCVR = () => {
        const regex = new RegExp("^\\d{8}(,\\d{8})*$");
        return !regex.test(CVRs.replaceAll(" ", ""));
    };

    return (
        <VejlederPage>
            <h1 className="h1">Adgangsstyring</h1>
            <span className={`${styles.infoText}`}>Indtast CVR-numre og hvilken test, vejledere med pågældende CVR-numre skal kunne generere brugerkoder til. <br/>
                Tilføj flere CVR-numre ved at separere med et komma.
            </span>
            <label className={`form-label ${styles.label} ${styles.labelPadding}`} htmlFor="cvr">CVR-numre: </label>
            <span className="form-hint">F.eks. 12345678, 76543210, 23458121 </span>
            <input
                type="text"
                value={CVRs}
                className={`form-input ${styles.input}`}
                name="CVR"
                id="cvr"
                onChange={(e) => {
                    setCVRs(e.target.value);
                }}>
                   
            </input>

            <label className={`form-label ${styles.label} ${styles.textLabel}`} htmlFor="test">Test: </label>
            <select
                onChange={(e) => handleSelectedChangeOpgavesaet(e)}
                className={`item form-select`}
                style={{ width: "auto" }}
                id="test"
                defaultValue={""}>
                <option disabled value={""} />
                {opgavesaetOptions}
            </select>
            <br />
            <button
                className={`button button-secondary ${styles.button}`}
                onClick={addCVR}
                disabled={isValidCVR() || selectedOpgavesetId == ""}>
                Tilføj
            </button>
            <span className={`${styles.infoText}`}>Vejledere logger ind i Testafvikleren med en medarbejdersignatur, som indeholder et CVR-nummer tilknyttet den virksomhed de arbejder for. <br /> 
                CVR-numre kan findes gennem CVR-registret. Det er ikke muligt for en vejleder at logge ind, hvis CVR-nummeret ikke er tilknyttet en prøve.</span>
            <h2 className="h2">Adgange til testafvikleren</h2>
            <SupportRuleTable
                setForceUpdate={setForceUpdate}
                rules={rules}
                ruleType={RuleTypes.CVR}
            />
        </VejlederPage>
    );
};

export default SupporterCVR;
