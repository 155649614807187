import {FunctionComponent, useEffect, useState} from "react";
import VejlederCreateHoldModal from "../../components/vejleder/VejlederCreateHoldModal/VejlederCreateHoldModal";
import VejlederHoldTable from "../../components/vejleder/VejlederHoldTable/VejlederHoldTable";
import VejlederPage from "./VejlederPage";
import {GetHoldAsVejlederApi} from "../../apiService/HoldApi";
import {HoldDetails} from "../../shared/types/HoldDetails";

const VejlederHold: FunctionComponent = () => {
    const [forceUpdate, setForceUpdate] = useState<number>(0);
    const [hold, setHold] = useState<HoldDetails[]>([]);

    useEffect(() => {
        GetHoldAsVejlederApi().then((hold) => {
            const sorted = hold.sort(
                (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            );
            setHold(sorted);
        });
    }, [forceUpdate]);

    return (
        <VejlederPage>
            <h1 className="h1">Hold</h1>
            <p>Opret hold til at inddele brugerkoder. Benyt hold til f.eks. at sortere i resultatvisningen over gennemførte tests. 
                Det er muligt at arkivere hold, der ikke længere er i brug</p>
            <VejlederCreateHoldModal setForceUpdate={setForceUpdate} />
            <VejlederHoldTable hold={hold} forceUpdate={forceUpdate} setForceUpdate={setForceUpdate} />
        </VejlederPage>
    );
};

export default VejlederHold;
