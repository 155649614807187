import moment from "moment";
import {ExportCsv, ExportPdf} from "@material-table/exporters";

const formatDates = (cols: any[], datas: any[]): [any[], any[]] => {
    const dateArr: string[] = [];

    cols.forEach((currentValue): void => {
        if(currentValue.type == "date" ) {
            dateArr.push(currentValue.field);
        }
    });

    datas.forEach((currentValue): void => {
        for (const key in currentValue) {
            if (dateArr.includes(key) && currentValue[key] != "-") {
                currentValue[key] = moment(new Date(currentValue[key])).format("DD-MM-YYYY");
            }
        }
    });

    return [cols, datas];
};

const removeEmptyColoumns = (cols: any[], datas: any[]): [any[], any[]] => {
    let index = 0;
    cols.forEach((currentValue): void => {
        for (const key in currentValue) {
            if (currentValue[key] == "internal_action") {
                cols.splice(index, 1);
            }
        }
        index++;
    });

    return [cols, datas];
};

const removeEmptyFormattedResult = (cols: any[], datas: any[]): [any[], any[]] => {
    datas.forEach((currentValue): void => {
        if (currentValue?.formattedResult == "-") {
            currentValue.formattedResult = "";
        }
    });

    return [cols, datas];
};

const addPlaceholderForEmptyValues = (cols: any[], datas: any[]): [any[], any[]] => {
    datas.forEach((currentValue): void => {
        for (const key in currentValue) {
            if (currentValue[key] == "" || currentValue[key] == undefined || currentValue[key] == null) {
                currentValue[key] = "-";
            }
        }
    });

    return [cols, datas];
};

export const exportPdf = (cols: any[], datas: any[], title: string): void => {
    [cols, datas] = removeEmptyColoumns(cols, datas);
    [cols, datas] = addPlaceholderForEmptyValues(cols, datas);
    [cols, datas] = formatDates(cols, datas);
    ExportPdf(cols, datas, title);
};

export const exportCSV = (cols: any[], datas: any[], title: string): void => {
    [cols, datas] = removeEmptyColoumns(cols, datas);
    [cols, datas] = formatDates(cols, datas);
    [cols, datas] = removeEmptyFormattedResult(cols, datas);
    ExportCsv(cols, datas, title, ";");
};

export const pdfLabel = "Eksportér PDF";
export const csvLabel = "Eksportér CSV";

