export const localization = {
    pagination: {
        labelRowsSelect: "Rækker",
        labelDisplayedRows: "Viser {from} til {to} af {count}",
        firstAriaLabel: "Første side",
        firstTooltip: "",
        previousAriaLabel: "Forige side",
        previousTooltip: "",
        nextAriaLabel: "Næste side",
        nextTooltip: "",
        lastAriaLabel: "Sidste side",
        lastTooltip: "",
    },
    toolbar: {
        searchPlaceholder: "Søg i tabellen",
        searchTooltip: "",
        exportPDFName: "Eksportér som PDF",
        exportCSVName: "Eksportér som CSV",
        exportTitle: "Eksportér",
        exportAriaLabel: "Eksportér",
        nRowsSelected: ""
    },
    body: {
        emptyDataSourceMessage: "Ingen data at vise",
        filterRow: {
            filterTooltip: "Filter",
        },
    },
};
