import BaseApi from "./BaseApi";
import { AxiosResponse } from "axios";
import { ResponseType } from "../shared/types/ResponseType";

export const GetResultByBrugerkodeAsElevApi = async (brugerkode: string): Promise<any> => {
    return await BaseApi.get<Request>("Result/GetByBrugerkodeAsElev?brugerkode=" + brugerkode);
};

export const GetResultAsPdfByBrugerkodeAsElevApi = async (brugerkode: string | undefined | null): Promise<any> => {
    return await BaseApi.get<Request>("Result/GetAsPdfByBrugerkodeAsElev?brugerkode=" + brugerkode);
};

export const GetResultByBrugerkodeApi = async (brugerkode: string): Promise<any> => {
    return await BaseApi.get<Request>("Result/GetByBrugerkode?brugerkode=" + brugerkode);
};

export const GetOpgavebesvarelserByBrugerkode = async (brugerkode: string): Promise<any> => {
    return await BaseApi.get<Request>("Result/GetOpgavebesvarelserByBrugerkode?brugerkode=" + brugerkode);
};

export const GetResultAsPdfByBrugerkodeApi = async (brugerkode: string | undefined | null): Promise<any> => {
    return await BaseApi.get<Request>("Result/GetAsPdfByBrugerkode?brugerkode=" + brugerkode);
};

export const CreateResultsAsPdfZippedApi = async (brugerkoder: string[]): Promise<ResponseType<string>> => {
    try {
        const response = await BaseApi.post<Request, AxiosResponse<any, any>>("Result/CreateAsPdfZipped", { brugerkoder });
        return {
            data: response.data.data,
            succeeded: true,
            Message: response.data.message,
            errors: [],
        };
    } catch (error: any) {
        return {
            data: undefined,
            succeeded: false,
            Message: "",
            errors: error.response.data.Message,
        };
    }
};



