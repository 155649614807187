import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./assets/css/testaf.scss";
import "../node_modules/dkfds/dist/css/dkfds-virkdk.css";
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './keycloak';
import { authorizationService } from "./services/auth/authorization.service";
import { GetRole } from "./apiService/AuthenticatedUserApi";
import { SessionStorage } from "./shared/enums/Storage";

const token = sessionStorage.getItem(SessionStorage.KC_TOKEN);
const refreshToken = sessionStorage.getItem(SessionStorage.KC_REFRESH_TOKEN);

const tokenhandler = () => {
    if (keycloak.token != undefined && keycloak.refreshToken) {
        authorizationService.setKeycloakTokenInSessionStorage(keycloak.token);
        authorizationService.setKeycloakRefreshTokenInSessionStorage(keycloak.refreshToken);
        GetRole().then((response) => {
            authorizationService.setRoleInSessionStorage(response.data.data);
        }).catch((error) => {
            console.log("Error in get role: ", error);
            authorizationService.clearSessionStorage();
            authorizationService.setRoleInSessionStorage("none");
        });
    } else {
        console.log("Token is empty");
        console.log("Keycloak: ", keycloak);
        console.log("Token: " + token);
        console.log("RefreshToken: " + refreshToken);
    }
};

ReactDOM.render(
    <React.StrictMode>
        <ReactKeycloakProvider
            authClient={keycloak}
            onTokens={() => tokenhandler()}
            initOptions={{ token, refreshToken }}>
            <App />
        </ReactKeycloakProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

