import { FC } from "react";

export interface IDisplayProps {
    display: boolean;
    setDisplay: React.Dispatch<React.SetStateAction<boolean>>;
}

const ToggleResultDisplay: FC<IDisplayProps> = ({ display, setDisplay }) => {
    return (
        <div>
            <button
                className="button button-secondary"
                onClick={() => {
                    setDisplay(!display);
                }}>
                {display ? "Skjul resultat" : "Vis resultat"}
            </button>
        </div>
    );
};

export default ToggleResultDisplay;
