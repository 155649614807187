import {AxiosResponse} from "axios";
import BaseApi from "./BaseApi";
import { ResponseType } from "../shared/types/ResponseType";


export const CreateSupportsagApi = async (begrundelse: string, authenticatedUserBrugernavn: string): Promise<ResponseType<string>> => {
    try {
        const response = await BaseApi.post<Request, AxiosResponse<any, any>>("Supportsag", { begrundelse, authenticatedUserBrugernavn });
        return {
            data: response.data.data,
            succeeded: true,
            Message: response.data.message,
            errors: [],
        };
    } catch (error: any) {
        return {
            data: undefined,
            succeeded: false,
            Message: "",
            errors: [error.response.data.Message],
        };
    }
};
