import { ChangeEvent, Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getAllVisibleOpgavesaetApi, GetOpgavesaetByRule } from "../../../apiService/OpgavesaetApi";
import { CreateBrugerkoderApi } from "../../../apiService/AuthenticatedUserApi";
import $ from "jquery";
import { ModalBackdrop } from "../../common/ModalBackdrop";
import { HoldDetails } from "../../../shared/types/HoldDetails";
import { CreateHoldApi, GetHoldAsVejlederApi } from "../../../apiService/HoldApi";
import { Opgavesaet } from "../../../shared/types/Opgavesaet";
import styles from "./VejlederCreateBrugerkoderModal.module.scss";
import { toastManager } from "../../common/Toast/ToastManager/ToastManager";
import updateTable from "../../shared/utils/updateTable";
import { authorizationService } from "../../../services/auth/authorization.service";

const VejlederCreateBrugerkoderModal: FunctionComponent<{
    forceUpdate: number,
    setForceUpdate: Dispatch<SetStateAction<number>>;
}> = ({ forceUpdate, setForceUpdate }) => {

    enum HoldOptions {
        NOHOLD = "noHold",
        EXISTINGHOLD = "existingHold",
        NEWHOLD = "newHold"
    }

    const [opgavesaet, setOpgavesaet] = useState<Opgavesaet[]>([]);
    const [selectedOpgavesetId, setSelectedOpgavesaetId] = useState<string>();
    const [selectedOpgavesetValue, setSelectedOpgavesaetValue] = useState<string>();
    const [selectedHoldId, setSelectedHoldId] = useState<string>();
    const [selectedHoldValue, setSelectedHoldValue] = useState<string>();
    const [numberToCreate, setNumberToCreate] = useState<number>(1);
    const [holdDetails, setHoldDetails] = useState<HoldDetails[]>([]);
    const [hold, setHold] = useState(HoldOptions.NOHOLD);
    const [newHoldName, setNewHoldName] = useState<string>("");

    const history = useHistory();

    useEffect(() => {
        if (authorizationService.isSupporter()) {
            getAllVisibleOpgavesaetApi().then((response) => {
                setOpgavesaet(response);
            });
        } else if (authorizationService.isVejleder()) {
            GetOpgavesaetByRule().then((response) => {
                if (response.succeeded && response.data) {
                    setOpgavesaet(response.data);
                }
            });
        }

        GetHoldAsVejlederApi().then((hold: HoldDetails[]) => {
            setHoldDetails(hold.filter(function (hold) {
                return !hold?.archived;
            }));
        });
    }, [history, forceUpdate]);

    const handleSelectedChangeOpgavesaet = (event: ChangeEvent<HTMLSelectElement>) => {
        event.preventDefault();
        const selectedIndex = event.target.options.selectedIndex;
        const id = event.target.options[selectedIndex].getAttribute("id");
        const value = event.target.options[selectedIndex].getAttribute("value");
        setSelectedOpgavesaetId(id!);
        setSelectedOpgavesaetValue(value!);
    };

    const handleSelectedChangeHold = (event: ChangeEvent<HTMLSelectElement>) => {
        event.preventDefault();
        const selectedIndex = event.target.options.selectedIndex;
        const id = event.target.options[selectedIndex].getAttribute("id");
        const value = event.target.options[selectedIndex].getAttribute("value");
        setSelectedHoldValue(value!);
        setSelectedHoldId(id!);
    };

    const opgavesaetOptions =
        opgavesaet.length > 0 &&
        opgavesaet.map((item) => {
            return (
                <option key={item.id} id={item.id} value={item.navn}>
                    {item.navn}
                </option>
            );
        });

    const holdOptions =
        holdDetails.length > 0 &&
        holdDetails.map((item) => {
            return (
                <option key={item.id} id={item.id} value={item.navn}>
                    {item.navn}
                </option>
            );
        });

    const handleSubmit = async (event: { preventDefault: () => void }) => {
        event.preventDefault();
        const requestHoldId = await getRequestHoldId();
        if (hold == HoldOptions.NEWHOLD) {
            if (requestHoldId == undefined) {
                return;
            }
        }

        const response = await CreateBrugerkoderApi(numberToCreate, selectedOpgavesetId!, requestHoldId);
        if (response.succeeded) {
            toastManager.success(response.Message);
            // a new random number will make the VejlederBrugerkoderTable fetch data again including new brugerkoder
            setForceUpdate(updateTable());
        } else {
            toastManager.error(response.errors.toString());
        }

        toggleOpretBrugerkoderModal();
    };

    const getRequestHoldId = async (): Promise<string | undefined> => {
        switch (hold) {
            case HoldOptions.NOHOLD:
                return undefined;
            case HoldOptions.EXISTINGHOLD:
                return selectedHoldId;
            case HoldOptions.NEWHOLD:
                if (newHoldName != "") {
                    const response = await CreateHoldApi(newHoldName);
                    if (response.succeeded && response.data) {
                        return response.data;
                    } else {
                        toastManager.error(response.errors.toString());
                    }
                } else {
                    toastManager.error("Du kan ikke oprette et hold uden et navn.");
                }

                return undefined;
            default:
                break;
        }
    };

    const toggleOpretBrugerkoderModal = () => {
        setSelectedOpgavesaetId("");
        setSelectedOpgavesaetValue("");
        setSelectedHoldId(undefined);
        setSelectedHoldValue("");
        setNumberToCreate(1);
        setHold(HoldOptions.NOHOLD);
        setNewHoldName("");

        $("#modal-brugerkoder").toggle();
        $("#modal-backdrop").toggle();
    };

    const handleHoldChange = (event: any) => {
        setHold(event.target.value);
    };

    return (
        <>
            <div className="fds-modal" id="modal-brugerkoder" aria-hidden="true" role="dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="modal-title">Opret brugerkoder</h2>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label className="form-label">Hvor mange brugerkoder ønsker du at oprette?</label>
                            <input
                                type="number"
                                value={numberToCreate}
                                className="item form-input input-width-xxs"
                                name="count"
                                onChange={(e) => {
                                    setNumberToCreate(e.target.valueAsNumber);
                                }}>
                            </input>
                        </div>
                        <div className="form-group">
                            <label className="form-label">Til hvilket opgavesæt skal brugerkoderne oprettes?</label>
                            <select
                                onChange={(e) => handleSelectedChangeOpgavesaet(e)}
                                className="item form-select"
                                style={{ width: "auto" }}
                                value={selectedOpgavesetValue}>
                                <option disabled value={""} />
                                {opgavesaetOptions}
                            </select>
                        </div>
                        <div className="form-group">
                            <label className="form-label">Til hvilket hold skal brugerkoderne tilknyttes?</label>
                            <div className={`${styles.radioButtonContainer}`}>
                                <div className={`${styles.firstOptionContainer}`}>
                                    <input type="radio"
                                        className={``}
                                        checked={hold === HoldOptions.NOHOLD}
                                        value={HoldOptions.NOHOLD}
                                        onChange={handleHoldChange} /> Intet hold
                                </div>
                                <div className={`${styles.optionContainer}`}>
                                    <div className={`${styles.selectionContainer}`}>
                                        <input type="radio"
                                            className={`${styles.radioButton}`}
                                            checked={hold === HoldOptions.EXISTINGHOLD}
                                            value={HoldOptions.EXISTINGHOLD}
                                            onChange={handleHoldChange} /> Eksisterende hold
                                    </div>
                                    <select
                                        onChange={(e) => handleSelectedChangeHold(e)}
                                        className={`item form-select ${styles.input}`}
                                        style={{ width: "auto" }}
                                        value={selectedHoldValue}
                                        disabled={hold != HoldOptions.EXISTINGHOLD}>
                                        <option disabled value={""} />
                                        {holdOptions}
                                    </select>
                                </div>
                                <div className={`${styles.optionContainer}`}>
                                    <div className={`${styles.selectionContainer}`}>
                                        <input type="radio"
                                            className={`${styles.radioButton}`}
                                            checked={hold === HoldOptions.NEWHOLD}
                                            value={HoldOptions.NEWHOLD}
                                            onChange={handleHoldChange} /> Nyt hold
                                    </div>
                                    <input
                                        type="string"
                                        value={newHoldName}
                                        className={`item form-input ${styles.input}`}
                                        name={HoldOptions.NEWHOLD}
                                        disabled={hold != HoldOptions.NEWHOLD}
                                        onChange={(e) => {
                                            setNewHoldName(e.target.value);
                                        }}>
                                    </input>
                                </div>
                            </div>
                        </div>
                        <div className={styles.submitCancel}>
                            <div className={`action-buttons mt-4 ${styles.submitButton}`}>
                                <input
                                    type="submit"
                                    className="button button-primary button-long"
                                    value="Opret brugerkoder"
                                    onClick={handleSubmit}
                                    disabled={
                                        numberToCreate < 1 || 
                                        Number.isNaN(numberToCreate) || 
                                        selectedOpgavesetId == "" || 
                                        (selectedHoldId == undefined && hold == HoldOptions.EXISTINGHOLD)}>
                                </input>
                            </div>
                            <div className="action-buttons mt-4">
                                <button
                                    className="button button-secondary button-long"
                                    onClick={toggleOpretBrugerkoderModal}>
                                    Annullér
                                </button>
                            </div>
                        </div>
                        <div className="modal-footer" />
                    </div>
                </div>
            </div>
            <button className="button button-secondary" onClick={toggleOpretBrugerkoderModal}>
                Opret brugerkoder
            </button>
            <ModalBackdrop />
        </>
    );
};

export default VejlederCreateBrugerkoderModal;
