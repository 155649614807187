import jsPDF from "jspdf";

export type BrugerkodePdf = {
    brugerkode: string,
    testName: string
};

function shouldInsertNewPage(index: number) : boolean {
    const maxNumberOfBrugerkoderPerPage = 9; // This is found by trial and error and is based on current FontSize used.

    return (index + 1) % maxNumberOfBrugerkoderPerPage === 0;
}

function calculateYPosition(index: number) : number {
    const distanceFromTop = 20;
    const distanceBetweenBrugerkoder = 30;
    const positionOnPage = index % 9;

    return distanceBetweenBrugerkoder * positionOnPage + distanceFromTop;
}

function saveBrugerkoderToFile(brugerkoder: BrugerkodePdf[]) : void {

    const filename = "Brugerkoder.pdf";

    const pdf = new jsPDF('portrait', 'mm', 'a4');

    pdf.setFont("times", "normal");
    brugerkoder.map(brugerkode => [brugerkode.brugerkode, brugerkode.testName, "Testafvikleren.dk"])
        .forEach((brugerkodeBlok, index) => {
            pdf.text(brugerkodeBlok, 30, calculateYPosition(index));

            if (shouldInsertNewPage(index)) {
                pdf.addPage("a4", "portrait");
            }
        });

    pdf.save(filename);
}

export default saveBrugerkoderToFile;