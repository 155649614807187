import { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";
import HeaderTitle from "../HeaderTitle";
import LogoCrown from "../LogoCrown";
import { LoginInfo } from "../../auth/LoginInfo/LoginInfo";
import styles from "./Header.module.scss";

const Header: FunctionComponent = () => {
    const history = useHistory();

    return (
        <header className="header">
            <div className="portal-header">
                <div className="portal-header-inner container">
                    <button className={`stillogo function-link ${styles.link}`} tabIndex={0} onClick={() => history.push("/")} title="Testafvikleren">
                        <div className="crown">
                            <LogoCrown />
                        </div>
                        <span className="solution-name">
                            <HeaderTitle />
                        </span>
                    </button>
                    <LoginInfo />
                </div>
            </div>
        </header>
    );
};

export default Header;
