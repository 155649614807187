export const invalidBrugerkodeWarning: JSX.Element = (
    <div className="alert alert-info">
        <div className="alert-body">
            <p className="alert-heading">Brugerkode findes ikke</p>
            <p className="alert-text">Der findes ingen test på den anvendte brugerkode</p>
        </div>
    </div>
);

export const closedTesttagningWarning: JSX.Element = (
    <div className="alert alert-info">
        <div className="alert-body">
            <p className="alert-heading">Testtagning afsluttet</p>
            <p className="alert-text">Testtagning er afsluttet og kan derfor ikke åbnes</p>
        </div>
    </div>
);

export const genericWarning = (props: string): JSX.Element => {
    return (
        <div className="alert alert-info">
            <div className="alert-body">
                <p className="alert-heading">{props}</p>
            </div>
        </div>
    );
};

export const noWarning: JSX.Element = <div></div>;
