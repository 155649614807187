import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./AnswerLineText.module.scss";

const AnswerLine = (props: { opgaver: any, opgavebesvarelser: any }): JSX.Element => {
    const answerLines = [] as JSX.Element[];
    props.opgaver.forEach((opgave: any) => {
        const isKorrektBesvarelse = props.opgavebesvarelser.find((e: any) => e.opgaveId === opgave.id)?.svarmulighed?.korrekt;
        const besvarelse = props.opgavebesvarelser.find((e: any) => e.opgaveId === opgave.id)?.svarmulighed?.svar;
        const korrektBesvarelse = opgave.svarmuligheder[opgave.korrektSvar];

        answerLines.push(<div className={styles.alignAnswerText}  key={Math.random()} > {answerLineImage(isKorrektBesvarelse)} {answerLineText(opgave.type, isKorrektBesvarelse, besvarelse, korrektBesvarelse)}</div>);
    });
    return (<div>{answerLines}</div>);
};

function answerLineText(questionType: any, isKorrektBesvarelse: any, besvarelse: any, korrektBesvarelse: any) {
    switch (questionType) {
        //This case corresponds to assignments with radiobutton answers
        case 6:
            if (isKorrektBesvarelse == undefined) {
                return <p className="mb-0">{"Spørgsmålet blev ikke besvaret."}</p>;
            }
            else if (isKorrektBesvarelse) {
                return <p className="mb-0">{"Svaret var rigtigt"}</p>;
            }
            else if (!isKorrektBesvarelse) {
                return <p className="mb-0">{"Svaret var forkert"}</p>;
            }
            break;
        //This case corresponds to assignments with DropDown answers
        case 7:
            if (isKorrektBesvarelse == undefined) {
                return <p className="mb-0">{"Spørgsmålet blev ikke besvaret."}</p>;
            }
            else if (isKorrektBesvarelse) {
                return <p className="mb-0" dangerouslySetInnerHTML={{ __html: `Rigtigt. Du svarede <i>${besvarelse}</i>.`}}/>;
            }
            else if (!isKorrektBesvarelse) {
                return <p className="mb-0" dangerouslySetInnerHTML={{ __html: `Forkert. Du svarede <i>${besvarelse}</i>. Det rigtige svar er <i>${korrektBesvarelse}</i>.`}}/>;
            }
            break;
    }
    return "";
}

function answerLineImage(isKorrektBesvarelse: boolean | undefined) {
    if (isKorrektBesvarelse == undefined) {
        return <FontAwesomeIcon className={styles.iconAlign} icon={["fas", "circle-exclamation"]} />;
    }
    else if (isKorrektBesvarelse) {
        return <FontAwesomeIcon className={styles.iconAlign} icon={["fas", "check"]} color="green" />;
    }
    else if (!isKorrektBesvarelse) {
        return <FontAwesomeIcon className={styles.iconAlign} icon={["fas", "xmark"]} color="red" />;
    }
    return <></>;
}

export default AnswerLine;