import {Helpers} from "./helpers";
import $ from "jquery";

export class Afsnit3Examples {
    private audio = new Audio("/api/v1/Sound?name=VLV_Del_02_Eksempel_01");
    private timeout = 500;
    private isExample01 = true;
    private timeout_example_tutorial = 12000;

    public start(): void {
        Helpers.hideOriginalBtn();
        Helpers.setExampleHeadline();
        Afsnit3Examples.example("san", "syb", "læl", "syf");
        this.displayTutorialNextButton();
        this.playSoundTutorial();
        setTimeout(() => {
            this.setCorrectAnswerTutorial("button-01");
        }, this.timeout_example_tutorial);
    }

    // Tutorial
    private setCorrectAnswerTutorial = (correctElementId: string): void => {
        const correctElement = document.getElementById(correctElementId) as HTMLElement;
        correctElement.className = "picture-answer button-afsnit-3 button-afsnit-3-correct";
    };

    private playSoundTutorial(): void {
        this.audio.pause();
        this.audio.src = "/api/v1/Sound?name=VLV_Del_03_Eksempel_01";
        this.audio.currentTime = 0;
        this.audio.play();
        this.audio.onended = this.activateNextBtn;
    }

    private displayTutorialNextButton = (): void => {
        const btnContainer = document.createElement("div");
        btnContainer.className = "action-buttons mt-4 button-right";
        btnContainer.id = "nextButton_1Container";
        const btn = document.createElement("button");
        btn.className = "button button-primary button-long";
        btn.innerHTML = "\u25B6";
        btn.onclick = this.tutorialNextButtonHandler;
        btn.disabled = true;
        btn.id = "nextButton_1";
        btnContainer.append(btn);
        $(".megatron-pagecontent").append(btnContainer);
    };

    private tutorialNextButtonHandler = (): void => {
        Helpers.removeElementById("nextButton_1Container");
        Helpers.removeElementById("example_1");
        this.startExample_1();
    };

    // Example 1
    private startExample_1 = (): any => {
        Afsnit3Examples.example("tyfd", "fæsd", "kruf", "føse");
        this.displayNextBtn01();
        this.playSound("VLV_Del_03_Eksempel_02");
    };

    private static example(btn_01_textcontent: string, btn_02_textcontent: string, btn_03_textcontent: string, btn_04_textcontent: string): void {
        const btn_01 = document.createElement("button");
        btn_01.id = "button-01";
        btn_01.className = "button-afsnit-3";
        btn_01.textContent = btn_01_textcontent;

        const btn_02 = document.createElement("button");
        btn_02.id = "button-02";
        btn_02.className = "button-afsnit-3";
        btn_02.textContent = btn_02_textcontent;

        const btn_03 = document.createElement("button");
        btn_03.id = "button-03";
        btn_03.className = "button-afsnit-3";
        btn_03.textContent = btn_03_textcontent;

        const btn_04 = document.createElement("button");
        btn_04.id = "button-04";
        btn_04.className = "button-afsnit-3";
        btn_04.textContent = btn_04_textcontent;

        const exampleContainer = document.createElement("div");
        exampleContainer.className = "example-afsnit-3-container-ramme";
        exampleContainer.id = "example_1";
        exampleContainer.innerHTML = `<div class="example-afsnit-3-container">
                                            <div id="button-container-01" class="example-afsnit-03-button-container">
                                            </div>
                                            <div id="button-container-02" class="example-afsnit-03-button-container">
                                            </div>
                                            <div id="button-container-03" class="example-afsnit-03-button-container">
                                            </div>
                                            <div id="button-container-04" class="example-afsnit-03-button-container">
                                            </div>
                                     </div>`;

        $(".megatron-pagecontent.megatron-ot-sammensat span.megatron-opgave-sammensat-tekst").append(exampleContainer);
        $("#button-container-01").append(btn_01);
        $("#button-container-02").append(btn_02);
        $("#button-container-03").append(btn_03);
        $("#button-container-04").append(btn_04);
    }

    private displayNextBtn01 = (): void => {
        const btnContainer = document.createElement("div");
        btnContainer.className = "action-buttons mt-4 button-right";
        btnContainer.id = "nextButton_1Container";
        const btn = document.createElement("button");
        btn.className = "button button-primary button-long";
        btn.innerHTML = "\u25B6";
        btn.onclick = this.nextBtn01Handler;
        btn.disabled = true;
        btn.id = "nextButton_1";
        btnContainer.append(btn);
        $(".megatron-pagecontent").append(btnContainer);
    };

    private playSound(soundPath: string): void {
        this.audio.pause();
        this.audio.src = "/api/v1/Sound?name=" + soundPath;
        this.audio.currentTime = 0;
        this.audio.play();
        this.audio.onended = this.activateEventListeners;
    }

    private activateEventListeners = (): void => {
        const btn_01 = document.getElementById("button-01") as HTMLElement;
        btn_01.onclick = this.btn01Handler;

        const btn_02 = document.getElementById("button-02") as HTMLElement;
        btn_02.onclick = this.btn02Handler;

        const btn_03 = document.getElementById("button-03") as HTMLElement;
        btn_03.onclick = this.btn03Handler;

        const btn_04 = document.getElementById("button-04") as HTMLElement;
        btn_04.onclick = this.btn04Handler;
    };

    private btn01Handler = (): void => {
        const btn_01 = document.getElementById("button-01") as HTMLElement;
        btn_01.className = "picture-answer button-afsnit-3 button-afsnit-3-correct";

        this.disableImgEventListener();
        setTimeout(() => {
            this.setCorrectAnswer("button-01", "button-02");
            this.playSoundWrong();
        }, this.timeout);
    };

    private btn02Handler = (): void => {
        const btn_02 = document.getElementById("button-02") as HTMLElement;
        btn_02.className = "picture-answer button-afsnit-3 button-afsnit-3-correct";

        this.disableImgEventListener();
        this.playSoundCorrect();
    };

    private btn03Handler = (): void => {
        const btn_03 = document.getElementById("button-03") as HTMLElement;
        btn_03.className = "picture-answer button-afsnit-3 button-afsnit-3-correct";

        this.disableImgEventListener();
        setTimeout(() => {
            this.setCorrectAnswer("button-03", "button-02");
            this.playSoundWrong();
        }, this.timeout);
    };

    private btn04Handler = (): void => {
        const btn_04 = document.getElementById("button-04") as HTMLElement;
        btn_04.className = "picture-answer button-afsnit-3 button-afsnit-3-correct";

        this.disableImgEventListener();
        setTimeout(() => {
            this.setCorrectAnswer("button-04", "button-02");
            this.playSoundWrong();
        }, this.timeout);
    };

    private disableImgEventListener = (): void => {
        const btn_01 = document.getElementById("button-01") as HTMLElement;
        btn_01.onclick = null;

        const btn_02 = document.getElementById("button-02") as HTMLElement;
        btn_02.onclick = null;

        const btn_03 = document.getElementById("button-03") as HTMLElement;
        btn_03.onclick = null;

        const btn_04 = document.getElementById("button-04") as HTMLElement;
        btn_04.onclick = null;
    };

    private setCorrectAnswer(idWrong: string, idCorrect: string): void {
        const wrongAnswer = document.getElementById(idWrong) as HTMLElement;
        wrongAnswer.className = "button-afsnit-3";

        const correctAnser = document.getElementById(idCorrect) as HTMLElement;
        correctAnser.className = "picture-answer button-afsnit-3 button-afsnit-3-correct";
    }

    private playSoundCorrect(): void {
        this.audio.pause();
        this.audio.src = "/api/v1/Sound?name=VLV_Del_03_Rigtigt";
        this.audio.currentTime = 0;
        this.audio.play();
        this.audio.onended = this.activateNextBtn;
    }

    private playSoundWrong(): void {
        this.audio.pause();
        if (this.isExample01) {
            this.audio.src = "/api/v1/Sound?name=VLV_Del_03_faesd";
        } else {
            this.audio.src = "/api/v1/Sound?name=VLV_Del_03_sdaa";
        }
        this.audio.currentTime = 0;
        this.audio.play();
        this.audio.onended = this.activateNextBtn;
    }

    private activateNextBtn = (): void => {
        const btn = document.getElementById("nextButton_1") as HTMLButtonElement;
        btn.disabled = false;
    };

    private nextBtn01Handler = (): void => {
        Helpers.removeElementById("nextButton_1Container");
        Helpers.removeElementById("example_1");
        this.startExample02();
    };

    // Example 2
    private startExample02 = (): any => {
        Afsnit3Examples.example("sgø", "sdå", "sgå", "sgæ");
        this.displayNextBtn02();
        this.playSound("VLV_Del_03_Eksempel_03");
        this.isExample01 = false;
    };

    private displayNextBtn02 = (): void => {
        const btnContainer = document.createElement("div");
        btnContainer.className = "action-buttons mt-4 button-right";
        btnContainer.id = "nextButton_2Container";
        const btn = document.createElement("button");
        btn.className = "button button-primary button-long";
        btn.innerHTML = "\u25B6";
        btn.onclick = this.nextBtn02Handler;
        btn.disabled = true;
        btn.id = "nextButton_1";
        btnContainer.append(btn);
        $(".megatron-pagecontent").append(btnContainer);
    };

    private nextBtn02Handler = (): void => {
        Helpers.removeElementById("nextButton_2Container");
        Helpers.removeElementById("example_1");
        this.endExample();
    };

    // End Example
    private endExample = (): any => {
        Afsnit3Examples.setEndExample();
        this.playSoundEnd();
        this.displayNextBtn01();
    };

    private static setEndExample(): void {
        $(".megatron-pagecontent.megatron-ot-sammensat span strong").html("LYT!");
    }

    private playSoundEnd(): void {
        this.audio.pause();
        this.audio.src = "/api/v1/Sound?name=VLV_Del_03_Eksempel_04";
        this.audio.currentTime = 0;
        this.audio.play();
        this.audio.onended = Afsnit3Examples.onEndPlaySound4Handler;
    }

    private static onEndPlaySound4Handler(): void {
        Helpers.removeElementById("nextButton_1Container");
        Helpers.displayOriginalBtn();
    }
}

export const afsnit3Examples = new Afsnit3Examples();
