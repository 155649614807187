import { FC, useEffect, useState } from "react";
import { IBrugerkodeProps } from "../../../shared/interfaces/IBrugerkodeProps";
import { HovedopgaveResult } from "../../../shared/types/Result";
import { IDisplayProps } from "../ToggleResultDisplay";
import { IResultProps } from "../../../shared/interfaces/IResultProps";
import styles from "./DetailsOpgaveBesvarelseMatematik.module.scss";
import { IOpgavebesvarelserProps } from "../../../shared/interfaces/IOpgavebesvarelserProps";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas, faCheck, faXmark, faRadiation, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import DetailsSvarmulighed from "./DetailsSvarmulighed";
import AnswerLineText from "./AnswerLineText";
import OpgaveText from "./OpgaveText";

library.add(fas, faRadiation, faCheck, faXmark, faCircleExclamation);

type ResultRow = {
    name: string;
    correct: number;
    total: number;
    class: string;
    group: number;
};

type Props = IDisplayProps | IBrugerkodeProps | IResultProps | IOpgavebesvarelserProps | any;

const DetailsOpgaveBesvarelseMatematik: FC<Props> = (props) => {
    const { display } = props as IDisplayProps;
    const { brugerkode } = props as IBrugerkodeProps;
    const { result } = props as IResultProps;
    const { opgavebesvarelser } = props as IOpgavebesvarelserProps;
    const [resultHeader, setResultHeader] = useState<ResultRow[]>(new Array<ResultRow>());
    const { opgavesaet } = props as any;
    const opgaveLinjer = [] as JSX.Element[];

    useEffect(() => {
        let results: ResultRow[] = [];
        let group = 0;
        if (result?.afsnit?.length > 0) {
            console.log("result", result);
            result.afsnit.map(
                (a: { navn: string; correct: number; total: number; hovedopgaver: Array<HovedopgaveResult> }) => {
                    results = [
                        ...results,
                        {
                            name: a.navn,
                            correct: a.correct,
                            total: a.total,
                            class: "hovedopgave",
                            group: group,
                        },
                    ];
                    a.hovedopgaver.forEach((h) => {
                        results = [
                            ...results,
                            {
                                name: "Opgave " + h.overskrift,
                                correct: h.correct,
                                total: h.total,
                                class: "opgave displayNone",
                                group: group,
                            },
                        ];
                    });
                    group += 1;
                }
            );
            setResultHeader(results);
        }
    }, [brugerkode, result]);

    const ToggleDisplay = (className: string, group: string) => {
        if (className === "hovedopgave") {
            const opgaver = document.getElementsByClassName("opgavebesvarelseDetail");
            Array.from(opgaver).forEach((element) => {
                if (element.classList.contains(group)) {
                    element.classList.toggle("displayNone");
                }
            });

            const hovedopgaver = document.getElementsByClassName("opgavebesvarelse");
            Array.from(hovedopgaver).forEach((element) => {
                if (element.classList.contains(group)) {
                    element.classList.toggle("detail_open");
                    element.classList.toggle("detail_closed");
                }
            });
        }
    };

    let i = 1;
    resultHeader?.map((row) => {
        if (row.class === "hovedopgave") {
            opgaveLinjer.push(
                <tr className={row.class + " " + row.group.toString()}
                    key={Math.random()}
                    onClick={() => ToggleDisplay(row.class, row.group.toString())}>
                    <td tabIndex={0} onKeyDown={(e) => (e.key === 'Enter' || e.key === 'Space' || e.key === ' ') && ToggleDisplay(row.class, row.group.toString())}
                        className={
                            ("opgavebesvarelse detail detail_closed ") + row.group.toString()
                        }>
                        {row.name}
                    </td>
                </tr>
            );

            opgavesaet?.afsnit[row.group]?.opgaver?.forEach((hovedOpgave: any) => {
                const opgaveNummer = `Opgave ${i}: `;

                opgaveLinjer.push(
                    <tr className={"opgavebesvarelseDetail displayNone " + " " + row.group.toString()}
                        key={Math.random()}
                        >
                        <td className="">
                            {<h4 key={Math.random()} className="mt-0">{opgaveNummer}</h4>}
                            {<OpgaveText opgaver={hovedOpgave.opgaver} />}
                            {<DetailsSvarmulighed opgaver = {hovedOpgave.opgaver} opgavebesvarelser={opgavebesvarelser} />}
                            {<AnswerLineText opgaver={hovedOpgave.opgaver} opgavebesvarelser={opgavebesvarelser} />}
                        </td>
                    </tr>);
                i++;
            });
        }
    });

    if (!display) {
        return <></>;
    } else {
        return (
            <>
                {opgavebesvarelser != null &&
                    <>
                        <h2>Opgavebesvarelser</h2>
                        <table className={`${styles.table} table table--borderless table--compact table--responsive-headers d-print-table mb-7 w-percent-lg-70`}>
                            <thead>
                                <tr>
                                    <th className="w-percent-70">Spørgsmål</th>
                                </tr>
                            </thead>
                            <tbody>
                                {opgaveLinjer}
                            </tbody>
                        </table>
                    </>
                }
            </>
        );
    }
};

export default DetailsOpgaveBesvarelseMatematik;