import {Dispatch, FunctionComponent, SetStateAction, useCallback, useEffect, useState} from "react";
import moment from "moment";
import { Paper } from "@material-ui/core";
import { localization } from "../../common/table/shared/Localization";
import { tableIcons } from "../../common/table/shared/Icons";
import MaterialTable from "@material-table/core";
import $ from "jquery";
import { ModalBackdrop } from "../../common/ModalBackdrop";
import styles from "./SupporterSupportersTable.module.scss";
import {options} from "./Options";
import {RuleDetails} from "../../../shared/types/RuleDetails";
import SupporterRemoveSupporterModal from "../SupporterRemoveSupporterModal/SupporterRemoveSupporterModal";

const SupportSupportersTable: FunctionComponent<{
    rules: RuleDetails[];
    setForceUpdate: Dispatch<SetStateAction<number>>;
}> = ({ rules, setForceUpdate }) => {
    const columnsDef = useCallback(() => {
        const removeSupporter = (rowData: RuleDetails) => {
            return (
                <>
                    <a
                        className={styles.brugerkodeLink}
                        onClick={() => {
                            $(`#modal-remove-supporter-${rowData.id}`).toggle();
                            $("#modal-backdrop").toggle();
                        }}>
                        Fjern
                    </a>
                    <SupporterRemoveSupporterModal rule={rowData} setForceUpdate={setForceUpdate} />
                </>
            );
        };

        return [
            {
                title: "Brugernavn",
                field: "brugernavn",
                type: "string" as const,
            },
            {
                title: "Navn",
                field: "name",
                type: "string" as const,
            },
            {
                title: "Tilføjet af",
                field: "createdBy",
                type: "string" as const,
                render: (rowData: RuleDetails) => rowData?.createdBy != undefined ? rowData.createdBy : "-",
            },
            {
                title: "Oprettet d.",
                field: "createdAt",
                type: "date" as const,
                render: (rowData: any) =>
                    rowData?.createdAt != undefined
                        ? moment(new Date(rowData.createdAt)).format("DD-MM-YYYY")
                        : "-",
                customFilterAndSearch: (term: string, rowData: { createdAt: Date }) => {
                    const formated = moment(new Date(rowData.createdAt)).format("DD-MM-YYYY");
                    const formatedArr = formated.split("-");
                    return (formatedArr[0] + "-" + formatedArr[1] + "-" + formatedArr[2]).indexOf(term) != -1;
                },
            },
            {
                title: "Fjern",
                field: "internal_action",
                type: "string" as const,
                sorting: false,
                render: (rowData: any) => removeSupporter(rowData),
                customFilterAndSearch: (term: string ) => {
                    return "Fjern".indexOf(term) != -1;
                }
            }
        ];
    }, [setForceUpdate]);
    const [columns, setColumns] = useState(columnsDef());

    useEffect(() => {
        setColumns(columnsDef());
    }, [rules, columnsDef]);

    return (
        <div>
            <MaterialTable
                icons={tableIcons}
                title=""
                columns={columns}
                data={rules}
                options={options}
                components={{
                    Container: (props) => <Paper {...props} elevation={0} variant="outlined" />,
                }}
                localization={localization}>
            </MaterialTable>
            <ModalBackdrop />
        </div>
    );
};

export default SupportSupportersTable;
