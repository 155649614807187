import { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";
import Page from "../../../components/common/Page/Page";
import styles from "./NotFound.module.scss";


const NotFound: FunctionComponent = () => {
    const history = useHistory();

    return (
        <Page>
            <div className="container page-container">
                <div className="alert alert-info">
                    <div className="alert-body">
                        <p className="alert-heading">Siden findes ikke</p>
                        <p className="alert-text">Du har har forsøgt at tilgå en side, der ikke findes. <br />
                            <a onClick={() => history.push("/")}  className={styles.link} >
                                Tryk her for at gå tilbage til forsiden.
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </Page>
    );
};

export default NotFound;
