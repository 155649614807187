import {TestNames} from "../enums/TestNames";

export class IsTest {
    public static isA = (testName: string): boolean => {
        if (testName === TestNames.A) {
            return true;
        }

        return false;
    };

    public static isB = (testName: string): boolean => {
        if (testName === TestNames.B) {
            return true;
        }

        return false;
    };

    public static isDemo = (testName: string): boolean => {
        if (testName === TestNames.DEMO) {
            return true;
        }

        return false;
    };

    public static isFGU = (testName: string): boolean => {
        if (testName === TestNames.FGU) {
            return true;
        }

        return false;
    };

    public static isFVU = (testName: string): boolean => {
        if (testName === TestNames.FVU) {
            return true;
        }

        return false;
    };

    public static isFGUL = (testName: string): boolean => {
        if (testName === TestNames.FGUL) {
            return true;
        }

        return false;
    };

    public static isVLV = (testName: string): boolean => {
        if (testName === TestNames.VLV) {
            return true;
        }

        return false;
    };

    public static isLaesetest = (testName: string | undefined): boolean => {
        if (testName === TestNames.VLV ||
            testName === TestNames.FGUL) {
            return true;
        }

        return false;
    };

    public static isMatematiktest = (testName: string | undefined): boolean => {
        if (testName === TestNames.FGU ||
            testName === TestNames.FVU ||
            testName === TestNames.DEMO ||
            testName === TestNames.A ||
            testName === TestNames.B) {
            return true;
        }

        return false;
    };

    public static getTestName = (testName: string | undefined): string => {
        if (testName === undefined) {
            return "";
        }
        if (this.isDemo(testName))
        {
            return "DEMO";
        } else if (this.isA(testName))
        {
            return "A";
        } else if (this.isB(testName))
        {
            return "B";
        } else if (this.isFGU(testName))
        {
            return "FGUMAT";
        } else if (this.isFVU(testName))
        {
            return "VMAT";
        } else if (this.isFGUL(testName))
        {
            return "FGUL";
        } else if (this.isVLV(testName))
        {
            return "VLV";
        }

        return "";
    };
}
