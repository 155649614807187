import Keycloak from 'keycloak-js';


const keycloak = () => {
    switch (window.location.hostname) {
        case "testafvikleren.dk": {
            return Keycloak('/../keycloak.json');
        }
        default: {
            return Keycloak('/../keycloak.development.json');
        }
    }
};

export default keycloak();