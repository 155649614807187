import $ from "jquery";
import { Helpers} from "./helpers";

export class Afsnit1Examples {
    private audio = new Audio("/api/v1/Sound?name=VLV_Del_01_Eksempel_01");
    private example3Answered = false;
    private example4Answered = false;
    private timeout_example_01 = 30000;
    private timeout_example_02 = 16000;

    public start(): void {
        Helpers.hideOriginalBtn();
        Helpers.setExampleHeadline();
        Afsnit1Examples.example01();
        this.displayNextBtn01();
        this.playSound01();
        setTimeout(() => {
            this.setCorrectAnswer("correct_1_1");
        }, this.timeout_example_01);
    }

    private static example01(): void {
        const example = document.createElement("span");
        example.className = "example-afsnit-1";
        example.id = "example_1";
        example.innerHTML = `<span>Henrik arbejder som postbud. 
                                    <span class='options-afsnit-1'>
                                        <span class="answer">Det</span>
                                        <span id="correct_1_1" class="answer">Han</span>
                                        <span class="answer">De</span>
                                        <span class="answer">Hun</span>
                                    </span>  bor i en lille by uden for Roskilde.
                             </span>`;

        $(".megatron-pagecontent.megatron-ot-sammensat span.megatron-opgave-sammensat-tekst").append(example);
    }

    private setCorrectAnswer = (correctElementId: string): void => {
        const correctElement = document.getElementById(correctElementId);
        if (correctElement != null) {
            correctElement.className = "answer correct-1";
        }
    };

    private playSound01(): void {
        this.audio.pause();
        this.audio.src = "/api/v1/Sound?name=VLV_Del_01_Eksempel_01";
        this.audio.currentTime = 0;
        this.audio.play();
        this.audio.onended = this.activateNextBtn01;
    }

    private activateNextBtn01 = (): void => {
        const btn = document.getElementById("nextButton_1") as HTMLButtonElement;
        btn.disabled = false;
    };


    private displayNextBtn01 = (): void => {
        const btnContainer = document.createElement("div");
        btnContainer.className = "action-buttons mt-4 button-right";
        btnContainer.id = "nextButton_1Container";
        const btn = document.createElement("button");
        btn.className = "button button-primary button-long";
        btn.innerHTML = "\u25B6";
        btn.onclick = this.nextBtn01Handler;
        btn.disabled = true;
        btn.id = "nextButton_1";
        btnContainer.append(btn);
        $(".megatron-pagecontent").append(btnContainer);
    };

    private nextBtn01Handler = (): void => {
        Helpers.removeElementById("nextButton_1Container");
        this.startExample02();
    };

    // Example 2
    private startExample02 = (): any => {
        Afsnit1Examples.example02();
        this.displayNextBtn02();
        this.playSound02();
        setTimeout(() => {
            this.setCorrectAnswer("correct_1_2");
        }, this.timeout_example_02);
    };

    private static example02(): void {
        const example = document.createElement("span");
        example.className = "example-afsnit-1";
        example.id = "example_2";
        example.innerHTML = ` <span>Han plejer at stå tidligt op om morgenen, 
                                <span class='options-afsnit-1'>
                                    <span class="answer">mens</span>
                                    <span class="answer">skønt</span>
                                    <span id="correct_1_2" class='answer'>fordi</span>
                                    <span class="answer">efter</span>
                                </span>  han skal være på posthuset i Roskilde kl. 6.</span>`;

        $(".megatron-pagecontent.megatron-ot-sammensat span.megatron-opgave-sammensat-tekst").append(example);
    }

    private displayNextBtn02 = (): void => {
        const btnContainer = document.createElement("div");
        btnContainer.className = "action-buttons mt-4 button-right";
        btnContainer.id = "nextButton_2Container";
        const btn = document.createElement("button");
        btn.className = "button button-primary button-long";
        btn.innerHTML = "\u25B6";
        btn.onclick = this.nextBtn02Handler;
        btn.disabled = true;
        btn.id = "nextButton_2";
        btnContainer.append(btn);
        $(".megatron-pagecontent").append(btnContainer);
    };

    private playSound02(): void {
        this.audio.pause();
        this.audio.src = "/api/v1/Sound?name=VLV_Del_01_Eksempel_02";
        this.audio.currentTime = 0;
        this.audio.play();
        this.audio.onended = this.activateNextBtn02;
    }

    private activateNextBtn02 = (): void => {
        const btn = document.getElementById("nextButton_2") as HTMLButtonElement;
        btn.disabled = false;
    };

    private nextBtn02Handler = (): void => {
        Helpers.removeElementById("nextButton_2Container");
        this.startExample03();
    };

    // Example 3 and 4
    private startExample03 = (): any => {
        Afsnit1Examples.example03();
        Afsnit1Examples.example04();
        this.displayNextBtn03();
        this.playSound03();
    };

    private static example03(): void {
        const example = document.createElement("span");
        example.className = "example-afsnit-1";
        example.id = "example_3";
        example.innerHTML = `Men denne morgen har 
                                <span class='options-afsnit-1'>
                                    <span id="example_3_1" class="answer">det</span>
                                    <span id="example_3_2" class="answer">han</span>
                                    <span id="example_3_3" class="answer">hun</span>
                                    <span id="example_3_4" class="answer">den</span>
                                </span>  høj feber, hoster og kan ikke stå på sine ben.`;

        $(".megatron-pagecontent.megatron-ot-sammensat span.megatron-opgave-sammensat-tekst").append(example);
    }

    private static example04(): void {
        const example = document.createElement("span");
        example.className = "example-afsnit-1";
        example.id = "example_4";
        example.innerHTML = `Henrik føler sig meget 
                                <span class='options-afsnit-1'>
                                    <span id="example_4_1" class="answer">vred</span>
                                    <span id="example_4_2" class="answer">sur</span>
                                    <span id="example_4_3" class="answer">forsinket</span>
                                    <span id="example_4_4" class="answer">syg</span>
                                </span>. Derfor bliver han hjemme.`;

        $(".megatron-pagecontent.megatron-ot-sammensat span.megatron-opgave-sammensat-tekst").append(example);
    }

    private displayNextBtn03 = (): void => {
        const btnContainer = document.createElement("div");
        btnContainer.className = "action-buttons mt-4 button-right";
        btnContainer.id = "nextButton_3Container";
        const btn = document.createElement("button");
        btn.className = "button button-primary button-long";
        btn.innerHTML = "\u25B6";
        btn.onclick = this.nextBtn_3Handler;
        btn.disabled = true;
        btn.id = "nextButton_3";
        btnContainer.append(btn);
        $(".megatron-pagecontent").append(btnContainer);
    };

    private playSound03(): void {
        this.audio.pause();
        this.audio.src = "/api/v1/Sound?name=VLV_Del_01_Eksempel_03";
        this.audio.currentTime = 0;
        this.audio.play();
        this.audio.onended = this.addEventListeners;
    }

    private addEventListeners = (): void => {
        this.addEventListenerForExample3();
        this.addEventListenerForExample4();
    };

    private addEventListenerForExample3 = (): void => {
        const example_3_1 = document.getElementById("example_3_1");
        const example_3_2 = document.getElementById("example_3_2");
        const example_3_3 = document.getElementById("example_3_3");
        const example_3_4 = document.getElementById("example_3_4");
        if (example_3_1 != null &&
            example_3_2 != null &&
            example_3_3 != null &&
            example_3_4 != null ) {
            example_3_1.onclick = this.example3_1ClickHandler;
            example_3_2.onclick = this.example3_2ClickHandler;
            example_3_3.onclick = this.example3_3ClickHandler;
            example_3_4.onclick = this.example3_4ClickHandler;
        }
    };

    private addEventListenerForExample4 = (): void => {
        const example_4_1 = document.getElementById("example_4_1");
        const example_4_2 = document.getElementById("example_4_2");
        const example_4_3 = document.getElementById("example_4_3");
        const example_4_4 = document.getElementById("example_4_4");
        if (example_4_1 != null &&
            example_4_2 != null &&
            example_4_3 != null &&
            example_4_4 != null ) {
            example_4_1.onclick = this.example4_1ClickHandler;
            example_4_2.onclick = this.example4_2ClickHandler;
            example_4_3.onclick = this.example4_3ClickHandler;
            example_4_4.onclick = this.example4_4ClickHandler;
        }
    };


    private example3_1ClickHandler = (): void => {
        this.resetExample3();
        $("#example_3_1").addClass("correct-1");
    };

    private example3_2ClickHandler = (): void => {
        this.resetExample3();
        $("#example_3_2").addClass("correct-1");
    };

    private example3_3ClickHandler = (): void => {
        this.resetExample3();
        $("#example_3_3").addClass("correct-1");
    };

    private example3_4ClickHandler = (): void => {
        this.resetExample3();
        $("#example_3_4").addClass("correct-1");
    };

    private resetExample3 = (): void => {
        $("#example_3_1").removeClass("correct-1");
        $("#example_3_2").removeClass("correct-1");
        $("#example_3_3").removeClass("correct-1");
        $("#example_3_4").removeClass("correct-1");
        this.example3Answered = true;

        if (this.example3Answered && this.example4Answered) {
            this.activateNextBtn03();
        }
    };

    private activateNextBtn03 = (): void => {
        const btn = document.getElementById("nextButton_3") as HTMLButtonElement;
        btn.disabled = false;
    };

    private example4_1ClickHandler = (): void => {
        this.resetExample4();
        $("#example_4_1").addClass("correct-1");
    };

    private example4_2ClickHandler = (): void => {
        this.resetExample4();
        $("#example_4_2").addClass("correct-1");
    };

    private example4_3ClickHandler = (): void => {
        this.resetExample4();
        $("#example_4_3").addClass("correct-1");
    };

    private example4_4ClickHandler = (): void => {
        this.resetExample4();
        $("#example_4_4").addClass("correct-1");
    };

    private resetExample4 = (): void => {
        $("#example_4_1").removeClass("correct-1");
        $("#example_4_2").removeClass("correct-1");
        $("#example_4_3").removeClass("correct-1");
        $("#example_4_4").removeClass("correct-1");
        this.example4Answered = true;
        if (this.example3Answered && this.example4Answered) {
            this.activateNextBtn03();
        }
    };

    private nextBtn_3Handler = (): void => {
        Helpers.removeElementById("nextButton_3Container");
        Helpers.removeElementById("exampleHeadline");
        Helpers.removeElementById("example_1");
        Helpers.removeElementById("example_2");
        Helpers.removeElementById("example_3");
        Helpers.removeElementById("example_4");
        this.endExample();
    };

    // End Example
    private endExample = (): any => {
        Afsnit1Examples.setEndExample();
        this.displayNextBtn01();
        this.playSound4();
    };

    private static setEndExample(): void {
        $(".megatron-pagecontent.megatron-ot-sammensat span strong").html("LYT!");
    }

    private playSound4(): void {
        this.audio.pause();
        this.audio.src = "/api/v1/Sound?name=VLV_Del_01_Eksempel_04";
        this.audio.currentTime = 0;
        this.audio.play();
        this.audio.onended = Afsnit1Examples.onEndPlaySound4Handler;
    }

    private static onEndPlaySound4Handler(): void {
        Helpers.removeElementById("nextButton_1Container");
        Helpers.displayOriginalBtn();
    }
}

export const afsnit1Examples = new Afsnit1Examples();
