import { FC, useEffect, useState } from "react";
import { IBrugerkodeProps } from "../../../shared/interfaces/IBrugerkodeProps";
import { HovedopgaveResult } from "../../../shared/types/Result";
import { IDisplayProps } from "../ToggleResultDisplay";
import {IResultProps} from "../../../shared/interfaces/IResultProps";
import styles from "./DetailsLaesetest.module.scss";
import {ITesttagningProps} from "../../../shared/interfaces/ITesttagningProps";
import {IsTest} from "../../../shared/utils/IsTest";

type ResultRow = {
    name: string;
    correct: number;
    wrong: number;
    skipped: number;
    unanswererd: number;
    class: string;
    group: number;
};

type Props = IDisplayProps | IBrugerkodeProps | IResultProps |ITesttagningProps;

const DetailsLaesetest: FC<Props> = (props) => {
    const { display } = props as IDisplayProps;
    const { brugerkode } = props as IBrugerkodeProps;
    const { result } = props as IResultProps;
    const { testtagning } = props as ITesttagningProps;
    const [resultHeader, setResultHeader] = useState<ResultRow[]>(new Array<ResultRow>());

    useEffect(() => {
        let results: ResultRow[] = [];
        let group = 0;
        if (result?.afsnit?.length > 0) {
            result.afsnit.map(
                (a: { navn: string; correct: number; wrong: number; skipped: number; unanswered: number; hovedopgaver: Array<HovedopgaveResult> }) => {
                    results = [
                        ...results,
                        {
                            name: a.navn,
                            correct: a.correct,
                            wrong: a.wrong,
                            skipped: a.skipped,
                            unanswererd: a.unanswered,
                            class: "hovedopgave",
                            group: group,
                        },
                    ];
                    group += 1;
                }
            );
            setResultHeader(results);
        }
    }, [brugerkode, result]);

    const renderResult = (row: ResultRow): string => {
        if (row.group === 0) {
            const sluttidspunkt = new Date(testtagning.del1Sluttidspunkt);
            const starttidspunkt = new Date(testtagning.del1Starttidspunkt);
            const totalTime = sluttidspunkt.getTime() - starttidspunkt.getTime();
            let time = (row.correct - (row.wrong / 3)) * 600000 / totalTime;

            if (time <= 0)
            {
                time = 0;

                return time.toString();
            } else {
                return time.toFixed(1).replaceAll(".", ",").toString();
            }
        }

        return row.correct.toString();
    };

    const renderSkipped = (row: ResultRow): number => {
        if (row.group === 0 || row.group === 1) {
            return row.skipped + row.unanswererd;
        } else if (row.group === 2) {
            return row.skipped;
        }

        return Number.NaN;
    };

    const tableBody = () => {
        return (
            <tbody>
                <tr className={styles.samletResultat}>
                    <th>{result?.testNavn}</th>
                    <td>Rigtige</td>
                    <td>Forkerte</td>
                    <td>Oversprungne</td>
                    { IsTest.isFGUL(result.testNavn) &&
                        <td>Resultat</td>
                    }
                </tr>
                {resultHeader.map((row) => (
                    <tr key={Math.random()}>
                        <td className={(row.class === "hovedopgave" ? "detail-laesetest detail_closed " : " ") + row.group.toString()}>
                            {row.name + "?"}
                        </td>
                        <td className="w-percent-md-15">
                            {row.correct}{" "}
                        </td>
                        <td className="w-percent-md-15">
                            {row.wrong}{" "}
                        </td>
                        <td className="w-percent-md-15">
                            {renderSkipped(row)}{" "}
                        </td>
                        { IsTest.isFGUL(result.testNavn) &&
                            <td className="w-percent-md-15">
                                {renderResult(row)}{" "}
                            </td>
                        }
                    </tr>
                ))}
            </tbody>
        );
    };

    if (!display) {
        return <></>;
    } else {
        return (
            <>
                <h2>Resultatoversigt</h2>
                <table className={`${styles.table} table table--borderless table--compact table--responsive-headers d-print-table mb-7 w-percent-lg-70`}>
                    <thead>
                        <tr>
                            <th className="w-percent-70"/>
                            <th className={styles.result}/>
                            <th className={styles.result}/>
                            <th className={styles.result}/>
                            { IsTest.isFGUL(result.testNavn) &&
                                <th className={styles.result}/>
                            }
                        </tr>
                    </thead>
                    {tableBody()}
                </table>
            </>
        );
    }
};

export default DetailsLaesetest;
