import {exportCSV, exportPdf, pdfLabel, csvLabel} from "../shared/utils/exportData";
import {
    search,
    searchFieldAlignment,
    pageSize,
    pageSizeOptions,
    headerStyle,
    searchFieldStyle,
    selection,
    emptyRowsWhenPaging
} from "../shared/Options";
import {Options} from "@material-table/core";

const exportMenu: any = [{
    label: pdfLabel,
    exportFunc: (cols: any[], datas: any[]): void => {
        exportPdf(cols, datas, "Testresultater");
    }
    },
    {
        label: csvLabel,
        exportFunc: (cols: any[], datas: any[]): void => {
            exportCSV(cols, datas, "Testresultater");
        }
    }];

export const options: Options<any> = {
    selection,
    search,
    searchFieldAlignment,
    pageSize,
    pageSizeOptions,
    headerStyle,
    searchFieldStyle,
    exportMenu,
    emptyRowsWhenPaging
};

