import {AxiosResponse} from "axios";
import BaseApi from "./BaseApi";
import {ResponseType} from "../shared/types/ResponseType";
import {AuthenticatedUserDetails} from "../shared/types/AuthenticatedUserDetails";

export const GetAuthenticatedUserByBrugernavnApi = async (brugernavn: string):  Promise<ResponseType<AuthenticatedUserDetails>> => {
    try {
        const response = await BaseApi.get<Request, AxiosResponse<any, any>>("AuthenticatedUser/GetByBrugernavn?brugernavn=" + brugernavn);
        return {
            data: response.data.data as AuthenticatedUserDetails,
            succeeded: true,
            Message: response.data.message,
            errors: [],
        };
    } catch (error: any) {
        return {
            data: undefined,
            succeeded: false,
            Message: "",
            errors: [error],
        };
    }
};

export const GetAuthenticatedUserBySupportsagIdApi = async (supportsagId: string):  Promise<ResponseType<AuthenticatedUserDetails>> => {
    try {
        const response = await BaseApi.get<Request, AxiosResponse<any, any>>("AuthenticatedUser/GetBySupportsagId?supportsagId=" + supportsagId);
        return {
            data: response.data.data as AuthenticatedUserDetails,
            succeeded: true,
            Message: response.data.message,
            errors: [],
        };
    } catch (error: any) {
        return {
            data: undefined,
            succeeded: false,
            Message: "",
            errors: [error],
        };
    }
};

export const GetAuthenticatedUserApi = async ():  Promise<ResponseType<AuthenticatedUserDetails>> => {
    try {
        const response = await BaseApi.get<Request, AxiosResponse<any, any>>("AuthenticatedUser");
        return {
            data: response.data.data as AuthenticatedUserDetails,
            succeeded: true,
            Message: response.data.message,
            errors: [],
        };
    } catch (error: any) {
        return {
            data: undefined,
            succeeded: false,
            Message: "",
            errors: [error],
        };
    }
};

export const CreateBrugerkoderApi = async (
    count: number,
    opgavesaetId: string,
    holdId: string | undefined
): Promise<ResponseType<number>> => {
    try {
        const data = holdId ? { count, opgavesaetId, holdId } : { count, opgavesaetId };
        const response = await BaseApi.post<Request, AxiosResponse<any, any>>("AuthenticatedUser/CreateBrugerkoder", data);
        return {
            data: response.data.data,
            succeeded: true,
            Message: response.data.message,
            errors: [],
        };
    } catch (error: any) {
        return {
            data: undefined,
            succeeded: false,
            Message: "",
            errors: [error.response.data.Message],
        };
    }
};

export const GetRole = async (): Promise<any> => {
    return await BaseApi.get<Request>("AuthenticatedUser/GetRole");
};

