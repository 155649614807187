import { Dispatch, FunctionComponent, SetStateAction, useState } from "react";
import $ from "jquery";
import { ModalBackdrop } from "../../common/ModalBackdrop";
import { CreateHoldApi } from "../../../apiService/HoldApi";
import styles from "./VejlederCreateHoldModal.module.scss";
import {toastManager} from "../../common/Toast/ToastManager/ToastManager";
import updateTable from "../../shared/utils/updateTable";

const VejlederCreateHoldModal: FunctionComponent<{
    setForceUpdate: Dispatch<SetStateAction<number>>;
}> = ({ setForceUpdate }) => {
    const [nameToCreate, setNameToCreate] = useState<string>("");

    const handleSubmit = async (event: { preventDefault: () => void }) => {
        event.preventDefault();
        const response = await CreateHoldApi(nameToCreate);
        if (response.succeeded) {
            toastManager.success(`Hold ${nameToCreate} oprettet`);
            setForceUpdate(updateTable());
        } else {
            toastManager.error(response.errors.toString());
        }

        toggleOpretHoldModal();
    };

    const toggleOpretHoldModal = () => {
        setNameToCreate("");

        $("#modal-hold").toggle();
        $("#modal-backdrop").toggle();
    };

    return (
        <>
            <div className="fds-modal" id="modal-hold" aria-hidden="true" role="dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="modal-title">Opret hold</h2>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label className="form-label">Hvad skal navnet på holdet været?</label>
                            <input
                                type="text"
                                value={nameToCreate}
                                className="item form-input"
                                name="count"
                                onChange={(e) => {
                                    setNameToCreate(e.target.value);
                                }}>
                            </input>
                        </div>
                        <div className={styles.submitCancel}>
                            <div className={`action-buttons mt-4 ${styles.submitButton}`}>
                                <input
                                    type="submit"
                                    className="button button-primary button-long"
                                    value="Opret hold"
                                    onClick={handleSubmit}
                                    disabled={nameToCreate == ""}>
                                </input>
                            </div>
                            <div className="action-buttons mt-4">
                                <button
                                    className="button button-secondary button-long"
                                    onClick={toggleOpretHoldModal}>
                                    Annullér
                                </button>
                            </div>
                        </div>
                        <div className="modal-footer"/>
                    </div>
                </div>
            </div>
            <button className="button button-secondary" onClick={toggleOpretHoldModal}>
                Opret hold
            </button>
            <ModalBackdrop />
        </>
    );
};

export default VejlederCreateHoldModal;
