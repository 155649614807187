import { Dispatch, FunctionComponent, SetStateAction, useState } from "react";
import $ from "jquery";
import { ModalBackdrop } from "../../common/ModalBackdrop";
import { CreateSupportsagApi } from "../../../apiService/SupportsagApi";
import { TesttagningDetails } from "../../../shared/types/TesttagningDetails";
import styles from "./SupporterRequestUserDataModal.module.scss";
import {toastManager} from "../../common/Toast/ToastManager/ToastManager";
import SupporterRequestUserData from "./SupporterRequestUserData";
import { HoldDetails } from "../../../shared/types/HoldDetails";
import { AuthenticatedUserDetails } from "../../../shared/types/AuthenticatedUserDetails";
import { SessionStorage } from "../../../shared/enums/Storage";

const SupporterRequestUserDataModal: FunctionComponent<{
    vejlederBrugernavn: string;
    setTesttagningerResultat: Dispatch<SetStateAction<TesttagningDetails[]>>;
    setTesttagningerBrugerkoder: Dispatch<SetStateAction<TesttagningDetails[]>>;
    setHold: Dispatch<SetStateAction<HoldDetails[]>>;
    setVejleder:Dispatch<SetStateAction<AuthenticatedUserDetails | undefined>>;
}> = ({ vejlederBrugernavn, setTesttagningerResultat, setTesttagningerBrugerkoder, setHold, setVejleder }) => {
    const [begrundelse, setBegrundelse] = useState<string>("");

    const handleSubmit = async (event: { preventDefault: () => void }) => {
        event.preventDefault();
        CreateSupportsagApi(begrundelse, vejlederBrugernavn).then((supportsagResponse) => {
            if (supportsagResponse.succeeded && supportsagResponse.data) {
                window.sessionStorage.setItem(SessionStorage.SUPPORTSAG, supportsagResponse.data);
                SupporterRequestUserData(supportsagResponse.data, setTesttagningerResultat, setTesttagningerBrugerkoder, setHold, setVejleder);
            } else {
                toastManager.error(supportsagResponse.errors.toString());
            }

            toggleAccessUserData();
        });
    };

    const toggleAccessUserData = () => {
        setBegrundelse("");
        $("#modal-access-data").toggle();
        $("#modal-backdrop").toggle();
    };

    return (
        <>
            <div className="fds-modal" id="modal-access-data" aria-hidden="true" role="dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="modal-title">Adgang til brugerdata</h2>
                    </div>
                    <div className="modal-body">
                        <span>Angiv informationer om supportsagen eller anden begrundelse for adgangen.</span>
                        <div className="form-group">
                            <table className={styles.accessUserDataTable}>
                                <tbody>
                                    <tr>
                                        <th className={styles.begrundelseLabel}>Begrundelse:</th>
                                        <td>
                                            <textarea
                                                className={`item form-input ${styles.begrundelseInput}`}
                                                name="begrundelse"
                                                value={begrundelse}
                                                onChange={(e) => {
                                                    setBegrundelse(e.target.value);
                                                }}>
                                            </textarea>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className={styles.submitCancel}>
                            <div className={`action-buttons mt-4 ${styles.submitButton}`}>
                                <input
                                    type="submit"
                                    className="button button-primary button-long"
                                    value="Opret"
                                    onClick={handleSubmit}
                                    disabled={begrundelse == ""}>
                                </input>
                            </div>
                            <div className="action-buttons mt-4">
                                <button
                                    className="button button-secondary button-long"
                                    onClick={toggleAccessUserData}>
                                    Annullér
                                </button>
                            </div>
                        </div>
                        <div className="modal-footer" />
                    </div>
                </div>
            </div>

            <button
                className="button button-secondary"
                onClick={toggleAccessUserData}
                disabled={vejlederBrugernavn == ""}>
                Fremsøg vejleder
            </button>
            <ModalBackdrop />
        </>
    );
};

export default SupporterRequestUserDataModal;
