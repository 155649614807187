import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { OpgavesaetProvider } from "./components/OpgavesaetContext";
import Elev from "./pages/elev/Elev";
import Frontpage from "./pages/Frontpage/Frontpage";
import Opgavesaet from "./pages/elev/Opgavesaet/Opgavesaet";
import Resultater from "./pages/elev/Resultater";
import VejlederElevResultat from "./pages/vejleder/VejlederElevResultat";
import VejlederBrugerkoder from "./pages/vejleder/VejlederBrugerkoder";
import VejlederResultat from "./pages/vejleder/VejlederResultat/VejlederResultat";
import AuthRedirect from "./pages/shared/AuthRedirect";
import VejlederHold from "./pages/vejleder/VejlederHold";
import { useKeycloak } from "@react-keycloak/web";
import { PrivateRoute } from "./components/auth/PrivateRoute";
import Login from "./components/auth/Login";
import SupporterSearchVejleder from "./pages/supporter/SupporterSearchVejleder/SupporterSearchVejleder";
import VejlederDashboard from "./pages/vejleder/VejlederDashboard";
import SupporterDashboard from "./pages/supporter/SupporterDashboard";
import SupporterPNummer from "./pages/supporter/SupporterPNummer/SupporterPNummer";
import NoAccess from "./pages/shared/NoAccess";
import NotFound from "./pages/shared/NotFound/NotFound";
import SupporterManageSupporters from "./pages/supporter/SupporterManageSupporters/SupporterManageSupporters";
import SupporterCVR from "./pages/supporter/SupporterCVR/SupporterCVR";
import SupporterRID from "./pages/supporter/SupporterRID/SupporterRID";
import Persondatapolitik from "./pages/shared/Persondatapolitik";
import Cookiepolitik from "./pages/shared/Cookiepolitik";
import Personoplysninger from "./pages/shared/Personoplysninger";

const Routing = (): JSX.Element => {
    const { initialized } = useKeycloak();

    if (!initialized) {
        return <div>Loading...</div>;
    }

    return (
        <Router>
            <OpgavesaetProvider>
                <Switch>
                    {/*Shared*/}
                    <Route exact path={"/"} component={Frontpage} />
                    <Route exact path={"/auth/login"} component={Login} />
                    <Route exact path={"/auth/noaccess"} component={NoAccess} />
                    <Route exact path={"/cookies"} component={Cookiepolitik} />
                    <Route exact path={"/persondatapolitik"} component={Persondatapolitik} />
                    <Route exact path={"/personoplysninger"} component={Personoplysninger} />

                    <PrivateRoute exact path={"/auth"} component={AuthRedirect} />

                    {/*Vejleder*/}
                    <PrivateRoute exact path={"/vejleder/resultat"} component={VejlederResultat} />
                    <PrivateRoute exact path={"/vejleder/elev/resultat"} component={VejlederElevResultat} />
                    <PrivateRoute exact path={"/vejleder/brugerkoder"} component={VejlederBrugerkoder} />
                    <PrivateRoute exact path={"/vejleder/hold"} component={VejlederHold} />
                    <PrivateRoute exact path={"/vejleder"} component={VejlederDashboard} />

                    {/*Supporter*/}
                    <PrivateRoute exact path={"/supporter/searchVejleder"} component={SupporterSearchVejleder} />
                    <PrivateRoute exact path={"/supporter/pnummer"} component={SupporterPNummer} />
                    <PrivateRoute exact path={"/supporter/cvr"} component={SupporterCVR} />
                    <PrivateRoute exact path={"/supporter/rid"} component={SupporterRID} />
                    <PrivateRoute exact path={"/supporter/manageSupporters"} component={SupporterManageSupporters} />
                    <PrivateRoute exact path={"/supporter"} component={SupporterDashboard} />

                    {/*Elev*/}
                    <Route exact path={"/resultat"} component={Resultater} />
                    <Route path={"/test"} component={Opgavesaet} />
                    <Route exact path={"/register"} component={Elev} />

                    {/*Shared*/}
                    <Route path={"*"} component={NotFound} />
                </Switch>
            </OpgavesaetProvider>
        </Router>
    );
};

export default Routing;
