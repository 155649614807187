import BaseApi from "./BaseApi";
import { ResponseType } from "../shared/types/ResponseType";
import { AxiosResponse } from "axios";
import { TesttagningDetails } from "../shared/types/TesttagningDetails";

export const GetTesttagningByBrugerkode = async (brugerkode: string, isFrontpageRequest: boolean): Promise<ResponseType<TesttagningDetails>> => {
    try {
        const response = await BaseApi.get<Request, AxiosResponse<any, any>>("Testtagning/GetByBrugerkode?brugerkode=" + brugerkode + "&isFrontpageRequest=" + isFrontpageRequest);
        return {
            data: response.data.data,
            succeeded: true,
            Message: response.data.message,
            errors: [],
        };
    } catch (error: any) {
        return {
            data: undefined,
            succeeded: false,
            Message: "",
            errors: [error],
        };
    }
};

export const GetTesttagningDetailsByBrugerkode = async (brugerkode: string): Promise<ResponseType<TesttagningDetails>> => {
    try {
        const response = await BaseApi.get<Request, AxiosResponse<any, any>>("Testtagning/GetDetailsByBrugerkode?brugerkode=" + brugerkode);
        return {
            data: response.data.data,
            succeeded: true,
            Message: response.data.message,
            errors: [],
        };
    } catch (error: any) {
        return {
            data: undefined,
            succeeded: false,
            Message: "",
            errors: [error],
        };
    }
};

export const GetTesttagningerAsAuthenticatedUserApi = async (inUse: boolean): Promise<any> => {
    return await BaseApi.get<Request>("Testtagning/GetByAuthenticatedUser?inUse=" + inUse);
};

export const GetTesttagningerBySupportsagIdApi = async (supportsagId: string): Promise<any> => {
    return await BaseApi.get<Request>("Testtagning/GetBySupportsagId?supportsagId=" + supportsagId);
};

export const UpdateTesttagningOpgavesaetAsElevApi = async (brugerkode: string): Promise<any> => {
    return await BaseApi.put<Request>("Testtagning/UpdateOpgavesaetByBrugerkodeAsElev", { brugerkode });
};

export const UpdateTesttagningByBrugerkodeApi = async (brugerkode: string | undefined | null, tekstlaesning: boolean | undefined, ordforraad: boolean | undefined, ordlaesning: boolean | undefined): Promise<any> => {
    return await BaseApi.put<Request>("Testtagning/UpdateByBrugerkode", { brugerkode, tekstlaesning, ordforraad, ordlaesning });
};
