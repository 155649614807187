import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import VejlederPage from "../../vejleder/VejlederPage";
import styles from "./SupporterPNummer.module.scss";
import { RuleTypes } from "../../../shared/enums/RuleTypes";
import { Roles } from "../../../shared/enums/Roles";
import { CreateRules, GetRulesByType } from "../../../apiService/RuleApi";
import { RuleDetails } from "../../../shared/types/RuleDetails";
import { toastManager } from "../../../components/common/Toast/ToastManager/ToastManager";
import updateTable from "../../../components/shared/utils/updateTable";
import { Opgavesaet } from "../../../shared/types/Opgavesaet";
import { getAllVisibleOpgavesaetApi } from "../../../apiService/OpgavesaetApi";
import SupportRuleTable from "../../../components/supporter/SupporterRuleTable/SupporterRuleTable";

const SupporterPNummer: FunctionComponent = () => {
    const [pNummers, setPNummers] = useState<string>("");
    const [rules, setRules] = useState<RuleDetails[]>([]);
    const [forceUpdate, setForceUpdate] = useState(0);
    const [opgavesaet, setOpgavesaet] = useState<Opgavesaet[]>([]);
    const [selectedOpgavesetId, setSelectedOpgavesaetId] = useState<string>("");

    const addPNummer = () => {
        CreateRules(pNummers.replaceAll(" ", "").split(","), RuleTypes.P_NUMMER, Roles.VEJLEDER, selectedOpgavesetId).then((response) => {
            if (response.succeeded) {
                toastManager.info(response.Message);
                setForceUpdate(updateTable());
            } else {
                toastManager.error(response.errors.toString());
            }
        });

    };

    useEffect(() => {
        GetRulesByType(RuleTypes.P_NUMMER).then((rules) => {
            setRules(rules);
        });
        getAllVisibleOpgavesaetApi().then((response) => {
            setOpgavesaet(response);
        });
    }, [forceUpdate]);

    const opgavesaetOptions =
        opgavesaet.length > 0 &&
        opgavesaet.map((item) => {
            return (
                <option key={item.id} id={item.id} value={item.navn}>
                    {item.navn}
                </option>
            );
        });

    const handleSelectedChangeOpgavesaet = (event: ChangeEvent<HTMLSelectElement>) => {
        event.preventDefault();
        const selectedIndex = event.target.options.selectedIndex;
        const id = event.target.options[selectedIndex].getAttribute("id");
        if (id != undefined) {
            setSelectedOpgavesaetId(id!);
        }
    };

    const isValidPNummer = () => {
        const tmp0 = pNummers.replaceAll(" ", "");
        const regex = new RegExp("^\\d{10}(,\\d{10})*$");
        return !regex.test(tmp0);
    };

    return (
        <VejlederPage>
            <h1 className="h1">Adgangsstyring</h1>
            <span className={`${styles.infoText}`}>Indtast P-nummer og dernæst hvilken test, vejledere på denne adresse skal kunne generere brugerkoder til. <br/>
            Tilføj flere P-numre ved at separere med et komma.</span>
            <label className={`form-label ${styles.label} ${styles.labelPadding}`}>P-numre: </label>
            <span className="form-hint">F.eks. 1234567890, 9876543210, 2345678121 </span>
            <input
                type="text"
                value={pNummers}
                className={`form-input ${styles.input}`}
                name="pNummer"
                onChange={(e) => {
                    setPNummers(e.target.value);
                }}>
                   
            </input>

            <label className={`form-label ${styles.label} ${styles.textLabel}`}>Test: </label>
            <select
                onChange={(e) => handleSelectedChangeOpgavesaet(e)}
                className={`item form-select`}
                style={{ width: "auto" }}
                defaultValue={""}>
                <option disabled value={""} />
                {opgavesaetOptions}
            </select>
            <br />
            <button
                className={`button button-secondary ${styles.button}`}
                onClick={addPNummer}
                disabled={isValidPNummer() || selectedOpgavesetId == ""}>
                Tilføj
            </button>
            <span className={`${styles.infoText}`}>Vejledere logger ind i Testafvikleren med en medarbejdersignatur, som indeholder et P-nummer, som er unik for den <br/> 
                adresse, de arbejder på. P-nummer kan findes gennem CVR-registret. Det er ikke muligt for en vejleder at logge ind, hvis <br/> 
                P-nummeret ikke er tilknyttet en prøve.</span>
            <h2 className="h2">Adgange til testafvikleren</h2>
            <SupportRuleTable
                setForceUpdate={setForceUpdate}
                rules={rules}
                ruleType={RuleTypes.P_NUMMER}
            />
        </VejlederPage>
    );
};

export default SupporterPNummer;
